import React, { FC, useEffect, useState } from 'react';
import { uniqBy, isNil, sortBy, min } from 'lodash';
import { Control } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  IconButton,
} from '@mui/material';
import { ExpandMore, Info } from '@mui/icons-material';
import { CertificateDocument } from 'src/rxdb/collections/Certificates/rxdb';
import CategoryDropdown from '../../../Dropdowns/CategoryDropdown';
import RichTextEditor from '../../../UI/Forms/RichTextEditor';
import Checkbox from '../../../UI/Forms/Checkbox';
import DatePicker from '../../../UI/Forms/DatePicker';
import Input from '../../../UI/Forms/Input';
import { getDatabase } from '../../../../rxdb';
import { handleCharLimitWarning, toJSON } from '../../../../utils';
import Autocomplete from '../../../UI/Forms/Autocomplete';
import { TblDdListDefaultsDocument } from '../../../../api/queries/tblDDListDefaults/rxdb';
import Select from '../../../UI/Forms/Select';
import { CrewDocument } from '../../../../rxdb/collections/Crew/schema';
import { useAppState } from 'src/contexts/app-state';
import WarningDialog from 'src/components/UI/WarningDialog';
import AutocompleteInput from 'src/components/UI/Forms/AutocompleteInput';
import 'src/components/dataentry/certificatesDE/styles.css';
import { isValidDateFormat } from 'src/utils/format-dates';
import { CHAR_LIMIT } from 'src/consts';

interface CertificateItemSummaryProps {
  form?: any;
  onChange: (field: string, value: any) => void;
  tblddlistdefaults?: null;
  handleChange?: any;
  handleCategoryChange?: any;
  control: Control;
  initialValue?: CertificateDocument | null;
  passport?: boolean;
  certificateType?: any;
  isCreated?: boolean;
}

const warnOptions: any[] = [
  {
    measureValue: "Day(s)",
    id: "0"
  },
  {
    measureValue: "Week(s)",
    id: "1"
  },
  {
    measureValue: "Month(s)",
    id: "2"
  },
  {
    measureValue: "Year(s)",
    id: "3"
  },
];

const CertificateSummaryForm: FC<CertificateItemSummaryProps> = ({
  initialValue,
  control,
  onChange,
  passport = false,
  certificateType,
  form,
  isCreated = false,
}) => {
  const { settingsPersonal } = useAppState();
  const [assignments, setAssignments] = useState<CrewDocument[]>([]);
  const [countries, setCountries] = useState<TblDdListDefaultsDocument[]>([]);
  const [departments, setDepartments] = useState<TblDdListDefaultsDocument[]>([]);
  const [isPassport, setIsPassport] = useState(passport);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showRemindInfo, setShowRemindInfo] = useState<boolean>(false);
  const { CERTIFICATES } = CHAR_LIMIT;

  const init = async () => {
    if (initialValue?.fldNationality) {
      setIsPassport(true);
    }
    const db = await getDatabase();

    db.tblddlistdefaults.find({ 
      selector: { 
        deletedBy: { $eq: null },
      }
    }).$.subscribe((defaults: any) => {
      const countries = defaults.filter((d: any) => d.fldListName === 'Countries' && d.fldMember !== "");
      const departments = defaults.filter((d: any) => d.fldListName === 'Department' && d.fldMember !== "");
      const positions = defaults.filter((d: any) => d.fldListName === 'Positions' && d.fldMember !== "");
      // Sort 'Department' and 'Positions' values ascending
      departments.sort((a: { value: string; }, b: { value: any; }) => (a.value && b.value) ? a.value.localeCompare(b.value) : 0);
      positions.sort((a: { value: string; }, b: { value: any; }) => (a.value && b.value) ? a.value.localeCompare(b.value) : 0);
      // Concatenate the sorted arrays
      const sortedDepartments = departments.concat(positions);

      setCountries(toJSON(countries));
      setDepartments(toJSON(sortedDepartments));
    });
    db.crew.find({
      selector: {      
        $and: [
          !isPassport ? { DateFire: { $eq: null }, deletedBy: { $eq: null }, fldIsGuest: { $eq: false}} : { DateFire: { $eq: null }, deletedBy: { $eq: null }},
        ],
      }
    }).$.subscribe((data: any) => {
      const crewProfileData = data.map((item: any) => ({ fldCrewID: item.fldCrewID, name: `${item.fldFirst} ${item.fldLast}` }));
      setAssignments(sortBy(crewProfileData, ['name']));
    });
  };

  const handleChangePassport = (evt: any) => {

    if(evt.target.checked){
      setIsPassport(evt.target.checked)
    } else {
      if(form?.fldIssue === null || form.fldNationality=== null || form?.fldIssue === "" || isNil(form?.fldIssue )){
        setIsPassport(evt.target.checked);
      } else {
        setIsDeleting(true);
      }
    }
  }

  const handleDeleteOk = () => {
    onChange("fldIssue", "")
    onChange("fldNationality", null)
    onChange("fldCertType",0)
    setIsPassport(false);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsPassport(true);
    setIsDeleting(false);
  }

  useEffect(() => {
    if (isNil(initialValue)) return;

    init();
  }, [initialValue]);

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          { certificateType !== "LIBRARY" && <div className="mt-3">
            {/* <Select
              keyExpr="fldCrewID"
              valueExpr="fldCrewID"
              displayExpr="name"
              name="fldCrewID"
              control={control}
              label="Issued To"
              options={assignments}
              rules={{required:'Issued To Required'}}
            /> */}
             <AutocompleteInput
              rules={{ required: certificateType === 'CREWPROFILE' ? true : false }}
              displayExpr="name"
              valueExpr="fldCrewID"
              options={assignments}
              label="Issued To"
              placeholder='Issued To'
              control={control}
              name="fldCrewID"
              disabled={!isCreated}
            />
          </div>}

          {/* Category Hierarchial, collapsable Tree */}
          {/* no need validator in taskDE form */}
          <div className="mt-6">
            <Input
              control={control}
              name="fldCertNum"
              rules={{ maxLength: CERTIFICATES.fldCertNum }}
              warning={(value) => handleCharLimitWarning(value, CERTIFICATES.fldCertNum)}
              inputProps={{ label: 'Document number' }}
            />
          </div>

          <div className="mt-6 w-full">
            <FormGroup className="w-full" row>
              <DatePicker
                name="DateIssue"
                control={control}
                label="Date Issued"
                rules={{
                  validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                }}
              />

              <Box className="h-px w-2 self-center mx-2 bg-dark-blue" />

              <DatePicker
                name="DateExpire"
                control={control}
                label="Expiration Date"
                rules={{
                  validate: (value) => isValidDateFormat(value) || 'Please enter a valid date format dd-MMM-yyyy',
                }}
              />
            </FormGroup>
          </div>

          <div className="mt-6 w-full">
            <FormGroup className="w-full" row>
              <Box className='flex-container'>
                <Box className='text-inline-start'>Warn me</Box>
                <Input
                  name="fldTimeWarn"
                  inputProps={{
                    label:"Time",
                    type:"number",
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-input': {
                          textAlign: 'right',
                        },
                      },
                      maxWidth: '18%',
                      marginRight: '10px'
                    },
                  }}
                  control={control}
                />
                <Autocomplete
                  onChange={onChange}
                  label="Measure"
                  options={warnOptions}
                  control={control}
                  name="fldTimeWarnMeasure"
                  keyExpr="id"
                  displayExpr="measureValue"
                />
                <Box className='text-inline-end'>before expiration</Box>
                <Box>
                  <IconButton size="small" onClick={() => setShowRemindInfo(true)}>
                    <Info fontSize="inherit" />
                  </IconButton>
                </Box>
              </Box>
            </FormGroup>
          </div>

          <div className="mt-6">
            <FormGroup row>
              <Checkbox
                control={control}
                name="fldSMS"
                label="SMS Required"
              />
              {certificateType === 1 && <Checkbox
                defaultValue={isPassport}
                control={control}
                name="fldPassport"
                label="This is a passport"
                onChange={handleChangePassport}
              />}
            </FormGroup>
          </div>
        </AccordionDetails>
      </Accordion>

      {(isPassport) && <Accordion 
      expanded={isPassport}
      className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={() => setIsPassport(!isPassport)}
          aria-controls="passport"
          id="passport"
        >
          Passport Information Details
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-3">
            <Input
              control={control}
              name="fldIssue"
              inputProps={{ label: 'Place of issue' }}
              rules={{ maxLength: CERTIFICATES.fldIssue }}
              warning={(value) => handleCharLimitWarning(value, CERTIFICATES.fldIssue)}
            />
          </div>

          <div className="mt-6">
            <Autocomplete
              defaultPayload={{
                fldListName: 'Countries',
                fldT4Reqd: true,
              }}
              displayExpr="fldMember"
              keyExpr="PKey"
              onChange={onChange}
              options={countries}
              label="Nationality"
              control={control}
              name="fldNationality"
              collection="tblddlistdefaults" // Use this in order to create new items.
            />
          </div>
        </AccordionDetails>
      </Accordion>}

      <Accordion className="mt-3" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="administrative"
          id="administrative"
        >
          Administrative Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-3">
            <CategoryDropdown
              name="fldSRHKey"
              label="Category"
              control={control}
              onChange={onChange}
              rules={{
                required: true,
              }}
              sourceForm="task"
              allDepts={settingsPersonal?.fldAllDepts > 0}
            />
          </div>

          <div className="mt-6">
            <Autocomplete
              defaultPayload={{
                fldListName: 'Department',
                fldT4Reqd: true,
              }}
              displayExpr="fldMember"
              keyExpr="PKey"
              onChange={onChange}
              options={departments}
              label="Reporting Responsibility"
              control={control}
              name="fldResponsible"
              collection="tblddlistdefaults" // Use this in order to create new items.
            />
          </div>

          <div className="mt-6">
            <RichTextEditor
              label="Notes"
              control={control}
              name="fldNotes"
              onChange={onChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Dialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={showRemindInfo}
        onClose={() => setShowRemindInfo(false)}
      >
        <DialogContent dividers>
          Specify a time increment in days, weeks, months, or years to determine when a task will populate on your worklist for certificate renewal.
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button onClick={() => setShowRemindInfo(false)}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      
      <WarningDialog
        visible={isDeleting}
        title="Information Clear Warning"
        content="Are you sure you wish to clear passport information?"
        okText='yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </div>
  );
};

export default CertificateSummaryForm;
