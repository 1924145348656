import { MultipleLocationDocument } from 'src/rxdb/collections/MultipleLocations/schema';
import BarCodeIcon from '../../assets/icon-bc-primary.svg';
import { TDIDb, getDatabase } from 'src/rxdb';
import { IAttachmentState } from 'src/modules/Attachments';
import { get, isEmpty, isNil } from 'lodash';
import { getListByTabType } from 'src/modules/Attachments/component/utils';
import { TblDocumentCrossReferenceDocument } from 'src/api/queries/tblDocumentCrossReference/rxdb';
import { DocumentRevisionDocument } from 'src/rxdb/collections/DocumentRevisions/schema';
import { TblDocumentationDocument } from '../LibrariesPage/rxdb';
import { InventoryDocument } from './rxdb';
import { getRegexByOperator } from 'src/utils';

export const categoryData = (viewcategoryhierarchy: any) => {
  const data = viewcategoryhierarchy.map((elem: any) => ({
    Id: elem.Id,
    ParentID: elem.ParentID,
    Name: elem.Name,
    IsGroup: elem.IsGroup,
    icon: elem.IsGroup ? null : BarCodeIcon,
  }));
  return data;
};

export const upsertAmountValue = (item: any, data: any[], increase: boolean): any[] => {
  const updatedAmount = increase ? item.Amount + 1 : item.Amount - 1;
  (item.original as MultipleLocationDocument).atomicPatch({
    Amount: updatedAmount,
  });
  // Update the data state with the new amount
  const updatedData = data.map((dataItem) => {
    if (dataItem.PKey === item.PKey) {
      return {
        ...dataItem,
        Amount: updatedAmount,
      };
    }
    return dataItem;
  });
  return updatedData;
}

const transformAttachment = (
  attachment: TblDocumentCrossReferenceDocument,
  revision?: DocumentRevisionDocument | null,
  documentation?: TblDocumentationDocument | null
): IAttachmentState => {
  const title = get(documentation, 'DocTitle', '');
  return {
    PKey: attachment.PKey,
    fldFKey: attachment.fldFKey,
    fldRecordKey: attachment.fldRecordKey,
    fldAltPath: revision?.fldAltPath,
    fldFileName: revision?.fldFileName,
    DocTitle: title || revision?.fldFileName || attachment.fldFKey,
    fileExtension: (revision?.fldFileName?.split('.').pop() || '').toUpperCase(),
    fldDescription: documentation?.fldDescription,
    fldShowInCentral: documentation?.fldShowInCentral,
    fldNotes: revision?.fldNotes,
    fldPage: revision?.fldPage,
    fldRevision: revision?.fldRevision,
    fldRevisionDate: revision?.fldRevisionDate,
    deletedAt: documentation?.deletedAt || revision?.deletedAt || attachment?.deletedAt,
  };
};

export const getProductImage = async (relatedKey: string, db: TDIDb) => {
  const type = 'photo';

  const items = await db.tbldocumentcrossreference
    .find({
      selector: {
        fldRecordKey: isNil(relatedKey) ? null : relatedKey,
      },
    })
    .exec();
  const result = await Promise.all<IAttachmentState>(
    items.map(async (attachment: any) => {
      // Push subscription to entry list, so we can unsubscribe
      // subscriptions.current.set('cross', referenceSubscription);
      const revision = await db.documentrevision
        .findOne({
          selector: {
            fldFKey: attachment.fldFKey,
          },
        })
        .exec();

      if (isNil(revision)) {
        return transformAttachment(attachment, revision, null);
      }

      const document = await revision.populate('fldFKey');

      return transformAttachment(attachment, revision, document);
    })
  );

  const attachmentList = getListByTabType(result, type);
  if (attachmentList.length > 0) {
      return attachmentList[0].fldFileName; // Return filename of the first attachment
  } else {
      return null; // No attachments found
  }
};

export const getProductById = async (productId: string, db: TDIDb) => {
  try {
    const product = db.inventory
    .findOne({
      selector: {
        ProductID: productId,
        deletedBy: null,
      },
    })
    .exec();
    return product;
  } catch (error) {
      console.error('Error querying inventory:', error);
  }
};

export const getProductsImages = async (relatedKeys: string[], db: TDIDb) => {
  const type = 'photo';

  const items = await db.tbldocumentcrossreference
    .find({
      selector: {
        fldRecordKey: { $in: relatedKeys.filter(Boolean) }, // Filter out any null or undefined related keys
      },
    })
    .exec();

  const result = await Promise.all(
    items.map(async (attachment: any) => {
      const revision = await db.documentrevision
        .findOne({
          selector: {
            fldFKey: attachment.fldFKey,
          },
        })
        .exec();

      if (!revision) {
        return null;
      }

      const document = await revision.populate('fldFKey');

      return transformAttachment(attachment, revision, document);
    })
  );

  const imageMap: { [fldRecordKey: string]: string } = {};
  result.forEach(attachment => {
    if (attachment && attachment.fldRecordKey && attachment.fldFileName) {
      const attachmentList = getListByTabType([attachment], type);
      if (attachmentList.length > 0) {
        imageMap[attachment.fldRecordKey] = attachmentList[0].fldFileName; // Map product ID to image filename
      }
    }
  });

  return imageMap;
};

export const getProductsByIds = async (productIds: string[], db: TDIDb): Promise<{ [productId: string]: InventoryDocument }> => {
  const inventoryMap: { [productId: string]: InventoryDocument } = {};

  const inventories = await db.inventory
    .find({
      selector: {
        ProductID: { $in: productIds },
      },
    })
    .exec();

  inventories.forEach((inventory: InventoryDocument) => {
    inventoryMap[inventory.ProductID] = inventory;
  });

  return inventoryMap;
};

export const performSearch = async (searchValue: string, selector: any, db: any) => {
  let noResultsFound = false;

  const s = await getSelectorByBarcodFilter(
    'fldTritonBC',
    searchValue,
    'contains',
    db
  );
  selector = {
    ...selector,
    ...s,
  };

  if (isEmpty(selector.ProductID?.$in)) {
    const partNumberSearch = await getSelectorByBarcodFilter(
      'fldPartNumber',
      searchValue,
      'contains',
      db
    );
    selector = {
      ...selector,
      ...partNumberSearch,
    };
  }

  if (isEmpty(selector.ProductID?.$in)) {
    const modelNumberSearch = await getSelectorByBarcodFilter(
      'ModelNumber',
      searchValue,
      'contains',
      db
    );
    selector = {
      ...selector,
      ...modelNumberSearch,
    };
  }

  if (isEmpty(selector.ProductID?.$in)) {
    const newSearchString = searchValue.replace(/[^a-zA-Z0-9]/g, '');
    const s = await getSelectorByBarcodFilter(
      'fldTritonBC',
      newSearchString,
      'contains',
      db
    );
    selector = {
      ...selector,
      ...s,
    };
  }

  if (isEmpty(selector.ProductID?.$in)) {
    const newSearchString = searchValue.replace(/[^a-zA-Z0-9]/g, '');
    const partNumberSearch = await getSelectorByBarcodFilter(
      'fldPartNumber',
      newSearchString,
      'contains',
      db
    );
    selector = {
      ...selector,
      ...partNumberSearch,
    };
  }
  if (isEmpty(selector.ProductID?.$in)) {
    const newSearchString = searchValue.replace(/[^a-zA-Z0-9]/g, '');
    const modelNumberSearch = await getSelectorByBarcodFilter(
      'ModelNumber',
      newSearchString,
      'contains',
      db
    );
    selector = {
      ...selector,
      ...modelNumberSearch,
    };
  }
  if (isEmpty(selector.ProductID?.$in)) {
    noResultsFound = true;
  }

  return { selector, noResultsFound };
};

export const getSelectorByBarcodFilter = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb
) => {
  switch (name) {
    case 'fldTritonBC': {
      const barcodes = await db.invbarcodes
        .find({
          selector: {
            fldTritonBC: { $regex: getRegexByOperator(operator, value) },
            deletedAt: { $eq: null }
          },
        })
        .exec();
      return {
        ProductID: { $in: barcodes.map((e) => e.ProductID) },
      };
    }

    case 'fldPartNumber': {
      const partNumber = await db.inventory
        .find({
          selector: {
            fldPartNumber: { $regex: getRegexByOperator(operator, value) },
            deletedAt: { $eq: null }
          },
        })
        .exec();
      return {
        ProductID: { $in: partNumber.map((e) => e.ProductID) },
      };
    }

    case 'ModelNumber': {
      const modelNumber = await db.inventory
        .find({
          selector: {
            ModelNumber: { $regex: getRegexByOperator(operator, value) },
            deletedAt: { $eq: null }
          },
        })
        .exec();
      return {
        ProductID: { $in: modelNumber.map((e) => e.ProductID) },
      };
    }
    default:
      return {};
  }
};