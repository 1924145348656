import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { isNil, isArray, last, isString, isEmpty } from 'lodash';
import { FC, useState } from 'react';
import { LogEntryDocument } from 'src/pages/LogEntryPage/rxdb';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { v4 as uuid } from 'uuid';
import LogEntryWithEqDetailsForm from 'src/components/dataentry/logentryDE/LogEntryWithEqDetailsForm';
import { TblSparesUsedDocument } from '../../../SparesTab/rxdb';
import { useAuth } from '../../../../contexts/auth';
import { getDatabase } from '../../../../rxdb';
import { getPlainTextFromHtml } from '../../../../utils';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import WarningDialog from 'src/components/UI/WarningDialog';
import LogEntryEquipmentHoursDialog from '../../logentryDE/component/LogEntryEquipmentHoursDialog';
import { normalizeDateTime } from 'src/helpers';
import { CustomDialog } from 'src/helpers/modals';

interface InjectedProps {
  visible: boolean;
  onSave?: (logEntry: LogEntryDocument, isCreate: boolean) => void;
  onCancel?: () => void;
  logEntry?: LogEntryDocument;
  issue?: WorkIssuesDocument;
  equipment?: EquipmentDocument;
  onPreSave?: () => Promise<boolean>;
}

const EqNewLogEntryFormDialog: FC<InjectedProps> = ({
  issue,
  logEntry,
  equipment,
  visible,
  onCancel,
  onSave,
  onPreSave,
}) => {
  const { user } = useAuth();
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });
  const [processing, setProcessing] = useState<boolean>(false);
  const [eqWarningDialog, setEqWarningDialog] = useState(false);
  const [saveData, setSaveData] = useState<any>();
  const [savedSpares, setSavedSpares] = useState<TblSparesUsedDocument[]>([]);
  const [eqHours, setEqHours] = useState<number>(0);
  const [logEqHours, setLogEqHours] = useState<number>(0);

  const isCreation = isNil(logEntry?.PKey);

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const onChangeHours = async (value:any)=>{
    if(parseInt(value)>0){
      setLogEqHours(parseInt(value))
    }
  }
  
  const onSubmit = async (data: any, spares: TblSparesUsedDocument[]) => {
    setProcessing(true);
    const db = await getDatabase();

    const {
      Company,
      LogDate,
      fldCost,
      fldEnteredBy,
      fldHTML,
      fldHours,
      fldIsWarranty,
      fldPerformedBy,
      fldRestricted,
      fldSMS,
      fldTime,
      fldTimeQty,
      fldWorkList,
      Department,
      Currency,
      EqKey,
      Equipment,
      fldSRHKey,
      fldLocHierarchy,
      LogEntry,
    } = data;

    if (isNil(logEntry)) {
      throw new Error('Log entry missing');
    }

    if (isNil(issue)) {
      throw new Error('Workissue missing');
    }

    // We will run some validation checks here.
    if (onPreSave) {
      await onPreSave();
    }

    try {
      const created = await db.logentry.upsert({
        EqKey,
        Equipment,
        MaintKey: issue.fldSchedMaintKey,
        DayLogType: 2,
        Curr: Currency?.ID || issue.fldActualCurrency,
        fldCrewID: user?.fldCrewID || null,
        fldNoView: false,
        fldLocHierarchy: isArray(fldLocHierarchy) ? last(fldLocHierarchy) : fldLocHierarchy,
        fldSRHKey: isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey,
        fldCompany: Company?.DisplayMember || Company || null,
        // fldWorkList: fldWorkList?.PKey || null,
        fldWorkList: isString(fldWorkList)
        ? fldWorkList
        : fldWorkList?.fldMember || null,
        Department: isString(Department)
        ? Department
        : Department?.fldMember || null,
        fldTimeQty,
        LogDate: new Date(LogDate).toISOString(),
        fldCost: parseInt(fldCost) || 0,
        fldEnteredBy,
        fldHTML,
        LogEntry: getPlainTextFromHtml(fldHTML),
        fldHours: fldHours ? parseInt(fldHours) : logEqHours || 0,
        fldIsWarranty,
        fldPerformedBy: fldPerformedBy?.join(', ') || null,
        fldRestricted,
        fldSMS,
        DateEntered: new Date().toISOString(),
        JobNumber: issue.JobNumber,
        fldTime: parseInt(fldTime) || 0,
        PKey: logEntry.PKey || uuid(),
      });

      // TODO: Update spares
      // Set LogKey for spares that came from Task or that we've adjusted.
      await Promise.all(
        spares.map(async (spare) => {
          // TODO: Handle case when multiple locations per ProductID can be found
          const location = await db.tblmultiplelocations
            .findOne({
              selector: {
                ProductID: spare.ProductID,
              },
            })
            .exec();

          await db.tblsparesused.atomicUpsert({
            ...spare.toJSON(),
            LogKey: created.PKey,
          });

          if (!isNil(location)) {
            await location.atomicPatch({
              Amount: Math.max(
                (location?.Amount || 0) - (spare.Amount || 0),
                0,
              ),
            });
          }

          return spare;
        }),
      );

      if (typeof equipment?.Hours != 'undefined') {
        const eqHours = isNil(equipment?.Hours) ? 0 : equipment?.Hours;
        const logHours = parseInt(fldHours) || 0;
        if (eqHours < logHours) {
          try {
            await equipment.atomicPatch({
              Hours: logHours,
            })
          }
          catch (e: any) {
            setSnackbar({
              open: true,
              type: 'error',
              message: e.message,
            });
          }
        }
      }
      onSave && (await onSave(created, !!logEntry.PKey));
      setProcessing(false);
      setSnackbar({
        open: true,
        type: 'success',
        message: 'Success!',
      });
    } catch (e: any) {
      setProcessing(false);
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  const onSaveClick = async (data: any, spares: TblSparesUsedDocument[])=>{
    setProcessing(true);
    const db = await getDatabase();
    if(data.EqKey){
      const equipmentData = await db.equipment.find({
        selector:{
          EqKey: {$eq: data.EqKey }
        }
      }).exec()
      
      if(equipmentData[0] && equipmentData[0].fldCountHours && isEmpty(data.fldHours) ){
        setEqHours(equipmentData[0].Hours || 0)
        setSaveData(data)
        setSavedSpares(spares)
        setEqWarningDialog(true)
      } else {
        onSubmit(data,spares)
      }
    } else {
      onSubmit(data,spares)
    }
  }

  const onContinueAnyWay = async()=>{
    onSubmit(saveData,savedSpares)
    setEqWarningDialog(false)
  }

  const onEnterEqHours = async()=>{
    setProcessing(false);
    setSaveData(null)
    setSavedSpares([])
    setEqWarningDialog(false)
  }


  const handleCancel = () => {
    onCancel && onCancel();
    setProcessing(false);
    setSnackbar({
      open: false,
      type: 'success',
      message: '',
    });
  };

  // Adjust Spare locations
  // const onSpareLocationAdjustCancel = () => {};
  // const onSpareLocationAdjustSave = () => {};

  return (
    <>
      <CustomDialog scroll="paper" fullWidth maxWidth="md" open={visible}>
        <DialogTitle>
          <span className="font-bold text-2xl">Create New Log Entry</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          {!isNil(logEntry) && !isNil(issue) && (
            <LogEntryWithEqDetailsForm
              logEntry={logEntry}
              workIssue={issue}
              onSubmit={onSaveClick}
              isCreation={isCreation}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button className="mr-2" onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton
              loading={processing}
              type="submit"
              form="logentry-details-form"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </CustomDialog>
      {/* <WarningDialog
        visible={eqWarningDialog}
        title="Empty equipment hours"
        content="This Equipment monitor hours, you have not enter any hours for this equipment. Please selecte option to continue."
        okText='Enter Equipment Hours'
        cancelText='Continue anyway'
        color='warning'
        onOk={onEnterEqHours}
        onCancel={onContinueAnyWay}
      /> */}
      <LogEntryEquipmentHoursDialog
        visible={eqWarningDialog}
        title="Empty equipment hours"
        okText='Cancel'
        cancelText='Continue'
        color='warning'
        onOk={onEnterEqHours}
        onCancel={onContinueAnyWay}
        equipmentHours={eqHours}
        onChange={onChangeHours}
        fldHours={logEqHours}
      />
      
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EqNewLogEntryFormDialog;
