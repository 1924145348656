import { Button, IconButton, Tooltip } from "@mui/material";
import { isNil, sortBy } from "lodash";
import { FC, useEffect, useState } from "react";
import { IAttachmentState } from "src/modules/Attachments";
import { transformAttachment } from "src/modules/Attachments/component/AttachmentGrid";
import AttachmentPopup from "src/modules/Attachments/component/AttachmentPopup";
import { getDatabase } from "src/rxdb";
import { uuid } from "uuidv4";
import { DOWNLOAD_URL } from 'src/consts';
import { useDialog } from "src/contexts/dialog";
import { Edit, DeleteTwoTone } from '@mui/icons-material';

const defaultFile = {
    contentType: '',
    extension: '',
    key: '',
    name: '',
    url: '',
};

interface IFile {
    contentType: string;
    extension: string;
    key: string;
    name: string;
    url: string;
}

interface Props {
    initialValue: any;
    setSnackbar: any;
    tableName: string;
    onSave?: (data: any) => void;
}

const ProcedureDocument: FC<Props> = ({
    tableName,
    initialValue,
    setSnackbar,
    onSave,
}) => {
    const [attachmentData, setAttachmentData] = useState<IAttachmentState | undefined>(undefined);
    const [attachmentValue, setAttachmentValue] = useState<IAttachmentState | undefined>(undefined);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<IFile>(defaultFile);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { showWarningDialog } = useDialog();

    const loadAttachmentData = async () => {
        setLoading(true)
        const db = await getDatabase();
        let procedureDoc: any = {};
        const document: any = await db.tbldocumentation
            .findOne({
                selector: {
                    PKey: initialValue.fldLinkID,
                }
            })
            .exec();
        //TODO: review by Jay - changed to prevent crash when no procedure doc available.
        if(document) {
                const attachment: any = await db.tbldocumentcrossreference
                .findOne({
                    selector: {
                        fldFKey: document.PKey,
                    }
                })
                .exec();
            const revisionDocs: any = await db.documentrevision
            .find({
                selector: {
                    fldFKey: attachment.fldFKey,                
                }
            })
            .exec();
            // Sort by revision date and updated at
            const revisions: any  = sortBy(revisionDocs, ['fldRevisionDate', 'updatedAt']).reverse();
            const revision = revisions.length > 0 ? revisions[0]: null;
            if (isNil(revision)) {
                procedureDoc = transformAttachment(attachment, revision, null);
            }
            else {
                const document: any = await revision.populate("fldFKey");
                procedureDoc = transformAttachment(attachment, revision, document);
            }
        }
        setAttachmentData(procedureDoc);
        setLoading(false)
    }

    const onFormCancel = () => {
        setShowPopup(false);
    };

    const addDocument = () => {
        setAttachmentValue(undefined);
        setShowPopup(true);
    }

    const editDocument = () => {
        setAttachmentValue(attachmentData);
        setShowPopup(true);
    }
    const viewDocument = () => {
        setAttachmentValue(attachmentData);
        setShowPopup(true);
    }

    const removeDocument = async () => {
        showWarningDialog({
            title: "Delete Procedure Document",
            content: "Are you sure you want to delete the procedure document and all its assosciated references?",
            onCancel: () => {console.log('cancel pressed, not deleting procedure document')},
            onConfirm: async () => {
                const db = await getDatabase();
        
                try {
                    await loadAttachmentData();
                    const revisions: any = await db.documentrevision.find({selector: {fldFKey: initialValue.fldLinkID}}).exec();
                    console.log('delete revisions', revisions.map((it:any) => it.PKey));                    
                    revisions.map((it:any) => it.remove());
                    const documentCrossRefs: any = await db.tbldocumentcrossreference.find({selector: { fldFKey: initialValue.fldLinkID }}).exec();
                    console.log('delete documentCrossRefs', documentCrossRefs.map((it:any) => it.PKey));
                    documentCrossRefs.map((it:any) => it.remove());
                    const tbldocumentation: any = await db.tbldocumentation.findOne({selector: { PKey: initialValue.fldLinkID }}).exec();
                    console.log('delete tbldocumentation', tbldocumentation.PKey);
                    await tbldocumentation.remove();

                    let scheMaint: any = await db.collections.tblschedmaint.findOne({
                        selector: {
                            PKey: initialValue.SchedKey,
                        }
                    }).exec();
                    let fldHTML: string = scheMaint.fldHTML.replace("OPEN ATTACHED PROCEDURE FOR INSTRUCTIONS. ", "");
                    await scheMaint.update({
                        $set: {
                            fldLinkID: null,
                            fldHTML: fldHTML,
                        }
                    });
                    
                    initialValue.fldLinkID = null;
                    initialValue.fldHTML = fldHTML;
                    onSave && onSave(initialValue);
                    setSnackbar({
                        open: true,
                        type: 'success',
                        message: 'Procedure document removed successfully',
                    });
                }
                catch (e: any) {
                    setSnackbar({
                        open: true,
                        type: 'error',
                        message: e.message,
                    });
                }
            },
          });
          return
        
    }

    const handleUpsert = async (data?: IAttachmentState) => {
        console.log('data:', data)
        setSaveLoading(true);
        const db = await getDatabase();
        try {
            // upsert into tbldocumentation 
            // use the fldFKey if present from attachment data - null/empty means a new attachment 
            // is being added so generate a new uuid. otherwise just use fldFKey so the documentation
            // record is upserted/updated
            const documentationPayload = {
                PKey: data?.fldFKey || uuid(),
                DocTitle: data?.DocTitle,
                fldDescription: data?.fldDescription,
                fldShowInCentral: false,
                fldSRHKey: initialValue.fldSRHKey || null,
                fldLibtype: data?.fldLibtype || null,
            };
            const responseDocumentation = await db.tbldocumentation.upsert(documentationPayload);

            setTimeout(() => {
                console.log('waiting for tblDocumentation insert')
            }, 500);
            // upsert into tbldocumentcrossreference - once only
            // if the fldFKey is present, that means this record has already been associated with documentation,
            // nothing needs to be done in that case
            let fldFKey = data?.fldFKey || undefined;
            if(!fldFKey) {
                console.log('tbldocumentation upsert:', responseDocumentation)
                fldFKey  = responseDocumentation.PKey;
                const documentCrossReferencePayload = {
                    PKey: uuid(),
                    fldFKey: fldFKey,
                    fldRecordKey: data?.fldRecordKey || initialValue.SchedKey,
                    fldTableName: "TblSchedMaint", // this should be variable based on module
                };
                await db.tbldocumentcrossreference.upsert(documentCrossReferencePayload);
            }
            
            // Find if there's a rev matching this fldFKey - what happens when there are multiple? Scott?       
            const revision: any = await db.documentrevision.findOne({selector: {fldFKey, fldRevision: data?.fldRevision}}).exec();
            // If a file is uploaded, generate a new uuid 
            // otherwise if the findQuery finds a matching rev, use that otherwise generate a new one
            const documentRevisionPKey = (uploadedFile && uploadedFile.key) ? uuid() : (revision ? revision.PKey: uuid());
            // Upsert the record so that new fields are updated from the form data
            const documentRevisionPayload = {
                PKey: documentRevisionPKey,
                fldFKey: fldFKey || responseDocumentation.PKey, // Create association with Documentation above.
                fldAltPath: data?.fldAltPath,
                fldFileName: data?.fldFileName || uploadedFile.key,
                fldNotes: data?.fldNotes,
                fldPage: data?.fldPage,
                fldRevision: data?.fldRevision,
                fldRevisionDate: data?.fldRevisionDate
                    ? new Date(String(data?.fldRevisionDate)).toISOString()
                    : new Date().toISOString(),
            };

            await db.documentrevision.upsert(documentRevisionPayload);

            let schedMaint: any = await db.collections.tblschedmaint.findOne({
                selector: {
                    PKey: initialValue.SchedKey,
                }
            }).exec();
            let fldHTML: string = "OPEN ATTACHED PROCEDURE FOR INSTRUCTIONS. " + (schedMaint.fldHTML ?? "");
            await schedMaint.update({
                $set: {
                    fldLinkID: responseDocumentation.PKey,
                    fldHTML: fldHTML,
                }
            });
            
            initialValue.fldLinkID = responseDocumentation.PKey;
            initialValue.fldHTML = fldHTML;
            setUploadedFile(defaultFile);
            onSave && onSave(initialValue);
            loadAttachmentData();

        } catch (e: any) {
            console.error(e);
            setSnackbar({
                open: true,
                type: 'error',
                message: e.message,
            });
        }
        setSaveLoading(false);
        onFormCancel();
    }

    useEffect(() => {
        loadAttachmentData();
    }, [initialValue.fldLinkID]);

    return (
        <div>
            {initialValue.fldLinkID
                ? <div>                    
                    <Button target="_blank" href={`${DOWNLOAD_URL}/${attachmentData?.fldFileName}`} disabled={loading || saveLoading}>View attached procedure</Button>                
                    <Tooltip title="Edit Procedure Document">
                        <IconButton
                        size="small"
                        color="primary"
                        onClick={editDocument}
                        >
                            <Edit fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Procedure Document">
                        <IconButton
                            size="small"
                            color="error"
                            onClick={removeDocument}
                            >
                            <DeleteTwoTone fontSize="small" />
                        </IconButton>
                    </Tooltip>                     
                </div>
                : <div>
                    <Button onClick={addDocument}>
                        Add a procedure document
                    </Button>
                </div>
            }
            {showPopup && 
                <AttachmentPopup
                    initialValue={initialValue}
                    attachmentValue={attachmentValue}
                    onCancel={onFormCancel}
                    onSubmit={handleUpsert}
                    visible={true}
                    setUploadedFile={setUploadedFile}
                    uploadedFile={uploadedFile}
                    type={"attachments"}
                    saveLoading={saveLoading}
                    tableName={tableName}
                    maxFiles={1}
                    disableCheckbox={true} // Attached Procedure is not allowed to be added to the central document library.
                />
            }
        </div>
    )
}

export default ProcedureDocument
