import { FC, useEffect, useState } from 'react';
import { uniqBy } from 'lodash';
import { Control, RegisterOptions } from 'react-hook-form';
import { TblSystemsHierarchyDocument } from './rxdb';
import { getDatabase } from '../../../rxdb';
import TreeSelect from '../../UI/Forms/TreeSelect';
import { useAuth } from '../../../contexts/auth';

type CategoryProps = {
  label: string;
  control: Control;
  name: string;
  onChange: (field: string, value: any) => void;
  rules?: RegisterOptions;
  defaultValue?: string | null;
  disabled?: boolean;
  sourceForm?: string;
  allDepts?: boolean;
};

const CategoryDropdown: FC<CategoryProps> = ({
  control,
  name,
  label,
  onChange,
  rules,
  defaultValue,
  disabled,
  sourceForm,
  allDepts = false,
}) => {
  const { user } = useAuth();
  const [systems, setSystems] = useState<TblSystemsHierarchyDocument[]>([]);

  const init = async () => {
    const db = await getDatabase();

    // SELECT fldCategory FROM tblSystemsHierarchy WHERE fldTags IS NULL;
    // SELECT fldCategory FROM tblSystemsHierarchy WHERE fldTags LIKE '%work%';
    // SELECT fldCategory FROM tblSystemsHierarchy WHERE fldDepts IS NULL;
    // SELECT fldCategory FROM tblSystemsHierarchy WHERE fldDepts LIKE '%DEPARTMENT%';

    const withGivenDefault = await db.tblsystemshierarchy
      .find({ selector: { PKey: { $eq: defaultValue }, deletedBy:{ $eq: null} } })
      .exec();
    const basedOnTagNull = await db.tblsystemshierarchy
      .find({ selector: { fldTags: { $eq: null }, deletedBy:{ $eq: null} } })
      .exec();
    const basedOnTagLike = await db.tblsystemshierarchy
      .find({ selector: { fldTags: { $regex: new RegExp('work', 'i') }, deletedBy:{ $eq: null} } })
      .exec();
    const basedOnDepartmentNull = await db.tblsystemshierarchy
      .find({ selector: { fldDepts: { $eq: null }, deletedBy:{ $eq: null} } })
      .exec();
    const basedOnDepartmentLike = user?.Department
      ? await db.tblsystemshierarchy
        .find({
          selector: {
            fldDepts: { $regex: new RegExp(user?.Department || '', 'i') },
            deletedBy:{ $eq: null}
          },
        })
        .exec()
      : [];

    const systems = [
      ...withGivenDefault,
      ...basedOnTagNull,
      ...basedOnTagLike,
      ...basedOnDepartmentNull,
      ...basedOnDepartmentLike,
    ];

    return(uniqBy(systems, 'PKey'));
  };

  const handleFilter = (item: any) => {
    switch (sourceForm) {
      case 'task':
        return getTwoNotesCondition(item, 'WORKISSUES');
      case 'logentry':
        return getTwoNotesCondition(item, 'LOGENTRY');
      case 'equipment':
        return getTwoNotesCondition(item, 'EQUIPMENT');
      case 'event-scheduling':
        return getTwoNotesCondition(item, 'EVENT_SCHEDULING');
      case 'drill-scheduling':
        return getTwoNotesCondition(item, 'DRILL_SCHEDULING');
      case 'general-inventory':
        return getTwoNotesCondition(item, 'INVENTORY');
      case 'beverages':
        return getOneNoteCondition(item, 'BEVERAGES');
      case 'uniforms':
        return getOneNoteCondition(item, 'UNIFORMS');
      case 'stores':
        return getOneNoteCondition(item, 'STORES');
      case 'medical':
        return getOneNoteCondition(item, 'MEDICAL');
      case 'inspections':
        return getTwoNotesCondition(item, 'INSPECTIONS');
      case 'ISPSDOCS':
        return getOneNoteCondition(item, 'ISPSDOCS');
      case 'PHOTOS':
        return getOneNoteCondition(item, 'PHOTOS');
      case 'DRAWINGS':
        return getTwoNotesCondition(item, 'DRAWINGS');
      case 'SMSDOCS':
        return getOneNoteCondition(item, 'SMSDOCS');
      default:
        return true;
    }
  };

  const getOneNoteCondition = (item: any, field: string) => {
    if (item.fldTags && item.fldTags.includes(field)) {
      return true;
    }
    return false;
  };

  const getTwoNotesCondition = (item: any, field: string) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes(field)) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes(field))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    return false;
  };
  
  const getTaskCondition = (item: any, field: string) => {
    if (allDepts) {
      if (!item.fldTags || item.fldTags.includes(field)) {
        return true;
      }
      return false;
    }
    if (
      (!item.fldTags || item.fldTags.includes(field))
      && (!item.fldDepts || item.fldDepts.includes((user?.Department || '').toUpperCase()))
    ) {
      return true;
    }
    //Adding below condition to handle if user do not have permission to see applied category but have permission to see task then it will enable that category only for the task
    if (item.PKey === defaultValue){
      return true
    }
    return false;
  };

  useEffect(() => {
    let isMounted = true; // Variable to track if component is mounted

    const fetchData = async () => {
      try {
        const systems = await init();
        if (isMounted) {
          // Update state only if component is mounted
          setSystems(systems);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();

    // Cleanup function to cancel asynchronous tasks
    return () => {
      isMounted = false; // Set to false when component unmounts
    };
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount



  const data = systems.filter(handleFilter).map((elem: any) => ({
    PKey: elem.PKey,
    ParentID: elem.ParentID,
    fldCategory: elem.fldCategory,
    expanded: false,
  }));

  const sortedData = data?.sort((a: any, b: any) => a.fldCategory.localeCompare(b.fldCategory));

  return (
    <TreeSelect
      disabled={disabled}
      onChange={onChange}
      options={sortedData}
      control={control}
      name={name}
      valueExpr="PKey"
      keyExpr="PKey"
      parentIdExpr="ParentID"
      displayExpr="fldCategory"
      label="Category"
      rules={rules}
    />
  );
};

export default CategoryDropdown;
