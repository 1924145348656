import { FC, useState } from "react";
import { isEmpty } from 'lodash';
import { Control, RegisterOptions, useController } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface InjectedProps {
  control: Control;
  name: string;
  defaultValue?: any;
  rules?: RegisterOptions;
  label?: string;
  onChange: (field: string, value: any) => void;
  disabled?: boolean;
  warning?: (value: string) => string | undefined;
}

const QUILL_MODULES = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link",],
    // ['code-block'], //TODO: There is no handler for that. Consider adding highlight.js or disable...
    ["clean"],
  ],
};

const QUILL_FORMATS = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

const RichTextEditor: FC<InjectedProps> = ({
  control,
  onChange,
  name,
  rules,
  defaultValue,
  label,
  children,
  disabled = false,
  warning
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const [lengthError, setLengthError] = useState<string | null>(null);

  const handleChange = (v?: string) => {
    let value = v || '';
    if (value === '<p><br></p>') {
      value = '';
    }
    onChange(name, isEmpty(value) ? null : value);

    if (warning && warning(value)) {
      const newErrorMessage: string | null = warning(value) || null;
      setLengthError(newErrorMessage);
    } else {
      setLengthError(null);
    }
  };

  return (
    <div style={{ minHeight: "120px", paddingBottom: "10px" }}>
      <ReactQuill
        theme="snow"
        readOnly={disabled}
        {...inputProps}
        value={inputProps.value || ''}
        placeholder={label}
        // style={{ minHeight: "100px" }}
        ref={ref}
        modules={QUILL_MODULES}
        formats={QUILL_FORMATS}
        onChange={handleChange}
      />
      {/* <HtmlEditor
        {...inputProps}
        ref={ref}
        placeholder={label}
        height="300px"
        onValueChanged={(e) => onChange(name, e.value)}
      >
        <MediaResizing enabled />
        <Toolbar multiline>{children}</Toolbar>
      </HtmlEditor> */}
      {error && (
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      )}
      {lengthError && (
        <FormHelperText error>{lengthError}</FormHelperText>
      )}
    </div>
  );
};

export default RichTextEditor;
