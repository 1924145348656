import { omit } from 'lodash';
import { LogEntry } from '../../generated/graphql';
import {logger} from "../../helpers/logger";

const fields = `
  PKey
  LogEntry
  LogDate
  Equipment
  MaintKey
  EqKey
  JobNumber
  Checks
  DayLogType
  fldCost
  Curr
  fldCompany
  fldTime
  fldTimeQty
  fldHours
  fldPerformedBy
  fldEnteredBy
  fldGeoLocation
  Department
  fldLogEntryTime
  fldCrewID
  fldNoView
  fldRestricted
  fldBillable
  fldIsWarranty
  fldLocHierarchy
  DateEntered
  fldIsCheckList
  fldWorkList
  fldHTML
  fldSRHKey
  deletedAt
  updatedAt
  deletedBy
  isRecoverable
  fldSMS
  autoId
`;

export const LogEntryQuery = (doc: LogEntry) => {
  if (!doc) {
    doc = {
      autoId: 0,
      PKey: '',
      LogDate: '',
      LogEntry: '',
      updatedAt: new Date(0).toISOString(),

    };
  }
  logger('LogEntry')
    .info(`pull query triggered - LogEntry: { ${new Date().toISOString()} - ID: ${doc.autoId}, updatedAt: ${doc.updatedAt} }`,);
  return {
    query: `
      query GetLatestLogEntry($autoId: Int, $filterDate: Date) {
        LogEntry(
          where:{ 
            _and: [
              {
                _or: [
                  {
                    isRecoverable: {
                      _is_null: true
                    }
                  },
                  {
                    isRecoverable: {
                      _eq: true
                    }
                  }
                ]
              },
              {
                _or: [
                  {
                    updatedAt: {_gt: $filterDate},                
                  },
                  {
                    autoId: {_gt: $autoId}
                    updatedAt: {_gte: $filterDate},
                  }
                ]    
              },
              {
                _or: [
                  {
                    fldIsCheckList: { _is_null: true }
                  },
                  {
                    fldIsCheckList: { _eq: false }
                  }
                ]
              }
            ]                                
          },         
            order_by: [
              {updatedAt : asc},
              {autoId: asc}],
              limit: 2000
          ) {
            ${fields}            
          }
        }
      `,
    variables: {
      autoId: doc.autoId,
      filterDate: doc.updatedAt,
      // endDate: addDays(Date.now(), -30),
    },
  };
};

export const LogEntryMutation = (doc: any) => {
  const query = `
    mutation UpsertLogHistoryEntry($input: LogEntryInput!) {
      upsertLogHistoryEntry(input: $input) {
        ${fields}
      }
    }
  `;

  const variables = {
    input: omit(doc, ['updatedAt', 'deletedAt', 'accessDate']),
  };

  return {
    query,
    variables,
  };
};

export const logEntrySubscription = `
  subscription onLogEntryChanged {
    LogEntry {
      ${fields}      
    }
  }
`;
