// @ts-nocheck
import React, {
  FC,
  useCallback,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import LicensedReactDataGrid from '../../../components/UI/LicensedReactDataGrid';
import { pick, get, size, isEmpty, isNil, sortBy } from 'lodash';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import '../styles.css';
import { MangoQuerySortDirection } from 'rxdb/dist/types/types/rx-query';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import {exportCSV, exportExcel, getRegexByOperator} from '../../../utils';
import { getDatabase, TDIDb } from '../../../rxdb';
import { LogEntryDocument } from '../rxdb';
import PrintButton from '../../../components/UI/PrintButton';
import LogEntryPDF from '../../../components/UI/PDF/LogEntryPDF';
import DeleteRecordBtn from '../../../components/DeleteRecordBtn';
import { useAppState } from 'src/contexts/app-state';
import { useAuth } from 'src/contexts/auth';
import {TblDdListDefaultsDocument} from "../../../api/queries/tblDDListDefaults/rxdb";
import {CrewDocument} from "../../../rxdb/collections/Crew/schema";
import GridRowAttachmentIcon from "../../../components/UI/LicensedReactDataGrid/components/GridRowAttachmentIcon";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import GridDateFilterDialog from 'src/components/UI/GridDateFilterDialog';

type LogEntryGridProps = {
  darken: boolean;
  onSelect: (issue: LogEntryDocument) => void;
  sourceRoot?: boolean;
  editFlag?: boolean;
  moduleLevelSearch?: any;
};

const filter = [
  {
    name: 'LogDate',
    operator: 'inrange',
    type: 'date',
    value: '',
  },
  {
    name: 'LogEntry',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Department',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Equipment',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldWorkList',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'EnteredBy',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldPerformedBy',
    operator: 'contains',
    type: 'string',
    value: '',
  },
];

const transformData = async (issue: LogEntryDocument) => {
  const crewDetail = await issue.populate('fldCrewID');
  return {
    ...pick(issue, [
      'PKey',
      'LogEntry',
      'LogDate',
      'Department',
      'Equipment',
      'fldPerformedBy',
      'EqKey',
      'fldSRHKey',
      'MaintKey',
      'fldHTML',
      'fldCompany',
      'fldCost',
      'fldTime',
      'fldHours',
      'fldSMS',
      'fldWorkList'
    ]),
    // Equipment: (await issue.populate("EqKey"))?.UniqueName,
    // Department: (await issue.populate("Department"))?.fldMember,
    // fldWorkList: (await issue.populate("fldWorkList"))?.fldMember,
    // fldSMS: (await issue.populate('EqKey'))?.fldSMS,
    month: moment(issue.LogDate).format('MMMM'),
    year: moment(issue.LogDate).format('YYYY'),
    EnteredBy: isNil(crewDetail)
      ? null
      : `${crewDetail?.fldFirst} ${crewDetail?.fldLast}`,
    original: issue,
  };
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb
) => {
  switch (name) { 
    case 'PKey': {
      return {
        PKey: { $in: value },
      };
    }
    case 'LogDate': {
      if (!isEmpty(value.start) && (isNil(value.end) || isEmpty(value.end))) {
        const dateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          LogDate: { $gte: dateformate },
        };
      }
      if (!isEmpty(value.end) && (isNil(value.start) || isEmpty(value.start))) {
        const dateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          LogDate: { $lte: dateformate },
        };
      }
      if (!isEmpty(value.start) && !isEmpty(value.end)) {
        const startdateformate = moment(value?.start)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        const enddateformate = moment(value?.end)
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString()
          .split('.')[0];
        return {
          LogDate: { 
            $gte: startdateformate,
            $lte: enddateformate
          },
        };
      }

      return {};
    }
    case 'LogEntry':
      return {
        LogEntry: { $regex: getRegexByOperator(operator, value) },
      };
    case 'Department': {
      return {
        Department: { $regex: getRegexByOperator(operator, value) },
      };
    }
    case 'Equipment': {
      return {
        Equipment: { $regex: getRegexByOperator(operator, value) },
      };
    }
    case 'fldWorkList': {
      return {
        fldWorkList: { $regex: getRegexByOperator(operator, value) },
      };
    }
    case 'fldPerformedBy': {
      return {
        fldPerformedBy: { $regex: getRegexByOperator(operator, value) },
      };
    }
    case 'EnteredBy': {
      return {
        EnteredBy: { $regex: getRegexByOperator(operator, value) },
      };
    }

    default:
      return {};
  }
};

let currentCount = 0;

interface LogEntryGridRef {
  handleExport: (type: string) => void;
}

const LogEntryGrid = forwardRef<LogEntryGridRef, LogEntryGridProps>(
  (
    { onSelect, sourceRoot = false, editFlag = false, moduleLevelSearch = null }: LogEntryGridProps, ref
  ) => {
    const { user } = useAuth();
    const { settingsPersonal } = useAppState();
    const [loading, setLoading] = useState<boolean>(false);
    const highlightedRowId = useRef<string>('-1');
    const [gridRef, setGridRef] = useState<any>(null);
    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [groupCollapsedStatus, setGroupCollapsedStatus] = useState(false);
    const [logEntryData, setLogEntryData] = useState<any>(null);
    const [categoryData, setCategoryData] = useState<any>(null);
    const [exportDialog, setExportDialog] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [rowSelected, setRowSelected] = useState(null);
    // const [groupByFlag, setGroupByFlag] = useState(false);

    const isActionable =
      Object.values(rowSelected || {}).filter((item: any) => item).length > 0;

    const handleSelectionChange = useCallback(({ selected }) => {
      setRowSelected(selected);
    }, []);

    useImperativeHandle(ref, () => ({
      handleExport: (type) => {
        handleDataExport(type);
      },
    }));

    const handleGetCount = async () => {
      const db = await getDatabase();
      let selector = {
        $or: [
          { fldIsCheckList: { $eq: false } },
          { fldIsCheckList: { $eq: null } },
        ],
        deletedBy: {
          $eq: null,
        },
      };
      const length = size(await db.logentry.find({ selector }).exec());
      setTotalCount(length);
    };

    const loadData = async ({
      skip,
      limit,
      filterValue,
      sortInfo,
    }: {
      sortInfo: any;
      skip: number;
      limit: number;
      filterValue: any;
    }): Promise<{ data: any[]; count: number }> => {
      const db = await getDatabase();
      let preSort = {
        LogDate: 'desc' as MangoQuerySortDirection,
      };
    
      if (!isNil(sortInfo)) {
        preSort = {
          [sortInfo.name]:
            sortInfo.dir === 1
              ? ('asc' as MangoQuerySortDirection)
              : ('desc' as MangoQuerySortDirection),
        };
      }
    
      const sort = [
        {
          ...preSort,
        },
      ];

      // TODO: Handle filtervalue.OPERATOR
      let selector = {
        $or: [
          { fldIsCheckList: { $eq: false } },
          { fldIsCheckList: { $eq: null } },
        ],
        deletedBy: {
          $eq: null,
        },
      };

      // TODO: find a better solution, before RxDB sync disableEdit is false and the filtering is not happening
      const disableEdit = await settingsPersonal?.fldAllDepts === 0;
      if(disableEdit) {
        const deptsPermissionSelector = {
          Department: user?.Department
        };
        selector = {
          ...selector,
          ...deptsPermissionSelector,
        };
      }

      await Promise.all(
        filterValue.map(async (v) => {
          if (isEmpty(v.value)) return v;

          const s = await getSelectorByFilterName(
            v.name,
            v.value,
            v.operator,
            db
          );

          selector = {
            ...selector,
            ...s,
          };

          return v;
        })
      );

      const rawData = await db.logentry.find({ selector }).exec();
      const length = size(rawData);
      currentCount = length;

      const items = await db.logentry
        .find({
          selector,
          skip,
          limit,
          sort
        })
        .exec();

      const data = await Promise.all(items.map(transformData));
      setLogEntryData(rawData); // retrieve filtered data without pagination for reports
      return { data, count: length };
    };

    useEffect(() => {
      handleGetCount();
    }, [loading]);

    // Find if any filters applied to grid
    const areFiltersActive = gridRef?.current.computedFilterValue.some(
      (f) => !isEmpty(f.value)
    );

    const currentFilters = get(gridRef, ['current', 'computedFilterValue'], []);
    const printData = () => {
      window.location.href = instance.url;
    };
    const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
      const db = await getDatabase();

      // grouping not working with pagination
      // if (
      //   ref?.current &&
      //   Array.isArray(ref.current.computedGroupBy) &&
      //   (ref.current.computedGroupBy.includes('month') || ref.current.computedGroupBy.includes('year'))
      // ) {
      //   setGroupByFlag(true);
      // }

      const allRecord = await db.tblsystemshierarchy.find().exec();
      const result = {};
      allRecord.forEach((item: any) => {
        result[item.PKey] = item;
      });
      setCategoryData(result);
      
      // Always reload grid on new item / update / delete.
      db.logentry.$.subscribe(async (ev) => {
        if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
          highlightedRowId.current = ev.documentId;
          ref?.current?.reload();
        }
        if (ev.operation === 'DELETE') {
          highlightedRowId.current = '-1';
          ref?.current?.reload();
        }
      });
    };

    useEffect(() => {
      if (isNil(settingsPersonal) || isNil(gridRef)) return;

      if(moduleLevelSearch && moduleLevelSearch.length) {
        gridRef?.current?.setFilterValue([
          ...gridRef.current.computedFilterValue.filter(
            (f) => f.name !== 'PKey'
          ),
          {
            name: 'PKey',
            value: moduleLevelSearch,
          },
        ]);
      }
      else {
        // setResetFilterOnModuleSwitch(true);
        if (!isNil(gridRef)) {
          gridRef?.current?.setFilterValue(
            gridRef.current.computedFilterValue.filter(
              (f) => f.name !== 'PKey'
            )
          );
        }
      }

      // TODO: Please fix that, it creates subscription that's never unsubscribed
      init(gridRef);
    }, [settingsPersonal, gridRef, moduleLevelSearch]);

    const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
      setGridRef(ref);
      init(ref);
    };

    const onLoadingChange = (status: boolean) => {
      // If loading completed - check if there any items that needs to be highlighted.
      if (!status && highlightedRowId.current !== '-1') {
        gridRef?.current?.scrollToId(highlightedRowId.current);
        gridRef?.current?.setSelectedById(highlightedRowId.current, true);
        highlightedRowId.current = '-1';
      }
      setLoading(status);
    };

    const dataSource = useCallback(loadData, []);

    const onRowClick = useCallback(
      ({ data }) => {
        if (Object.keys(rowSelected || {}).length < 2) {
          onSelect(data.original);
        }
      },
      [rowSelected]
    );

    const exportData = (type = 'CSV' | 'xlsx', withFilters = true) => {
      setExportDialog(null);

      switch (type) {
        case 'CSV':
          return onExportToCSV(withFilters);
        case 'xlsx':
          return onExportToExcel(withFilters);
        default:
      }
    };

    const getRows = async (issue: any) => {
      const rows =  issue.map((data: any) =>{
        const logDateFormatted = moment(data.LogDate).format('DD-MMM-YYYY');
        return {
          ...pick(data, [
            'LogEntry',
            'Department',
            'Equipment',
            'fldPerformedBy',
            'EnteredBy',
          ]),
          LogDate: logDateFormatted,
        };
      }   
      );
      return rows
    };

    const onExportToExcel = async (withFilters: boolean) => {
      const { data } = await loadData({
        filterValue: withFilters ? currentFilters : [],
      });

      const columnsData = gridRef.current.visibleColumns.map((c: any) => ({
        header: c.header,
        key: c.id,
      }));
      const columns = columnsData.filter(item => {
        return item.header && typeof item.header === 'string';
      });

      const rows = await getRows(data)

      return exportExcel(columns, rows);
    };

    const onExportToCSV = async (withFilters: boolean) => {
      const columns = gridRef.current.visibleColumns;

      const { data } = await loadData({
        filterValue: withFilters ? currentFilters : [],
      });
      
      const rows = await getRows(data)

      return exportCSV(columns, rows);
    };

    const handleDataExport = (type = 'CSV' | 'xlsx') => {
      if (areFiltersActive) {
        setExportDialog({
          visible: true,
          type,
          title: type === 'CSV' ? 'CSV' : 'Excel',
        });

        return;
      }

      exportData(type, false);
    };

    const handleGeneratePDF = () => {
      if (categoryData) {
        logEntryData.forEach((item: any) => {
          item.category = categoryData[item.fldSRHKey];
        });
      }

      const sortedData = logEntryData?.sort((a: any, b: any) =>
        b.LogDate.localeCompare(a.LogDate)
      );
      return LogEntryPDF(sortedData, currentCount);
    };

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const renderGroupTitle = (value, { data }) => {
      const columns = data.fieldPath.map((col) =>
        col === data.name ? col.toUpperCase() : col
      );
      let path = columns && columns.length && columns.join('>');

      let dataValue = data.value;
      if (path.includes('LogDate')) {
        dataValue = moment(data.value).format('DD-MMM-YYYY');
      }
      return data.value === 'null' ? `No ${path} Assigned` : dataValue;
    };

    const CollapseButton = () => {
      if (groupCollapsedStatus) {
        setGroupCollapsedStatus(false);
        return gridRef.current.collapseAllGroups();
      }
      if (!groupCollapsedStatus) {
        setGroupCollapsedStatus(true);
        return gridRef.current.expandAllGroups();
      }
    };

    // grouping not working with pagination
    // const groupByMonth = async () =>{
    //   const grouped = gridRef?.current?.computedGroupBy
    //   if(!groupByFlag){      
    //   await gridRef?.current.setGroupBy(grouped.concat(['year','month']))
    //   await gridRef?.current?.reload()
    //   setGroupByFlag(true)
    //   }
    //   else{
    //     setGroupByFlag(false)
    //     const filterGroup = grouped.filter((e)=> e != 'month').filter((n)=> n!='year')
    //     await gridRef?.current.setGroupBy(filterGroup)
    //     await gridRef?.current?.reload()
    //   }
    // };
  
    const columns = [
      {
        id: 'icons',
        defaultWidth: 75,
        render: ({ data }: any) => (
            <GridRowAttachmentIcon selector={{ fldRecordKey: data.PKey }} showErrorIcon={data.fldSMS} />
        ),
        onRender: (cellProps: any, { data }: { data: LogEntryDocument }) => {
          cellProps.style.borderLeft = '#e4e3e2 3px solid';
        },
        sortable: false
      },

      {
        name: 'LogDate',
        header: 'Date',
        defaultFlex: isDesktop ? 1.5 : undefined,
        headerAlign: 'start' as any,
        textAlign: 'end' as any,
        dateFormat: 'DD-MMM-YYYY',
        filterEditor: DateFilter,
        filterEditorProps: (props, { index }) => {
          return { placeholder: index == 1 ? 'End...' : 'Start...' };
        },
        render: ({ value, cellProps: { dateFormat } }) => {
          if (!isNil(value)) {
            return moment(value).format(dateFormat);
          }
        },
      },
      {
        name: 'year',
        header: 'Log Date Year',
        defaultFlex: isDesktop ? 1 : undefined,
        defaultVisible: false,
      },
      {
        name: 'month',
        header: 'Log Date Month',
        defaultFlex: isDesktop ? 1 : undefined,
        defaultVisible: false,
      },
      {
        name: 'Equipment',
        header: 'Equipment',
        defaultFlex: isDesktop ? 1 : undefined,
        sortable: false
      },
      {
        name: 'fldWorkList',
        header: 'Work List',
        defaultFlex: isDesktop ? 1 : undefined,
        sortable: false
      },
      {
        name: 'LogEntry',
        header: 'Log Entry',
        defaultFlex: isDesktop ? 1 : undefined,
        sortable: false
      },
      {
        name: 'fldPerformedBy',
        header: 'Performed By',
        defaultFlex: isDesktop ? 1 : undefined,
        sortable: false
      },
      {
        name: 'EnteredBy',
        header: 'Entered By',
        defaultFlex: isDesktop ? 1 : undefined,
        sortable: false
      },
      {
        name: 'Department',
        header: 'Department',
        defaultFlex: isDesktop ? 1 : undefined,
        sortable: false
      },
    ];

    const footerRows = [
      {
        position: 'end',
        render: {
          icons: () => {
    
            const style = {
              paddingLeft: 20,
            };
    
            return (
              <div style={style}>
                Total Records: {currentCount}/{totalCount}
              </div>
            );
          },
        },
        colspan: {
          icons: (_, computedProps) => computedProps.visibleColumns.length,
        },
      },
    ];
    
    return (
      <div data-testid="data-grid" className="flex flex-col flex-grow">
        <div className="flex flex-row items-center justify-end">
          {!isEmpty(gridRef?.current.computedGroupBy) &&
            (groupCollapsedStatus ? (
              <div>
                <Tooltip title="Collapse All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldLessIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div>
                <Tooltip title="Expand All">
                  <IconButton onClick={CollapseButton}>
                    <UnfoldMoreIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ))}

          {settingsPersonal?.fldLogsMaint && (
            <DeleteRecordBtn
              visible={isActionable}
              records={rowSelected}
              onSelect={setRowSelected}
            />
          )}
          {/* grouping not working with pagination */}
          {/* {groupByFlag ? (
            <Tooltip title="Remove Group by Year/month">
              <IconButton onClick={groupByMonth}>
                <GroupRemoveIcon />
              </IconButton>
            </Tooltip>) : (
            <Tooltip title="Group by Year/month of log Date">
              <IconButton onClick={groupByMonth}>
                <GroupAddIcon />
              </IconButton>
            </Tooltip>)
          } */}
          <PrintButton
            generatePDF={handleGeneratePDF}
            enable={!!logEntryData}
            title='Print Log Entry'
            recordsCount={currentCount}
          />
        </div>
        <LicensedReactDataGrid
          onRowClick={onRowClick}
          onLoadingChange={onLoadingChange}
          defaultLimit={18}
          pagination
          livePagination
          onReady={onReady}
          rowHeight={40}
          // defaultGroupBy={[]} // groupBy not working with pagination
          onGroupByChange={() => gridRef.current.collapseAllGroups()}
          collapsedGroups={collapsedGroups}
          onGroupCollapseChange={setCollapsedGroups}
          loading={loading}
          enableSelection
          defaultFilterValue={filter}
          idProperty="PKey"
          columns={columns}
          dataSource={dataSource}
          renderGroupTitle={renderGroupTitle}
          footerRows={footerRows}
          selected={rowSelected}
          onSelectionChange={handleSelectionChange}
          checkboxColumn
          sourceRoot={sourceRoot}
        />
        <Dialog maxWidth="xs" open={exportDialog?.visible || false}>
          <DialogTitle>
            Export data to
            {exportDialog?.title}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              You have filters applied. Would you like to export with current
              filters? update
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              autoFocus
              onClick={() => exportData(exportDialog?.type, false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => exportData(exportDialog?.type)}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export default LogEntryGrid;
