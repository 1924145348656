import React, { FC } from 'react';
import {
  Button,
  ButtonPropsVariantOverrides,
  useMediaQuery,
  useTheme,
  Icon,
  IconButton,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { OverridableStringUnion } from '@mui/types';
import ClockIconAdd from '../../../assets/icon-clock-add-primary.svg';
import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { AMCSBadge } from '../AMCSBadge';
import { Info, ArrowBack, CloseTwoTone } from '@mui/icons-material';

interface Action {
  onClick: () => void;
  title?: string;
  icon?: string;
  startIcon?: JSX.Element;
  invisibleBadge?:boolean;
  customComponent?: JSX.Element;
  type?: OverridableStringUnion<
    'text' | 'outlined' | 'contained',
    ButtonPropsVariantOverrides
  >;
  iconbutton?: boolean;
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  disabled?: boolean;
}

interface InjectedProps {
  title: string;
  actions?: Action[];
  renderView?: any;
  renderSearch? : any;
  renderMasterSearch? : any;
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const PageHeader: FC<InjectedProps> = ({
  title,
  actions = [],
  children,
  renderView,
  renderSearch,
  renderMasterSearch,
}) => {
  const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
  const isMobile = useMediaQuery('(max-width: 420px)');
  
  const getIconForTitle = (title: string|undefined) => {
    // Add your switch case logic here to determine the image based on the title
    switch (title) {
      case 'clock':
        return (
          <Icon>
              <img src={ClockIconAdd} alt="" />
          </Icon>
        )
      case 'history':
        return (
          <RestoreTwoToneIcon/>
        );
      case 'info':
        return (
          <Info fontSize="medium"/>
        );
      case 'back':
        return (
          <ArrowBack fontSize="medium"/>
        );
      case 'close':
        return (
          <CloseTwoTone fontSize="medium"/>
        );
      default:
        return null;
    }
  };

  const renderActionButtons = () => {
    return (actions.map(
      ({
        icon,
        startIcon,
        title,
        type,
        onClick,
        iconbutton,
        color,
        customComponent,
        disabled,
        invisibleBadge=true,
      }, index) =>
        (!iconbutton && !customComponent && (
          <Button
            key={title}
            variant={type || 'contained'}
            onClick={onClick}
            className="ml-1 p-2"
            sx={{ height: 36 }}
            startIcon={startIcon || <AddIcon />}
            disabled={disabled}
          >
            {title}
          </Button>
        )) ||
        (!icon && customComponent) || (      
            <Tooltip key={index} title={title || ''}>
              <IconButton
                key={title}
                onClick={onClick}
                color={color}
                aria-label={title}                      
                disabled={disabled}
                className={!invisibleBadge? 'pr-8':''}
              >
                <AMCSBadge color="secondary" badgeContent={title}
                invisible={invisibleBadge}
                >
                  {getIconForTitle(icon)}
                </AMCSBadge>
              </IconButton>
            </Tooltip>

        )
    ))
  }
  if(isMobile) {
    return(
      <Grid container spacing={2} className={"pt-1"}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={12} className={"pb-2"}>
            <Box>
              <h2 className={"font-bold text-2xl text-left ml-2 mt-2"}>{title}</h2>
            </Box>
          </Grid>
          <Grid container item xs={12} className={"pb-1 mt-2 text-right"} flexDirection="row" justifyContent="flex-end">
            <Box className={"pr-2"} style={{paddingLeft: '10px', flex: 1 }}>{renderSearch && renderSearch()}</Box>
            <Box className={"pr-1"}>{renderView && renderView()}</Box> 
            <Box>{renderActionButtons()}</Box>
          </Grid>
          <Grid container item xs={12} className={"pb-2 text-right"} flexDirection="row" justifyContent="flex-end">
            <Box style={{paddingLeft: '10px', flex: 1 }}>{renderMasterSearch && renderMasterSearch()}</Box>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container spacing={2} className={"pt-4"}>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={4} className={"pb-2"}>
          <Box>
            <h2 className={"font-bold text-2xl text-left ml-2 mt-2"}>{title}</h2>
          </Box>
        </Grid>
        <Grid container item xs={8} className={"mt-2 text-right"} flexDirection="row" justifyContent="flex-end">
          <Box className={"pr-2 ml-2"}> {renderSearch && renderSearch()}</Box> 
          <Box className={"pr-2"}> {renderMasterSearch && renderMasterSearch()}</Box> 
          <Box className={"pr-1"}> {renderView && renderView()}</Box>
          <Box>{renderActionButtons()}</Box>
        </Grid>            
      </Grid>
    </Grid>
  );  
};

export default PageHeader;
