import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import React, { FC, useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { LogEntryDocument } from 'src/pages/LogEntryPage/rxdb';
import Tabs from '../../UI/Tabs';
import { useAuth } from '../../../contexts/auth';
import SparesTab from '../../SparesTab';
import AttachmentTab from '../../../modules/Attachments';
import Comments from '../../../modules/Comments';
import { TblSparesUsedDocument } from '../../SparesTab/rxdb';
import { getDatabase } from '../../../rxdb';
import { ISpareFormPayload } from '../../../types/spares';
import LogEntrySummaryForm from './component/LogEntrySummaryForm';
import { normalizeDateFormValue } from 'src/helpers';
import { filterNonNullStrings, stopPropagate } from 'src/utils';

interface Props {
  workIssue: WorkIssuesDocument;
  logEntry: LogEntryDocument;
  onSubmit: (data: any, spares: TblSparesUsedDocument[]) => void;
}

const LogEntryWithWorkIssueDetailsForm: FC<Props> = ({
  workIssue,
  logEntry,
  onSubmit,
}) => {
  const [spares, setSpares] = useState<TblSparesUsedDocument[]>([]);
  const [isSparesLoading, setSparesLoading] = useState<boolean>(false);

  const { user } = useAuth();
  const {
    control, watch, setValue, handleSubmit, getValues, reset,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      fldCompany: workIssue.Company || '',
      Department: workIssue.Department || null,
      LogDate: normalizeDateFormValue(logEntry.LogDate || new Date()),
      fldCost: workIssue.fldActualCost || logEntry.fldCost || 0,
      fldEnteredBy: logEntry.fldEnteredBy || user?.fldCrewID || '',
      LogEntry: `${workIssue.Subject}${
        workIssue.Notes ? `<br/><br/>${workIssue.fldHTML}` : ''
      }`,
      fldHTML:`${workIssue.Subject}${
        workIssue.Notes ? `<br/><br/>${workIssue.fldHTML}` : ''
      }`,
      fldHours: logEntry.fldHours || '',
      fldIsWarranty: workIssue.fldIsWarranty,
      fldPerformedBy: isEmpty(logEntry.fldPerformedBy)
      ? [`${user?.fldFirst} ${user?.fldLast}`]
      : logEntry.fldPerformedBy?.split(', '),
      // fldPerformedBy: logEntry.fldPerformedBy || user?.fldCrewID || '',
      fldRestricted: logEntry.fldRestricted || false,
      fldSMS: logEntry.fldSMS || false,
      fldTime: workIssue.fldActualWork || logEntry.fldTime || '',
      fldTimeQty: logEntry.fldTimeQty || '',
      fldWorkList: workIssue.fldWorkList,
      EqKey: workIssue.EqKey,
      Equipment: null,
      fldSRHKey: workIssue.fldSRHKey,
      fldLocHierarchy: workIssue.fldLocHierarchy,
      Curr: workIssue.fldActualCurrency || logEntry.Curr || null,
    },
  });

  const setInitialValues = async () => {
    // const department = await workIssue.populate('Department');
    // const worklist = await workIssue.populate('fldWorkList');
    const equipment = await workIssue.populate('EqKey');

    reset({
      ...getValues(),
      Equipment: equipment?.toJSON() || null,
      // fldWorkList: worklist?.toJSON() || null,
      // Department: department?.toJSON() || null,
    });
  };
  const getSpares = async () => {
    const db = await getDatabase();

    db.tblsparesused
      .find({
        selector: {
          WorkKey: get(workIssue, 'JobNumber', ''),
          deletedAt: null
        },
      })
      .$.subscribe((spares: TblSparesUsedDocument[] | null) => {
        setSpares(spares || []);
        setSparesLoading(false);
      });
  };

  const handleSpareDelete = async (spare: TblSparesUsedDocument) => {
    setSpares(spares.filter((s) => s.PKey !== spare.PKey));
  };
  const handleSpareCreate = async (
    payload: ISpareFormPayload,
    isCreate: boolean,
  ) => {
    const db = await getDatabase();
    const index = spares.findIndex((spare) => spare.PKey === payload.PKey);

    const doc = {
      PKey: payload.PKey || uuid(),
      DateEntered: new Date().toISOString(),
      Amount: payload.Amount,
      LogKey: logEntry.PKey,
      ProductID: payload.ProductID || '',
      WorkKey: workIssue.JobNumber,
    };

    if (index === -1) {
      const spare = await db.tblsparesused.newDocument(doc);
      await db.collections.tblsparesused.upsert(doc);
      return setSpares([...spares, spare]);
    }

    const cloned = spares.slice();
    cloned[index] = await db.tblsparesused.newDocument({
      ...cloned[index]?.toJSON(),
      ...doc,
    });
    return setSpares(cloned);
  };

  const onFormSubmit = (data: any) => {
    onSubmit(data, spares)
  };

  useEffect(() => {
    setInitialValues();
    getSpares();
  }, []);

  const onChange = (name: string, value: any) => setValue(name, value);

  const relatedKeys = filterNonNullStrings([workIssue.JobNumber]);

  return (
    <form
      id="logentry-details-form-workissue"
      className="relative bg-white flex-grow"
      onSubmit={stopPropagate(handleSubmit(onFormSubmit))}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          <div className="mb-6">
            <h3 className="text-xl font-bold">{workIssue.Subject}</h3>
          </div>

          <div className="mt-3 mb-20">
            {
              <Tabs
                tabs={[
                  {
                    label: 'Summary',
                    component: (
                      <LogEntrySummaryForm
                        watch={watch}
                        control={control}
                        initialValue={logEntry}
                        onChange={onChange}
                      />
                    ),
                  },
                  {
                    label: 'Spares ',
                    component: (
                      <SparesTab
                        loading={isSparesLoading}
                        spares={spares}
                        issue={workIssue}
                        onDelete={handleSpareDelete}
                        onCreate={handleSpareCreate}
                      />
                    ),
                  },
                  {
                    label: 'Attachments',
                    component: (
                      <AttachmentTab
                        type="attachments"
                        primaryKey={workIssue.JobNumber}
                        relatedKeys={relatedKeys}
                        SRHKey={workIssue.fldSRHKey}
                        allowedFileExtensions={['.pdf']}
                        tableName="LogEntry"
                      />
                    ),
                  },
                  {
                    label: 'Photos',
                    component: (
                      <AttachmentTab
                        type="photo"
                        primaryKey={workIssue.JobNumber}
                        relatedKeys={relatedKeys}
                        SRHKey={workIssue.fldSRHKey}
                        allowedFileExtensions={['.jpg', '.jpeg', '.gif', '.png']}
                        tableName="LogEntry"
                      />
                    ),
                  },
                  {
                    label: 'Comments',
                    component: (
                      <Comments
                        selectorType="WorkIssues"
                        selectorKeyValue={workIssue.JobNumber}
                        // disabled={true} // TD-1135 Fix comment not carry over from task to logentry (#966)
                        disableEdit={false} // TD-1676 Fix Unable to add a comment when completing a Task
                      />
                    ),
                  },
                ]}
              />
            }
          </div>
        </div>
      </div>
    </form>
  );
};

export default LogEntryWithWorkIssueDetailsForm;
