import React, {
    useState,
    createContext,
    useContext,
    useEffect,
    FC,
} from 'react';
import { getDatabase } from '../rxdb';

export type AppStateProps = {
    settingsPersonal: any;
    setSettingsPersonal: (data: any) => void;
    tableState: any;
    setTableState: (data: any) => void;
    hierarchyState: any;
    setHierarchyState: (data: any) => void;
};

export type AppStateProviderProps = {
    children?: JSX.Element;
};

const AppStateProvider: FC<AppStateProviderProps> = ({ children }) => {
    const [settingsPersonal, setSettingsPersonal] = useState<any>(null);
    const [tableState, setTableState] = useState<any>();
    const [hierarchyState, setHierarchyState] = useState<any>();

    useEffect(() => {
        handleLoadSettingsPersonal();
        handleLoadTableState();
    }, []);

    useEffect(() => {
        handleSaveTableState()
    }, [tableState])

    const handleLoadSettingsPersonal = async () => {
        const db = await getDatabase();

        db.tblsettingspersonal.find().$.subscribe((settingsPersonalUpdate) => {
            if (settingsPersonalUpdate && settingsPersonalUpdate[0]) {
                setSettingsPersonal(settingsPersonalUpdate[0]);
            }
        });
    }

    const handleLoadTableState = async () => {
        try {
            const recordData = localStorage.getItem('tableStateRecord');
            if (recordData) {
                const gridState = JSON.parse(recordData);
                setTableState(gridState)
            }
        } catch (e: any) {
            console.log("Error:", e.message)
        }
    }

    const handleSaveTableState = () => {
        localStorage.setItem('tableStateRecord', JSON.stringify(tableState));
    }

    return (
        <AppStateContext.Provider
            value={{
                settingsPersonal,
                setSettingsPersonal,
                tableState,
                setTableState,
                hierarchyState,
                setHierarchyState
            }}
        >
            {children}
        </AppStateContext.Provider>
    );
};

const AppStateContext = createContext<AppStateProps>({
    setSettingsPersonal: (data: any) => { },
    settingsPersonal: null,
    tableState: {},
    setTableState: (data: any) => { },
    hierarchyState: {},
    setHierarchyState: (data: any) => { }
});
const useAppState = () => useContext(AppStateContext);

export { AppStateProvider, useAppState };
