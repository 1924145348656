import React, { Component } from "react";
import PSPDFKitWeb, { Instance } from "pspdfkit";
import { PSPDF_LICENSE } from "src/consts";

export default class PSPDFKitPrint extends Component {
    containerRef = React.createRef();

    componentDidMount() {
        const url = URL.createObjectURL(this.props.blob);
        PSPDFKitWeb.load({
            document: url,
            container: this.containerRef.current,
            licenseKey:PSPDF_LICENSE,
            baseUrl: `${window.location.protocol}//${window.location.host}/`,
        }).
        then(instance => {
            URL.revokeObjectURL(url);
            instance.print();
        });
    }

    componentWillUnmount() {
        PSPDFKitWeb.unload(this.containerRef.current);
    }

    render() {
        return (
            <div
                ref={this.containerRef}
                style={{ width: "100%", height: "100%", position: "absolute" }}
            />
        );
    }
}
