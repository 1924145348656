import React, { FC, useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { TblDdListDefaultsDocument } from '../../../../api/queries/tblDDListDefaults/rxdb';
import { getDatabase } from '../../../../rxdb';
import { toJSON } from '../../../../utils';
import Autocomplete from '../Autocomplete';

interface InjectedProps {
  listName: string;
  onChange: (name: string, value: any) => void;
  freeSolo?: boolean;
  label?: string;
  control: Control;
  name: string;
  disabled?: boolean;
}

const ListDefaultAutocomplete: FC<InjectedProps> = ({
  listName,
  onChange,
  freeSolo,
  label,
  control,
  name,
  ...rest
}) => {
  const [data, setData] = useState<TblDdListDefaultsDocument[]>([]);

  const init = async () => {
    const db = await getDatabase();

    db.tblddlistdefaults.find({ selector: { deletedBy: { $eq: null } }}).$.subscribe((defaults) => {
      const data = defaults.filter((d) => d.fldListName === listName && d.fldMember !== "");
      setData(toJSON(data));
    });
  };

  useEffect(() => {
    init();
  }, [listName]);

  return (
    <Autocomplete
      {...rest}
      defaultPayload={{
        fldListName: listName,
        fldT4Reqd: true,
      }}
      displayExpr="fldMember"
      keyExpr="PKey"
      onChange={onChange}
      freeSolo={freeSolo}
      options={data}
      label={label || listName}
      control={control}
      name={name}
      collection="tblddlistdefaults" // Use this in order to create new items.
    />
  );
};

export default ListDefaultAutocomplete;
