import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import './styles.scss';
import { isNil } from 'lodash';
import { v4 } from 'uuid';
import { Alert, Snackbar } from '@mui/material';
import { Subscription } from 'rxjs';
import Comment from '../Comment';
import { CommentDocument } from '../../../../rxdb/collections/Comments/schema';
import { getDatabase } from '../../../../rxdb';
import CommentInput from '../CommentInput';
import { useAuth } from '../../../../contexts/auth';

interface InjectedProps {
  comment: CommentDocument;
  disableEdit?: boolean;
}

const CommentReplies: FC<InjectedProps> = ({ comment, disableEdit = false }) => {
  const { user } = useAuth();
  const [comments, setComments] = useState<CommentDocument[]>([]);
  const subscription = useRef<Subscription>();
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const loadReplies = async () => {
    const db = await getDatabase();

    subscription.current = db.comments
      .find({
        selector: {
          replyId: comment.id,
          deletedBy: null
        },
        sort: [{ createdAt: 'desc' }],
      })
      .$.subscribe((v) => setComments(v));
  };

  useEffect(() => {
    loadReplies();

    return () => {
      subscription.current?.unsubscribe();
    };
  }, []);

  const onSubmit = async (content: string, id?: string) => {
    if (isNil(user)) return;

    const db = await getDatabase();

    try {
      await db.comments.upsert({
        ...comment.toJSON(),
        id: id || v4(),
        content,
        replyId: comment.id,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        authorId: user.fldCrewID,
      });

      setSnackbar({
        open: true,
        message: 'Comment successfully has been created',
        type: 'success',
      });
    } catch (e: any) {
      // console.error(e);
      setSnackbar({
        open: true,
        message: e.message,
        type: 'error',
      });
    }
  };

  const renderCommentList = () =>
  // TODO: Think on how should it work. Replace me with react-window once decided.
  // TODO: Big issue with input + height inside drawer.

    comments.map((comment) => (
      <Comment key={comment.id} comment={comment} disableEdit={disableEdit} />
    ));
  return (
    <div className="w-full">
      {renderCommentList()}

      {<CommentInput className="pt-2 pb-2" onSubmit={onSubmit} readOnly={disableEdit}/>}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CommentReplies;
