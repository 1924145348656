import { useState, SyntheticEvent, FC, ReactNode } from 'react';
import { Tab, Tabs as MaterialTabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import TabPanel from './components/TabPanel';

interface Tab {
  label: string | ReactNode;
  component: ReactNode;
}
interface InjectedProps {
  onChange?: (value: any) => void;
  tabs: Tab[];
  className?: string;
  'data-testid'?: string;
  tabValue?: number;
}

const StyledTabs = styled(MaterialTabs)(({ theme }) => ({
  backgroundColor: '#f8f8f8',
  '& .MuiTab-root': {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
}));

const Tabs: FC<InjectedProps> = (props) => {
  const { onChange, tabs, className = 'h-full', tabValue} = props;
  const [currentTab, setCurrentTab] = useState<number>(0);

  const onTabChange = (event: SyntheticEvent, value: any) => {
    onChange && onChange(value);
    if(tabValue !== undefined && !isNaN(tabValue)) {
      setCurrentTab(tabValue)
    } else {
      setCurrentTab(value);
    }
  };

  const renderTabButton = (tab: Tab, index: number) => {
    if (typeof tab.label === 'string') return <Tab key={index} label={tab.label} />;

    return tab.label;
  };

  return (
    <div className={`${className}`} data-testid={props['data-testid']}>
      <StyledTabs
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onTabChange}
        aria-label="Tabs"
      >
        {tabs.map(renderTabButton)}
      </StyledTabs>

      {tabs.map((tab, index) => (
        <TabPanel
          value={currentTab}
          index={index}
          key={index}
        >
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
};

export default Tabs;
