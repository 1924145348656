import './styles.css';
import React, { useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { uuid } from 'uuidv4';
import { Alert, Snackbar, Button } from '@mui/material';
import AttachmentGrid from './component/AttachmentGrid';
import { getDatabase } from '../../rxdb';
import AttachmentPopup from './component/AttachmentPopup';
import { handleAttachmentUpsert, handleDeleteDocument } from "src/utils";
import AddIcon from '@mui/icons-material/Add';

export type AlertColor = 'success' | 'info' | 'warning' | 'error';
type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;

interface Props {
  form?: any;
  data?: any;
  allowedFileExtensions: any;
  primaryKey: string | null;
  relatedKeys: string[];
  type?: string;
  disabled?: boolean;
  SRHKey?: string | null;
  tableName?: string;
  disableEdit?: boolean;
  disableCheckbox?: boolean;
}

export interface IAttachmentState {
  PKey: string;
  fldFKey: string | null;
  fldRecordKey?: string | null;
  fldAltPath?: number | null;
  fldFileName?: string | null;
  DocTitle?: string;
  fileExtension?: string;
  fldDescription?: string | null;
  fldNotes?: string | null;
  fldPage?: any | null;
  fldRevision?: any | null;
  fldRevisionDate?: string | null;
  fldShowInCentral?: boolean | null;
  deletedAt?: string | null;
  fldLibtype?: any | null;
}

interface IClickEvent {
  type: 'remove' | 'insert' | 'update';
  key: string;
  data?: IAttachmentState;
}

interface IFile {
  contentType: string;
  extension: string;
  key: string;
  name: string;
  url: string;
}

const defaultFile = {
  contentType: '',
  extension: '',
  key: '',
  name: '',
  url: '',
};

const AttachmentTab = ({
  primaryKey,
  relatedKeys,
  allowedFileExtensions,
  type = 'attachments',
  disabled = false,
  SRHKey,
  tableName = '',
  disableEdit = false,
  disableCheckbox = false,
}: Props) => {
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<Severity>(undefined);
  const [uploadedFile, setUploadedFile] = useState<IFile>(defaultFile);
  const [reloadPhotos, setReloadPhotos] =useState<boolean>(false);
  const [isFormVisible, setFormVisible] = useState<boolean>(false);
  const [editableItem, setEditableItem] = useState<IAttachmentState>();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const handleUpsert = async (data?: IAttachmentState) => {
    setSaveLoading(true)
    try {
      await handleAttachmentUpsert(SRHKey, primaryKey, tableName, uploadedFile, data);
      setEditableItem(undefined);
      setFormVisible(false);
      setAlert(true);
      setAlertMessage(
        !isNil(editableItem)
          ? 'Record successfully updated'
          : 'Record successfully created',
      );
      setAlertSeverity('success');
      setUploadedFile(defaultFile);
      setReloadPhotos(!reloadPhotos); 
    } catch (e: any) {
      console.error(e);
      setAlert(true);
      setAlertMessage(e.message);
      setAlertSeverity('error');
    }
    setSaveLoading(false)
  };

  const handleDelete = async (data: IAttachmentState) => {
    const key = data.PKey;
    const db = await getDatabase();

    try {
      // Remove all revisions per this document
      const documentCross = await db.tbldocumentcrossreference
        .findOne({
          selector: {
            _id: key,
          },
        })
        .exec();

      if (isNil(documentCross)) throw new Error('Document not found!');
      await handleDeleteDocument(documentCross.fldFKey)      
      setAlert(true);
      setAlertMessage('Record successfully removed');
      setAlertSeverity('success');
      if(type === "photo"){
        setReloadPhotos(!reloadPhotos);
      }
      
    } catch (e: any) {
      console.error(e);
      setAlert(true);
      setAlertMessage(e.message);
      setAlertSeverity('error');
    }
  };

  const handleAddClick = () => {
    setEditableItem(undefined);
    setFormVisible(true);
    setUploadedFile(defaultFile);
  };

  const onFormCancel = () => {
    setEditableItem(undefined);
    setFormVisible(false);
    setUploadedFile(defaultFile);
  };

  const onEdit = (data: IAttachmentState) => {
    setEditableItem(data);
    setFormVisible(true);
  };

  return (
    <>
      <div>
        {!disableEdit && 
          <div className="flex justify-end mb-4">
            <Button 
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddClick}
              disabled={disableEdit}
            >
              Add
            </Button>
          </div>
        }
        <AttachmentGrid
          relatedKeys={relatedKeys}
          allowedFileExtensions={allowedFileExtensions}
          setUploadedFile={setUploadedFile}
          type={type}
          onEdit={onEdit}
          onDelete={handleDelete}
          disabled={disabled}
          reload={reloadPhotos}
          editDisabled={disableEdit}
        />
      </div>

      <AttachmentPopup
        attachmentValue={editableItem}
        recordKey={primaryKey}
        tableName={tableName}
        onCancel={onFormCancel}
        onSubmit={handleUpsert}
        visible={isFormVisible}
        setUploadedFile={setUploadedFile}
        uploadedFile={uploadedFile}
        type={type}
        saveLoading={saveLoading}
        disableCheckbox={disableCheckbox}
      />

      <Snackbar
        open={alert}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setAlert(false);
        }}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default AttachmentTab;
