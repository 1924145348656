import React, { FC, useState } from 'react';
import {
    IconButton,
} from '@mui/material';
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone';
import WarningDialog from 'src/components/UI/WarningDialog';
import { useAppState } from 'src/contexts/app-state';
import { withSnackbar } from "src/components/UI/SnackbarHOC";
import { useAuth } from 'src/contexts/auth';

interface DeleteRecordBtnProps {
    visible: boolean;
    records: any;
    onSelect: (data: any) => void;
    keyField?: any;
    warningMessage?: string;
    snackbarShowMessage: any
}

const DeleteRecordBtn: FC<DeleteRecordBtnProps> = ({
    visible,
    records,
    onSelect,
    keyField = 'original',
    warningMessage = 'Are you sure you wish to delete record(s)?',
    snackbarShowMessage,
}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const  { user } = useAuth();
    const { settingsPersonal } = useAppState();
    const handleDelete = async () => {
        setIsDeleting(true);
    }

    const handleDeleteOk = async () => {
        var allRecordNotDeleted = false
        try {
            const recordList: any = Object.values((records || {}));
            for (const item of recordList) {
                if(item[keyField]?.fldRestricted && !settingsPersonal.fldDeptHead){
                    allRecordNotDeleted = true
                } else if (settingsPersonal.fldAllDepts === 1 && item[keyField]?.Department != user?.Department){
                    allRecordNotDeleted = true
                } 
                else {
                    if (keyField) {
                        await item[keyField].remove()
                    } else {
                        await item.remove()
                    }
                }
            }
            onSelect({})
        } catch (error) {
            console.log("delete error ---", error)
        }
            if(allRecordNotDeleted){
                snackbarShowMessage('You do not have permission to delete record(s), some record(s) are not deleted', "error", 5000);
            } else{
                snackbarShowMessage('Removed all selected record(s)', "success", 3000);
            }
        setIsDeleting(false);
    };

    const handleDeleteCancel = () => {
        setIsDeleting(false);
    }

    if (!visible) {
        return null
    }
    return (
        <>
            <IconButton
                onClick={handleDelete}
                aria-label="Delete Records"
                color="error"
            >
                <DeleteTwoTone />
            </IconButton>
            <WarningDialog
                visible={isDeleting}
                title="Delete Warning"
                content={warningMessage}
                okText='Yes'
                color='error'
                onOk={handleDeleteOk}
                onCancel={handleDeleteCancel}
            />
        </>
    )
}

export default withSnackbar(DeleteRecordBtn)