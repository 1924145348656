import { ChangeEventHandler, FC } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Button,
  Box,
  TextField,
} from "@mui/material";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { LoadingButton } from "@mui/lab";
import { Control } from "react-hook-form";
import Input from "src/components/UI/Forms/Input";

interface LogEntryEquipmentHoursDialogProps extends Partial<DialogProps> {
  visible: boolean;
  title: string;
  okText?: string;
  cancelText?: string;
  onOk: () => void;
  onCancel: () => void;
  color?: any;
  disabled?: boolean;
  loading?: boolean;
  equipmentHours: number;
  fldHours: number;
  onChange: (value: any) => void;
}

const LogEntryEquipmentHoursDialog: FC<LogEntryEquipmentHoursDialogProps> = ({
  visible,
  title,
  cancelText = 'Cancel',
  okText = "OK",
  color="error",
  onCancel,
  onOk,
  disabled = false,
  loading = false,
  equipmentHours,
  fldHours =0,
  onChange,
}) => {

  const handleChangeWrapper: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event: { target: { name: any; value: any; }; }) => {
    const { value } = event.target;
    onChange(value);
  };
  return (
    <Dialog
      open={visible}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="warning-dialog"
    >
      <DialogTitle id="alert-dialog-title">{(<WarningTwoToneIcon fontSize="small" color={color} className="mr-3"/>)}{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            
            <div className="ml-3">
              <span className="mr-3">Current Equipment hours:</span>
            
              <TextField
               value={equipmentHours}
               fullWidth
               autoComplete='off'
               rows={1}
               multiline={false}
               size='small'
               disabled={true}
               className="w-1/2"
              />
         
            </div>

            <div className="ml-3 mt-4">
              <span className="mr-3">Update Equipment hours:</span>
              <TextField
                value={fldHours}
                fullWidth
                autoComplete='off'
                rows={1}
                multiline={false}
                size='small'
                type="number"
                inputProps={{ min: 0, style: { textAlign: 'end' } }}
                onChange={handleChangeWrapper}
                className="w-1/2"
              />
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
        <Box sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}>
          {!disabled && 
          <LoadingButton loading={loading} data-testid="warning-dialog-confirm" className="mx-2" onClick={onOk}>
            {okText}
          </LoadingButton>}
        </Box>
        <Button data-testid="warning-dialog-cancel" onClick={onCancel} variant="contained" autoFocus>{cancelText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogEntryEquipmentHoursDialog;