import { CircularProgress } from '@mui/material';
import React, { ComponentType, Suspense, lazy } from 'react';
import { FileViewerPage } from 'src/components/FileViewer';
import { HomePage } from '../pages';
const AccountPage = lazy(() => import('../pages/AccountPage'));
const WorkIssuesPage = lazy(() => import('../pages/WorkIssuesPage'));
const YMSSettingsPage = lazy(() => import('../pages/YMSSettingsPage'));
const ListUtilityPage = lazy(() => import('../pages/ListUtilityPage'));
const SystemsHierarchyPage = lazy(() => import('../pages/SystemsHierarchyPage'));
const LocationsHierarchyPage = lazy(() => import('../pages/LocationsHierarchyPage'));
const RecycleBinPage = lazy(() => import('../pages/RecycleBinPage'));
const WorkIssuesCertificatesPage = lazy(() => import('../pages/WorkIssuesCertificatesPage'));
const InventoryPage = lazy(() => import('../pages/InventoryPage'));
const RefrigeratedStoresPage = lazy(() => import('../pages/RefrigeratedStoresPage'));
const MedicalInventoryPage = lazy(() => import('../pages/MedicalInventoryPage'));
const UniformsPage = lazy(() => import('../pages/UniformsPage'));
const BeveragesPage = lazy(() => import('../pages/BeveragesPage'));
const EquipmentPage = lazy(() => import('../pages/EquipmentPage'));
const CertificatesPage = lazy(() => import('../pages/CertificatesPage'));
const DWRPage = lazy(() => import('../pages/DWRPage'));
const LogEntryPage = lazy(() => import('../pages/LogEntryPage'));
const LibrariesPage = lazy(() => import('../pages/LibrariesPage'));
const EngineersDayLogPage = lazy(() => import('../pages/EngineersDayLogPage'));
const TasksCurrentPage = lazy(() => import('../pages/TasksCurrentPage'));
const SMSReportsPage = lazy(() => import('../pages/SMSReportsPage'));
const ISPSReportsPage = lazy(() => import('../pages/ISPSReportsPage'));
const SMSReportsDetailPage = lazy(() => import('../pages/SMSReportsDetailPage'));
const HoursOfRestPage = lazy(() => import('../pages/HoursOfRestPage'));
const ISPSReportsDetailPage = lazy(() => import('../pages/ISPSReportsDetailPage'));
const CrewProfilesPage = lazy(() => import('../pages/CrewProfilesPage'));
const CrewGuestsPage = lazy(() => import('../pages/CrewGuestsPage'));
const RunningLogsPage = lazy(() => import('../pages/RunningLogsPage'));
const RunningLogsGroupPage = lazy(() => import('../pages/RunningLogsGroupsPage'));
const PurchaseOrdersPage = lazy(() => import('../pages/PurchaseOrdersPage'));
const ScheduleEquipment = lazy(() => import('../pages/EquipmentSchedules'));
const EventScheduling = lazy(() => import('../pages/EventScheduling'));
const DrillScheduling = lazy(() => import('../pages/DrillScheduling'));
const CriticalSystemReportPage = lazy(() => import('../pages/CriticalSystemReportPage'));
const MaintenanceReportPage = lazy(() => import('../pages/MaintenanceReportPage'));
const ForecastingPage = lazy(() => import('../pages/ForecastingPage'));
const Inspections = lazy(() => import('../pages/InspectionsPage'))
const TasksInspectionsPage = lazy(() => import('../pages/TasksInspectionsPage'))

export const RoutePath = {
  PROFILE: '/profile',
  HOME: '/home',
  TASKS_WORKISSUES: '/tasks/workissues',
  TASKS_INSPECTIONS: '/tasks/inspections',
  TASKS_CURRENT: '/tasks/current',
  TASKS_CERTIFICATES: '/workissuescertificates',
  CERTIFICATES: '/certificates',
  INVENTORIES_HOME: '/inventories/inventory',
  INVENTORIES_BEVERAGES: '/inventories/beverages',
  INVENTORIES_UNIFORMS: '/inventories/uniforms',
  INVENTORIES_STORES: '/inventories/stores',
  INVENTORIES_MEDICAL: '/inventories/medical-inventory',
  LOGS_MAINTENANCE: '/logs/maintenance',
  LOGS_ENGINEERING: '/logs/engineering',
  LOGS_RUNNING: '/logs/running',
  LOGS_ENG_DAY: '/logs/engday-log',
  LOGS_CRITICAL_REPORT:'/logs/criticalsystemreport',
  LOGS_MAINTENANCE_REPORT:'/logs/maintenancereport',
  LOGS_FORECASTING:'/logs/forecasting',
  EQUIPMENT: '/equipment',
  PURCHASING: '/purchasing',
  DWR: '/data/dwr',
  SMS_REPORTS: '/sms-reports',
  ISPS_REPORTS: '/isps-reports',
  LIBRARIES_HOME: '/libraries',
  SETTINGS_YMS_SETTINGS: '/settings/yms-settings',
  SETTINGS_LIST_UTILITY: '/settings/list-utility',
  SETTINGS_CATEGORY_UTILITY: '/settings/category-utility',
  SETTINGS_LOCATIONS_UTILITY: '/settings/locations-utility',
  RUNNING_LOGS_GROUPS_UTILITY: '/settings/running-logs-groups',
  SETTINGS_RECYCLE_BIN: '/settings/recycle-bin',
  // LIBRARIES_DRAWING: '/libraries/drawings',
  // LIBRARIES_SMS_LIBRARY: '/libraries/sms-library',
  // LIBRARIES_PHOTOS: '/libraries/photo-libraries',
  HOURS_OF_REST: '/crew/hor',
  CREW_PROFILES: '/crew/profiles',
  CREW_GUESTS: '/crew/guests',
  TEST_FILES: '/test/files/:filename',
  SCHEDULES_EQUIPMENT: '/schedules/equipment',
  EVENT_SCHEDULING: '/schedules/event',
  DRILL_SCHEDULING: '/schedules/drill',
  REPORT_ISSUES: 'https://app.hatchbuck.com/OnlineForm/33554914616',
  INSPECTIONS: '/schedules/inspections',
};

export const RouteMapModule: any = {
  tasks: 'workissues',
  certificates: 'certificates',
  inventories: 'inventory',
  purchasing: 'purchasing',
  logs: 'logentry',
  equipment: 'equipment',
  crew: 'crew',
};

const withSuspense =
  (Component: ComponentType): any =>
  ({ ...rest }) =>
    (
      <>
        <Suspense
          fallback={
            <div className="flex flex-grow items-center justify-center">
              <CircularProgress />
            </div>
          }
        >
          <Component {...rest} />
        </Suspense>
      </>
    );

  const externalLink = (url:string)=>{
    return;
  }

const routes: { path: string; component: any; exact?: boolean }[] = [
  {
    path: RoutePath.PROFILE,
    component: withSuspense(AccountPage),
  },
  {
    path: RoutePath.HOME,
    component: HomePage,
  },
  {
    path: RoutePath.TASKS_WORKISSUES,
    component: withSuspense(WorkIssuesPage),
  },
  {
    path: RoutePath.SETTINGS_YMS_SETTINGS,
    component: withSuspense(YMSSettingsPage),
  },
  {
    path: RoutePath.SETTINGS_LIST_UTILITY,
    component: withSuspense(ListUtilityPage),
  },
  {
    path: RoutePath.SETTINGS_CATEGORY_UTILITY,
    component: withSuspense(SystemsHierarchyPage),
  },
  {
    path: RoutePath.SETTINGS_LOCATIONS_UTILITY,
    component: withSuspense(LocationsHierarchyPage),
  },
  {
    path: RoutePath.RUNNING_LOGS_GROUPS_UTILITY,
    component: withSuspense(RunningLogsGroupPage),
  },
  {
    path: RoutePath.SETTINGS_RECYCLE_BIN,
    component: withSuspense(RecycleBinPage),
  },
  {
    path: RoutePath.TASKS_CERTIFICATES,
    component: withSuspense(WorkIssuesCertificatesPage),
  },
  {
    path: RoutePath.TASKS_CURRENT,
    component: withSuspense(TasksCurrentPage),
  },
  {
    path: RoutePath.TASKS_INSPECTIONS,
    component: withSuspense(TasksInspectionsPage),
  },
  {
    path: `${RoutePath.CERTIFICATES}/:type`,
    component: withSuspense(CertificatesPage),
  },
  {
    path: RoutePath.INVENTORIES_HOME,
    component: withSuspense(InventoryPage),
  },
  {
    path: RoutePath.INVENTORIES_BEVERAGES,
    component: withSuspense(BeveragesPage),
  },
  {
    path: RoutePath.INVENTORIES_UNIFORMS,
    component: withSuspense(UniformsPage),
  },
  {
    path: RoutePath.INVENTORIES_STORES,
    component: withSuspense(RefrigeratedStoresPage),
  },
  {
    path: RoutePath.INVENTORIES_MEDICAL,
    component: withSuspense(MedicalInventoryPage),
  },
  {
    path: RoutePath.LOGS_ENG_DAY,
    component: withSuspense(EngineersDayLogPage),
    exact: false,
  },
  {
    path: RoutePath.LOGS_MAINTENANCE,
    component: withSuspense(LogEntryPage),
  },
  {
    path: RoutePath.LOGS_CRITICAL_REPORT,
    component: withSuspense(CriticalSystemReportPage),
  },
  {
    path: RoutePath.LOGS_MAINTENANCE_REPORT,
    component: withSuspense(MaintenanceReportPage),
  },
  {
    path: RoutePath.LOGS_FORECASTING,
    component: withSuspense(ForecastingPage),
  },
  {
    path: RoutePath.LOGS_RUNNING,
    component: withSuspense(RunningLogsPage),
  },
  {
    path: RoutePath.EQUIPMENT,
    component: withSuspense(EquipmentPage),
  },
  {
    path: RoutePath.PURCHASING,
    component: withSuspense(PurchaseOrdersPage),
  },
  {
    path: RoutePath.DWR,
    component: withSuspense(DWRPage),
  },
  {
    path: `${RoutePath.LIBRARIES_HOME}/:type`,
    component: withSuspense(LibrariesPage),
  },
  {
    path: `${RoutePath.SMS_REPORTS}/:id`,
    component: withSuspense(SMSReportsDetailPage),
  },
  {
    path: `${RoutePath.ISPS_REPORTS}/:id`,
    component: withSuspense(ISPSReportsDetailPage),
  },
  {
    path: `${RoutePath.ISPS_REPORTS}/:id`,
    component: withSuspense(ISPSReportsDetailPage),
  },
  {
    path: RoutePath.HOURS_OF_REST,
    component: withSuspense(HoursOfRestPage),
  },
  {
    path: RoutePath.CREW_PROFILES,
    component: withSuspense(CrewProfilesPage),
  },
  {
    path: RoutePath.CREW_GUESTS,
    component: withSuspense(CrewGuestsPage),
  },
  {
    path: RoutePath.TEST_FILES,
    component: withSuspense(FileViewerPage),
  },
  {
    path: RoutePath.SCHEDULES_EQUIPMENT,
    component: withSuspense(ScheduleEquipment),
  },
  {
    path: RoutePath.EVENT_SCHEDULING,
    component: withSuspense(EventScheduling),
  },
  {
    path: RoutePath.DRILL_SCHEDULING,
    component: withSuspense(DrillScheduling),
  },
  {
    path: RoutePath.INSPECTIONS,
    component: withSuspense(Inspections),
  },
  {
    path: RoutePath.REPORT_ISSUES,
    component: externalLink(RoutePath.REPORT_ISSUES),
  }
];

export default routes.map((route) => ({
  ...route,
  component: route.component,
}));
