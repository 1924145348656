import React, { FC, useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import Autocomplete from 'src/components/UI/Forms/Autocomplete';
import vDDAllContacts from "../../../rxdb/views/vDDAllContacts";

type CompanyProps = {
  onChange: (field: string, value: any) => void;
  placeholder?: string;
  control: Control;
  name: string;
  label?: string;
  variant?: string;
  size?: string;
};

const CompaniesDropDown: FC<CompanyProps> = ({
  placeholder,
  control,
  label,
  name,
  onChange,
  variant,
  size,
}) => {
  const [contacts, setContacts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleLoadData = async () => {
    try {
      const contacts = await vDDAllContacts();
      const sortedContacts = contacts.sort((a: any, b: any) => a.DisplayMember > b.DisplayMember ? 1 : -1);
      return sortedContacts;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const contacts = await handleLoadData();
        if (isMounted) {
          // Update state only if component is mounted
          setContacts(contacts);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Set to false when component unmounts
    };
  }, []);

  return (
    <Autocomplete
      onChange={onChange}
      loading={loading}
      label={label}
      placeholder={placeholder}
      options={contacts}
      control={control}
      name={name}
      keyExpr=""
      displayExpr="DisplayMember"
      variant={variant}
      size={size}
      freeSolo={true}
    />
  )
};

export default CompaniesDropDown
