import { FC, MutableRefObject, useEffect, useState } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import {
  TypeComputedProps,
  TypeDataGridProps,
  TypeFilterValue,
  TypeGroupBy,
  TypeRowSelection,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';
import '@inovua/reactdatagrid-enterprise/index.css';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import './style.css';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { getDatabase } from 'src/rxdb';
import { MangoQuerySortDirection } from 'rxdb';
import { useAppState } from 'src/contexts/app-state';
import { size } from 'lodash';
import { GRID_LICENSE } from 'src/consts';
import * as Sentry from '@sentry/react';

interface InjectedProps {
  onLoadingChange?: (loading: boolean) => void;
  groupByEnabled?: boolean;
  sourceRoot?: boolean;
}

type StoreProps = {
  key: string;
  sortInfo: TypeSortInfo | undefined;
  filterInfo: TypeFilterValue | undefined;
  columnOrder: string[] | undefined;
  selected: TypeRowSelection | undefined;
  groupBy: TypeGroupBy;
};

let defaultStore: StoreProps = {
  key: '',
  sortInfo: undefined,
  filterInfo: undefined,
  columnOrder: undefined,
  selected: undefined,
  groupBy: undefined,
};

const LicensedReactDataGrid: FC<Partial<TypeDataGridProps> & InjectedProps> = (
  props
) => {
  const { groupByEnabled = true, sourceRoot = false } = props;
  if (!sourceRoot) {
    return (
      <ReactDataGrid
        {...(props as any)}
        style={{ borderColor: '#ededed', ...props.style }}
        className="rounded"
        licenseKey={GRID_LICENSE}
      />
    );
  }
  const history = useHistory();
  useEffect(() => {
    if (props.selected) {
      onSetSelected(props.selected);
    }
  }, [props?.selected]);

  const onSetSelected = (selected: any) => {
    const currentPath = history.location.pathname;
    const currentTableState =
      tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['selected'] = selected;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });
  };

  const { tableState, setTableState } = useAppState();
  const handleColumnOrderChange = (columnOrder: string[]) => {
    const currentPath = history.location.pathname;
    const currentTableState =
      tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['columnOrder'] = columnOrder;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });
  };

  const handleSortInfoChange = (sortInfo: TypeSortInfo) => {
    const currentPath = history.location.pathname;
    const currentTableState =
      tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['sortInfo'] = sortInfo;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });
  };

  const handleFilterValueChange = (filterInfo: any[]) => {
    const currentPath = history.location.pathname;
    const currentTableState =
      tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['filterInfo'] = filterInfo;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });
  };

  const handleSelectionChange = ({ selected }: any) => {
    const currentPath = history.location.pathname;
    const currentTableState =
      tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['selected'] = selected;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });

    if (props.onSelectionChange) props.onSelectionChange({ selected });
  };

  const handleGroupByChange = (groupBy: any) => {
    const currentPath = history.location.pathname;
    const currentTableState =
      tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['groupBy'] = groupBy;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });

    if (props.onGroupByChange) props.onGroupByChange(groupBy);
  };

  const handleReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    const currentPath = history.location.pathname;
    const currentTableState =
      tableState && tableState[currentPath] ? tableState[currentPath] : {};
    currentTableState['filterInfo'] = ref.current?.computedFilterValue;
    currentTableState['groupBy'] = ref.current?.computedGroupBy;

    setTableState({
      ...tableState,
      [currentPath]: currentTableState,
    });

    if (ref && props.onReady) props.onReady(ref);
  };

  const currentPath = history.location.pathname;
  const currentTableState =
    tableState && tableState[currentPath] ? tableState[currentPath] : {};

  return (
    <>
      <ReactDataGrid
        {...(props as any)}
        style={{ borderColor: '#ededed', ...props.style }}
        defaultSortInfo={currentTableState.sortInfo || props.defaultSortInfo}
        columnOrder={currentTableState.columnOrder}
        filterValue={currentTableState.filterInfo}
        selected={currentTableState.selected}
        groupBy={groupByEnabled ? currentTableState.groupBy : null}
        onReady={handleReady}
        onSortInfoChange={handleSortInfoChange}
        onColumnOrderChange={handleColumnOrderChange}
        onFilterValueChange={handleFilterValueChange}
        onSelectionChange={handleSelectionChange}
        onGroupByChange={handleGroupByChange}
        className="rounded"
        licenseKey={GRID_LICENSE}
      />
    </>
  );
};

export default Sentry.withProfiler(LicensedReactDataGrid);
