// Style
import "../../App.css"

// MUI components
import { IconButton, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Check } from '@mui/icons-material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

// React components
import { FC, useEffect, useRef, useState } from 'react';
import { isNil } from 'lodash';
import { v4 as uuid } from 'uuid';

// Data
import { getDatabase } from 'src/rxdb';
import { WorkIssues } from 'src/generated/graphql';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { CertificateDocument } from 'src/rxdb/collections/Certificates/rxdb';
import { LogEntryDocument } from 'src/pages/LogEntryPage/rxdb';

// Utils
import { getRecurrencePerSchedMaint } from 'src/utils';
import { saveWorkIssueCommentsToLogEntry, saveWorkIssueSparesToLogEntry } from 'src/pages/WorkIssuesPage/utils';
import { equipmentHoursUpdate, isNotNil, searchForEquipment, searchForSchedMaintEq, updateNextHourDueForNotLockHours } from 'src/utils';

// TDI components
import CompleteCertificateTaskModal from '../dataentry/taskDE/component/CompleteCertificateTaskModal';
import CompleteExpireEquipmentTaskModal from '../dataentry/taskDE/component/CompleteExpireEquipmentTaskModal';
import RecurringTaskModal from '../dataentry/taskDE/component/RecurringTaskModal';
import CertificateFormDialog from '../CertificateFormDialog';
import LogEntryFormDialog from '../LogEntryFormDialog';
import WarningDialog from '../UI/WarningDialog';

interface Props {
  initialValue: WorkIssuesDocument;
  componentName?: string;
  onLogEntrySaveExtraOperations?: () => void;
  onClose?: () => void;
  onCancel: () => void;
  disableEdit?: boolean;
}

const CompleteTaskButton: FC<Props> = ({ initialValue, componentName, onLogEntrySaveExtraOperations, onClose, onCancel, disableEdit=false }) => {
  const [isCompleteLoading, setCompleteLoading] = useState<boolean>(false);
  const [certificateModalVisible, setCertificateModalVisible] = useState<boolean>(false);
  const [recurrenceModalVisible, setRecurrenceModalVisible] = useState<boolean>(false);
  const [expireEquipmentModalVisible, setExpireEquipmentModalVisible] = useState<boolean>(false);
  const [certificate, setCertificate] = useState<CertificateDocument>();
  const pendingWorkIssueRef = useRef<WorkIssues | null>(null);
  const [logEntry, setLogEntry] = useState<LogEntryDocument>();
  const [selectedIssue, setSelectedIssue] = useState<WorkIssuesDocument>();
  const onCertificateCb = useRef<() => void>();
  const oldSelectedIssue = useRef<WorkIssuesDocument | null>(null);
  const [isCompletionCanceled, setCompletionCanceled] = useState<boolean>(false);
  const [isCertificateCreate, setIsCertificateCreate] = useState<boolean>(false);
  const [warningDialog, setWarningDialog] = useState<boolean>(false)
  const [warningContentElement, setWarningContentElement] = useState<string>('')

  useEffect(() => {
    setSelectedIssue(initialValue)
  }, [])
  const handleCertificateChange = async (
    isCreate: boolean,
    cb?: () => void,
  ) => {
    if (isNil(initialValue)) return;

    onCertificateCb.current = cb;
    setIsCertificateCreate(isCreate)
    const db = await getDatabase();
    if (isCreate) {
      let cert
      const oldCert = await db.certificates.findOne({
        selector:{
          PKey: initialValue.fldSMSID
        }
      }).exec()
      if(oldCert){
        if(oldCert?.fldCertType === 1){
          //Below certificate new document is for issue new passport 
          cert = await db.certificates.newDocument({
            // PKey: v4()
            fldCertType: 1,
            fldType:oldCert?.fldType || 'Passport',
            fldCrewID: oldCert?.fldCrewID,
            fldSRHKey: oldCert?.fldSRHKey,
          });
        } else {
          cert = await db.certificates.newDocument({
            fldType: oldCert?.fldType,
            fldCrewID: oldCert?.fldCrewID,
            fldNationality: oldCert?.fldNationality,
            fldSRHKey: oldCert?.fldSRHKey,
            fldIssue: oldCert?.fldIssue,
            fldCertType: oldCert?.fldCertType,
            // PKey: v4()
          });
        }
      } else {
        cert = await db.certificates.newDocument({
          // PKey: v4()
        });
      }
      // Create initial values
      return setCertificate(cert);
    }

    const certificate = await db.certificates
      .findOne({ selector: { PKey: initialValue.fldSMSID } })
      .exec();

    if (isNil(certificate)) return;

    setCertificate(certificate);
  };

  const onCertificateSave = async (
    certificate: CertificateDocument,
    isCreate: boolean,
  ) => {
    if (initialValue) {
      if (isCreate) {
        // Mark previous certificate if any as archived
        const previousCertificate = await initialValue.populate('fldSMSID');
        await previousCertificate?.atomicPatch({
          fldArchive: true,
        });
      }
      // TODO: Confirm on what should we do here
      // Once certificate created -> update WorkIssue fldSMSID with the new certificate id.
      initialValue.atomicPatch({
        fldSMSID: certificate.PKey,
        fldStatus: 'Completed',
        PercentComplete: 100,
        Completed: true,
        DateCompleted: new Date().toISOString(),
      }).then(() => {
        console.log('atomicPatch 2')
      });
    }
    await saveWorkIssueCommentsToLogEntry(certificate.PKey, initialValue.JobNumber)
    onCertificateCb.current && onCertificateCb.current();
    onCertificateCancel();
    onCancel && onCancel()
  };

  const onCertificateDelete = async () => {
    // Update work issue with null certificate
    initialValue?.atomicPatch({
      fldSMSID: null,
    });
    onCertificateCb.current && onCertificateCb.current();
  };

  const onCertificateCancel = () => {
    setCertificate(undefined);
    setSelectedIssue(undefined);
  };

  const handleLogEntryInfoChange = async (
    isCreate: boolean,
    pendingWorkIssue?: WorkIssues,
  ) => {
    if (isNil(initialValue)) return;

    pendingWorkIssueRef.current = pendingWorkIssue || null;

    const db = await getDatabase();

    await getWorkIssueForLogEntry();

    if (isCreate) {
      const logEntry = await db.logentry.newDocument({
        PKey: uuid(),
        EqKey: initialValue?.EqKey, // When creating a new logentry, pass EqKey for having reference to the related equipment
        fldSMS: initialValue.fldSMS
      });
      setSelectedIssue(initialValue)
      return setLogEntry(logEntry);
    }
  };

  const getWorkIssueForLogEntry = async () => {
    const db = await getDatabase();
    // When task not saved yet but we would like to autofill info on LogEntry with this unsaved info.
    if (pendingWorkIssueRef.current) {
      const document: WorkIssuesDocument = await db.workissues.newDocument({
        ...initialValue?.toJSON(),
        ...pendingWorkIssueRef.current,
      });

      setSelectedIssue(document);
    }
  };

  const onLogEntryCleanUp = () => {
    setLogEntry(undefined);
    setSelectedIssue(oldSelectedIssue.current || undefined);
  };

  const onLogEntryPreSave = async () => {
    const db = await getDatabase();
    // Run validations

    if (isNil(selectedIssue)) throw new Error('WorkIssue not found!');

    // Dont check for any maint in case of AD-Hoc task.
    if (isNil(selectedIssue.fldSchedMaintKey)) return true;

    const schedMaintEq = await db.tblschedmainteq
      .findOne({
        selector: {
          PKey: selectedIssue.fldSchedMaintKey,
        },
      })
      .exec();

    if (isNil(schedMaintEq)) throw new Error('Maintenance equipment schedule (tblSchedMaintEq) not found');

    const schedMaint = await db.tblschedmaint
      .findOne({
        selector: {
          PKey: schedMaintEq.SchedKey,
        },
      })
      .exec();

    if (isNil(schedMaint)) throw new Error('Maintenance schedule (tblSchedMaint) not found');

    return true;
  };

  const onTaskCompletionCancel = () => {
    // Completion was cancelled -> Prompt user with proper message.
    setCompletionCanceled(true);
    pendingWorkIssueRef.current = null;
    // Dont wait on user response - reset values right away.
  };

  const onLogEntryCancel = () => {
    onLogEntryCleanUp();
    onTaskCompletionCancel();
  };

  const onLogEntrySave = async (
    logEntry: LogEntryDocument,
    isCreate: boolean,
  ) => {
    const db = await getDatabase();

    await initialValue?.atomicPatch({
      ...(pendingWorkIssueRef.current || {}),
      fldStatus: 'Completed',
      PercentComplete: 100,
      Completed: true,
      DateCompleted: logEntry.LogDate || new Date().toISOString(),
    });
    console.log("[Workissue] ---- Completed  --- 100%")

    // For inspection task completion, mark all af it's checks completed true
    if(componentName === 'InspectionChecks') {
      // await markWorkissueCompleted();
      onLogEntrySaveExtraOperations?.();
      onClose?.();
    }

    await saveWorkIssueCommentsToLogEntry(logEntry.PKey, selectedIssue?.JobNumber)
    const spares = await db.tblsparesused.find({
      selector: {
        WorkKey: selectedIssue?.JobNumber,
        deletedAt: { $eq: null },
      }
    }).exec()
    if (spares.length > 0) {
      await saveWorkIssueSparesToLogEntry(logEntry.PKey, selectedIssue?.JobNumber)
    }

    // Adding hours update for equipment when equipment monitoring hours
    if (!isNil(selectedIssue?.EqKey)) {
      const equipment = await db.equipment.findOne({
        selector: {
          EqKey: selectedIssue?.EqKey
        }
      }).exec()
      if (equipment && equipment?.fldCountHours) {
        await equipmentHoursUpdate(equipment?.EqKey, logEntry.fldHours);
      }
    }
    // Especially for recurring task - do extra job.
    if (!isNil(selectedIssue?.fldSchedMaintKey)) {
      const maintEq = await db.tblschedmainteq
        .findOne({
          selector: {
            PKey: selectedIssue?.fldSchedMaintKey,
          },
        })
        .exec();
      const schedMaint = await db.tblschedmaint.findOne({
        selector: {
          PKey: maintEq?.SchedKey,
        },
      })
        .exec();

      if (!isNil(maintEq)) {
        // MaintEq shouldn't null since we have preSave check.
        const recurrence = await getRecurrencePerSchedMaint(maintEq, logEntry);
        if (recurrence && !isNil(maintEq?.EqKey) && !isNil(logEntry.fldHours)) {
          await equipmentHoursUpdate(maintEq?.EqKey, logEntry.fldHours);
        }
        await maintEq?.atomicPatch({
          ...recurrence,
        });
        if (schedMaint?.fldHourLock) {
          // TD-1623 - logEntry.fldHours shouldn't be equal to 0; if updating hours when completing task it wouldn't run the code inside if
          if (!isNil(logEntry.fldHours) && logEntry.fldHours >= 0 && !isNil(schedMaint?.fldHourInterval) && !isNil(maintEq?.fldHoursTrigger)) {
            const nextDueHrs = maintEq?.fldHoursTrigger
            await maintEq?.atomicPatch({
              fldHoursTrigger: nextDueHrs,
              fldDateTrigger: null,
            });
          }
        } else {
          const loggedHrs = logEntry?.fldHours || 0;
          await updateNextHourDueForNotLockHours(schedMaint, maintEq, loggedHrs);
        }
      }
    }

    onLogEntryCleanUp();
    onCancel && onCancel() // Cloase task view
  };
  const onLogEntryDelete = async () => { };

  // Recurring handler
  const onRecurringTaskComplete = () => {
    // Prompt user to create log entry
    handleLogEntryInfoChange(true);
  };

  const handleTaskComplete = async (pendingWorkIssue?: WorkIssuesDocument) => {

    if (isNil(initialValue)) return;

    const db = await getDatabase();
    const {
      fldSchedMaintKey, fldSMSType, fldSMSID, fldIsCheckList, EqKey
    } = initialValue;

    // Validate if schedule related to the tasks exists in db (useCase: create a task against a schedule and before completing the task, schedule gets deleted)
    if (fldSchedMaintKey !== null && fldSchedMaintKey !== undefined) {
      const schedMaintEqExists = await searchForSchedMaintEq(fldSchedMaintKey, db);
      if (!schedMaintEqExists) {
        setWarningDialog(true)
        setWarningContentElement('Schedule')
        return;
      }
    }
    // Validate if equipment related to the tasks exists in db (useCase: create a task against an equipment and before completing the task, equipment gets deleted)
    if (EqKey !== null && EqKey !== undefined) {
      const equipmentExists = await searchForEquipment(EqKey, db);
      if (!equipmentExists) {
        setWarningDialog(true)
        setWarningContentElement('Equipment')
        return;
      }
    }
    
    let validEq = null;
    if (!isNil(EqKey) && fldSMSType === 'EQEXP') {
      validEq = await db.equipment.find({
        selector: {
          EqKey: { $eq: EqKey },
          deletedAt: { $eq: null }
        }
      }).exec()
    }

    // Ad Hoc (one-off tasks - created from the "Create New" button)
    const isAdHoc = isNil(fldSchedMaintKey)
      && isNil(fldSMSType)
      && isNil(fldSMSID)
      && !fldIsCheckList;
    const isExpiredCertificate = isNil(fldSchedMaintKey)
      && ['CERT', 'CERTS'].includes(String(fldSMSType))
      && !isNil(fldSMSID)
      && !fldIsCheckList;
    const isExpiredEquipment = isNil(fldSchedMaintKey)
      && fldSMSType === 'EQEXP'
      && !isNil(EqKey)
      && !isNil(validEq)
    const isChecklistSummary = isNil(fldSchedMaintKey)
      && new RegExp('^<CHKLIST', 'i').test(fldSMSType || '')
      && isNil(fldSMSID)
      && !fldIsCheckList;
    const isCheckListItem = isNil(fldSchedMaintKey)
      && fldSMSType === 'CHKCAR'
      && isNotNil(fldSMSID)

    if (isChecklistSummary) {
      alert('We do not support Checklist Summary Tasks yet');
      return;
    }

    // Certificate task
    if (isExpiredCertificate) {
      setCertificateModalVisible(true);
      return;
    }

    // Expired Equipment
    if (isExpiredEquipment) {
      setExpireEquipmentModalVisible(true);
      return;
    }

    // AD-HOC and CheckList item task
    if (isAdHoc || isCheckListItem) {
      handleLogEntryInfoChange(true, pendingWorkIssue);
      return;
    }

    // Recurring
    try {
      setCompleteLoading(true);
      const db = await getDatabase();

      const bin = await db.tblrecyclebin
        .findOne({
          selector: { fldRecordKey: initialValue?.fldSchedMaintKey },
        })
        .exec();

      // Stop loading animation on complete button
      setCompleteLoading(false);

      // If we have it in bin - show Recurrence modal
      if (!isNil(bin)) {
        setRecurrenceModalVisible(true);
        return;
      }

      // Open drawer
      handleLogEntryInfoChange && handleLogEntryInfoChange(true, pendingWorkIssue);
    } catch (e) {
      setCompleteLoading(false);
    }
  };

  const onTaskCompleteModalClose = () => {
    setCertificateModalVisible(false);
  };

  const onRecurringTaskModalCancel = () => {
    setRecurrenceModalVisible(false);
    onTaskCompletionCancel();
  };

  const onRecurringTaskModalSave = () => {
    setRecurrenceModalVisible(false);
    onRecurringTaskComplete();
  };

  const onExpireEquipmentTaskModalClose = () => {
    setExpireEquipmentModalVisible(false);
  };

  const onExpireEquipmentTaskModalSubmit = () => {
    setExpireEquipmentModalVisible(false);
    onCancel && onCancel(); // Task completed. Close drawer
  };

  const handleCertificateInfoChange = (isCreate: boolean) => {
    // Passing callback here in order to open Modal back again and allow user to complete task with new/updated certificate
    handleCertificateChange(isCreate, handleTaskComplete);
  };

  const handleWarningClose = () => {
    setWarningDialog(false);
  }

  const handleCompletionCanceledClose = () => {
    setCompletionCanceled(false);
  }

  return (
    <>
      {componentName === 'CheckTasks' &&
        <Tooltip title="Complete Task">
          <IconButton
            onClick={() => handleTaskComplete()}
            size="small"
            aria-label="Complete task"
            className="mx-2"
            disabled={disableEdit}
          >
            <TaskAltIcon color='primary' />
          </IconButton>
        </Tooltip>
      }
      {componentName === 'WorkedPerformed' &&
        <Tooltip title="Complete Task">
          <IconButton
            onClick={() => handleTaskComplete()}
            size="small"
            aria-label="Complete task"
            className="mx-1"
            disabled={disableEdit}
          >
            <TaskAltIcon color='primary' />
          </IconButton>
        </Tooltip>
      }
      {componentName === 'InspectionChecks' &&
        <LoadingButton
          loading={isCompleteLoading}
          loadingPosition="start"
          startIcon={<Check />}
          onClick={() => handleTaskComplete()}
          aria-label="Complete task"
          sx={{ minWidth: '120px' }}
          variant="contained"
          disabled={disableEdit}
        >
          Complete
        </LoadingButton>
      }
      {!componentName &&
        <LoadingButton
          loading={isCompleteLoading}
          loadingPosition="start"
          startIcon={<Check />}
          onClick={() => handleTaskComplete()}
          color="info"
          aria-label="Complete task"
          size="small"
          disabled={disableEdit}
        >
          Complete Task
        </LoadingButton>
      }

      {certificateModalVisible && <CompleteCertificateTaskModal
        visible={certificateModalVisible}
        issue={initialValue}
        onClose={onTaskCompleteModalClose}
        onCertificateInfoChange={handleCertificateInfoChange}
      />}

      <CompleteExpireEquipmentTaskModal
        visible={expireEquipmentModalVisible}
        issue={initialValue}
        onSubmit={onExpireEquipmentTaskModalSubmit}
        onClose={onExpireEquipmentTaskModalClose}
      />

      <RecurringTaskModal
        visible={recurrenceModalVisible}
        issue={initialValue}
        onSave={onRecurringTaskModalSave}
        onCancel={onRecurringTaskModalCancel}
      />

      <CertificateFormDialog
        visible={!!certificate}
        certificate={certificate}
        onSave={onCertificateSave}
        onDelete={onCertificateDelete}
        onCancel={onCertificateCancel}
        certificateCreate={isCertificateCreate}
        issue={initialValue}
        isPassport={certificate?.fldCertType === 1 ? true : false}
      />

      <LogEntryFormDialog
        visible={!!logEntry}
        issue={initialValue}
        logEntry={logEntry}
        onPreSave={onLogEntryPreSave}
        onSave={onLogEntrySave}
        onDelete={onLogEntryDelete}
        onCancel={onLogEntryCancel}
      />

      <WarningDialog 
        visible={isCompletionCanceled} 
        title='Completion cancelled' 
        content='Completion operation has been cancelled!'
        cancelText='OK'
        onCancel={handleCompletionCanceledClose}      
      />

      <WarningDialog
        visible={warningDialog}
        title="Invalid Data Warning"
        content={`Invalid ${warningContentElement} associated with this task, please contact <a id="contactLink" href="mailto:support@yms360.com">support@yms360.com</a>.`}
        color="warning"
        cancelText='Close'
        onCancel={handleWarningClose}
      />
    </>
  );
};

export default CompleteTaskButton;
