import React, { FC, useEffect, useState } from "react";
import { Control, UseFormWatch } from "react-hook-form";
import { isNil } from "lodash";

// MUI Components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

// TDI Componenents
import Checkbox from "src/components/UI/Forms/Checkbox";
import Input from "src/components/UI/Forms/Input";
import { InventoryDocument } from "src/pages/InventoryPage/rxdb";
import RichTextEditor from "../../../UI/Forms/RichTextEditor";
import Autocomplete from "../../../UI/Forms/Autocomplete";
import CategoryDropdown from "../../../Dropdowns/CategoryDropdown";
import InvStorage from "src/components/dataentry/inventoryForm"

// Utilities
import { toJSON } from "../../../../utils";

// Data
import { getDatabase } from "../../../../rxdb";
import { TblDdListDefaultsDocument } from "../../../../api/queries/tblDDListDefaults/rxdb";
import { Inventory } from "../../../../generated/graphql";
import { useAppState } from 'src/contexts/app-state';
interface MedicalInventorySummaryFormProps {
  form: Inventory;
  initialValue: InventoryDocument;
  onChange: (field: string, value: any) => void;
  control: Control;
  watch: UseFormWatch<any>;
  getValues: any;
  onStorageUpdate: (isUpdate: boolean) => void;
  isCreated? : boolean;
  selectedItemOrderID?: string;
  onSave: (invItem: Inventory, isCreated: boolean) => void;
}

const MedicalInventorySummaryForm: FC<MedicalInventorySummaryFormProps> = ({
  form,
  onChange,
  initialValue,
  control,
  watch,
  getValues,
  onStorageUpdate,
  isCreated= false,
  selectedItemOrderID = "",
  onSave,
}) => {
  const { settingsPersonal } = useAppState();
  const [usageMeasure, setUsageMeasure] = useState<TblDdListDefaultsDocument[]>([]);
  const [orderMeasure, setOrderMeasure] = useState<TblDdListDefaultsDocument[]>([]);

  const init = async () => {
    const db = await getDatabase();

    db.tblddlistdefaults.find({ selector: { deletedBy: { $eq: null } }}).$.subscribe((defaults: any) => {
      const usageMeasure = defaults.filter((d: any) => d.fldListName === "Qty" && d.fldMember !== "");
      const orderMeasure = defaults.filter((d: any) => d.fldListName === "Qty" && d.fldMember !== "");
      setUsageMeasure(toJSON(usageMeasure));
      setOrderMeasure(toJSON(orderMeasure));
    });
  };

  useEffect(() => {
    if (isNil(initialValue)) return;

    init();
  }, [initialValue]);

  // ??Shouldn't this be it's own component?  It just gets used over and over
  const renderListDefaultBox = (
    items: TblDdListDefaultsDocument[],
    fieldName: string,
    fldListName: string,
    freeSolo = false,
    customListName: any = null
  ) => (
    <Autocomplete
      defaultPayload={{
        fldListName,
        fldT4Reqd: true,
      }}
      displayExpr="fldMember"
      keyExpr="PKey"
      onChange={onChange}
      freeSolo={freeSolo}
      options={items}
      label={customListName || fldListName}
      control={control}
      name={fieldName}
      collection="tblddlistdefaults"
    />
  );

  const onStorageUpdated = (isUpdated: boolean) => {
    if (isUpdated) {
      onStorageUpdate(isUpdated)
    }
  }

  return (
    <div className="mt-3">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="general-information"
          id="general-information"
        >
          General Information
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-col">
            {/* Category Hierarchial, collapsable Tree */}
            <div className="mt-3">
              <CategoryDropdown
                name="fldSRHKey"
                label="Category"
                defaultValue={initialValue.fldSRHKey}
                control={control}
                onChange={onChange}
                rules={{
                  required: true,
                }}
                sourceForm="medical"
                allDepts={settingsPersonal?.fldAllDepts > 0}
              />
            </div>

            <div className="mt-3">
              <FormGroup row>
                <Checkbox
                  control={control}
                  name="Bonded"
                  label="Bonded"
                />
              </FormGroup>
            </div>
          </div>

          {/* TODO add fldHTML */}
          <div className="my-4">
            <RichTextEditor
              control={control}
              name="ProductDescription"
              onChange={onChange}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="Storage Locations"
          id="storageLocations"
        >
          Storage Locations
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <InvStorage
              form={form}
              onChange={onChange}
              initialValue={initialValue}
              control={control}
              watch={watch}
              getValues={getValues()}
              onSave={onStorageUpdated}
              isCreated = {isCreated}
              selectedItemOrderID={selectedItemOrderID}
              onSaveInv={onSave}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className="mt-3">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="Ordering Details"
          id="orderingDetails"
        >
          Ordering Details
        </AccordionSummary>
        <AccordionDetails>
          <div className="pt-4">
            <div className="flex flex-wrap">
              <div className="flex-grow p-2">
                <Input
                  name="ReOrderLevel"
                  inputProps={{
                    label: "Min. Holding Level",
                    type: "number",
                    inputProps: { min: 0 },
                  }}
                  control={control}
                  rules={{
                    min: 0,
                  }}
                />
              </div>
              <div className="flex-grow p-2">
                <Input
                  name="ReOrderAmt"
                  inputProps={{
                    label: "Max. Holding Level",
                    type: "number",
                    inputProps: { min: 0 },
                  }}
                  control={control}
                  rules={{
                    min: 0,
                  }}
                />
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="flex-grow p-2">
                {renderListDefaultBox(usageMeasure, "Qty", 'Qty', true, "Usage Measure")}
              </div>
              <div className="flex-grow p-2">
                {renderListDefaultBox(
                  orderMeasure,
                  "fldOrderQty",
                  'Qty',
                  true,
                  "Order Measure"
                )}
              </div>
            </div>
            <div className="p-2 w-1/2">
              <Input
                name="fldFactor"
                inputProps={{
                  label: "Measure Factor",
                  type: "number",
                  inputProps: {
                    inputMode: "decimal",
                    pattern: "([0-9]+)?[,\\.]?[0-9]*",
                    step: 0.25,
                  },
                }}
                control={control}
                // TODO:revisit validation after edit works
                // defaultValue = {getValues().Qty === getValues().fldOrderQty ? 1.0 : initialValue.fldOrderQty}
                rules={{
                  min: 0,
                  // validate:()=>{
                  //   if(getValues().Qty === getValues().fldOrderQty && getValues().fldFactor != 1.0){
                  //     return "Usage Measure and Order Measure are same so default value will be 1.0"
                  //   }
                  // }
                }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MedicalInventorySummaryForm;
