import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Grid, Stack, Alert, Snackbar } from '@mui/material';

import Input from 'src/components/UI/Forms/Input';
import PageHeader from 'src/components/UI/PageHeader';
import { useAuth } from 'src/contexts/auth';
import { getDatabase } from 'src/rxdb';
import { CrewDocument } from 'src/rxdb/collections/Crew/schema';
import useSnackbar from 'src/hooks/useSnackbar';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { size } from 'lodash';
import WarningDialog from 'src/components/UI/WarningDialog';
import { logger } from '../../helpers/logger';

const CHANGE_PASSWORD = gql`
  mutation ($input: ChangeCrewPasswordInput!) {
    changeCrewPassword(input: $input)
  }
`;

const Profile = () => {
  const { user, signOut } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [crewProfile, setCrewProfile] = useState<CrewDocument | null>(null);
  const [changePassword] = useMutation(CHANGE_PASSWORD, { ignoreResults: true });
  const [isCompleteLoading, setIsCompleteLoading] = useState<boolean>(false);
  const [isUserNameUpdate, setIsUserNameUpdate] = useState<boolean>(false);
  const [newCrewName, setNewCrewName] = useState<any>(null)

  const { control, getValues, handleSubmit, reset } = useForm<any>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    }
  });

  const { control: userControl, handleSubmit: handleSubmitUser, reset :userNameReset } = useForm<any>({
    defaultValues: {
      CrewUsername: user?.CrewUsername,
    }
  });
 
  const handleUpdatePassword = useCallback(async (values: any) => {
    const { newPassword, confirmNewPassword, currentPassword } = values;
    try{
      if (!navigator.onLine) {      
        throw new Error('Appears you are being offline!')
      }

      await changePassword({
        variables: {
          input: {
            newPassword,
            confirmNewPassword,
            currentPassword
          }
        }
      });

      reset();

      showSnackbar({
        type: 'success',
        message: 'Password has been updated!',
      })

    } catch(e: unknown) {
      showSnackbar({
        type: 'error',
        message: (e as any).message,
      })
    }
  }, [crewProfile]);

  const handleUserNameChangeOk =  async () =>{
    const db = await getDatabase()

    setIsCompleteLoading(true)

    const document = {
      ...crewProfile?.toJSON(),
      CrewUsername: newCrewName
    } as any

    try{
      // await crewProfile?.atomicPatch({ CrewUsername: newCrewName });
      await db.collections.crew.upsert(document);
      showSnackbar({
        type: 'success',
        message: 'User Name has been updated!',
      })
      setNewCrewName(null);
      signOut && signOut();
    } catch (e){
      showSnackbar({
        type: 'error',
        message: 'try again',
      })
    }
  }

  const handleUserNameChangeCancel = () =>{
    userNameReset()
    setIsUserNameUpdate(false)
  }

  const handleUpdateUserName = useCallback(async (value: any) => {
    try{
      const db = await getDatabase();
      const { CrewUsername } = value;
      if(CrewUsername === crewProfile?.CrewUsername ) {
        showSnackbar({
          type: 'error',
          message: 'Cannot save same username!',
        })
        return;
      }
    
      const matchingCrew = await db.crew.find({selector:{
        deletedBy:{$eq:null}
      }}).exec();
      const duplicateFound = matchingCrew.find(
        (item:any) =>
          item.CrewUsername && item.CrewUsername.toLowerCase() === CrewUsername.toLowerCase()
      );
      if(duplicateFound && size(duplicateFound)> 0) {
        showSnackbar({
          type: 'error',
          message: 'Duplicate username. Please try another username!',
        })
        return;
      }
      setNewCrewName(CrewUsername)
      setIsUserNameUpdate(true)

      // await crewProfile?.atomicPatch({ CrewUsername: CrewUsername });
      
      // showSnackbar({
      //   type: 'success',
      //   message: 'User Name has been updated!',
      // })
  
      } catch {
  
        showSnackbar({
          type: 'error',
          message: 'User Name update failed!',
        })
      }
  }, [crewProfile]);

  useEffect(() => {
    const initializeCrewProfile = async () => {
      if (!!user?.fldCrewID) {
        const db = await getDatabase();
        const profiles = await db.collections.crew.find({ selector: { fldCrewID: user.fldCrewID } }).exec();
        if (profiles.length) {
          setCrewProfile(profiles[0]);
          logger('Account Page')
          .info(`user profile set - CrewProfile: { ${new Date().toISOString()} - fldCrewID: ${profiles[0].fldCrewID}, CrewUsername: ${profiles[0].CrewUsername} }`,);
        }
      }
    }

    initializeCrewProfile()
  }, [user])

  return (
    <>
      <PageHeader title="Profile" />

      <Grid container>
        <Grid item xs={6}>
          <form key={2} onSubmit={handleSubmitUser(handleUpdateUserName)}>
            <Stack sx={{ py: 6 }} spacing={4}>
              <Input
                control={userControl}
                name="CrewUsername"
                inputProps={{
                  label: 'Change Username',
                  variant: 'outlined',
                }}

              />

              <Button variant="contained" color="primary" type="submit">
                Update UserName
              </Button>
            </Stack>
            
          </form>

          <form key={1} onSubmit={handleSubmit(handleUpdatePassword)}>
            <Stack sx={{ py: 6 }} spacing={4}>
              <Input
                control={control}
                name="currentPassword"
                inputProps={{
                  label: 'Current Password',
                  variant: 'outlined',
                  type: 'password'
                }}
                rules={{ required: 'Please, provide current password!' }}
              />

              <Input
                control={control}
                name="newPassword"
                inputProps={{
                  label: 'New Password',
                  variant: 'outlined',
                  type: 'password'
                }}
                rules={{
                  required: 'Password is required!',
                  min: 8,
                  max: 100,
                }}
              />

              <Input
                control={control}
                name="confirmNewPassword"
                inputProps={{
                  label: 'Confirm Password',
                  variant: 'outlined',
                  type: 'password'
                }}
                rules={{
                  validate: (value) => {
                    const { newPassword } = getValues();

                    if (!value) return 'Please confirm your new password!'

                    if (newPassword !== value) {
                      return 'Password does not match'
                    }
                  }
                }}
              />

              <Button variant="contained" color="primary" type="submit">
                Update Password
              </Button>
            </Stack>
          </form>
        </Grid>
      </Grid>
      <WarningDialog
        visible={isUserNameUpdate}
        loading={isCompleteLoading}
        title="Username change warning"
        content="Changing your username will require you to log back in"
        okText='Logout'
        color='error'
        onOk={handleUserNameChangeOk}
        onCancel={handleUserNameChangeCancel}
      />
    </>
  );
};

export default Profile;
