import React, { FC, useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { getDatabase } from '../../../rxdb';
import { toJSON } from '../../../utils';
import Autocomplete from '../../UI/Forms/Autocomplete';
import { CurrenciesDocument } from '../../../api/queries/Currencies/rxdb';

type CurrenciesProps = {
  onChange: (field: string, value: any) => void;
  placeholder?: string;
  control: Control;
  label?: string;
  name?: string;
};

const CurrenciesDropdown: FC<CurrenciesProps> = ({
  placeholder,
  control,
  label,
  onChange,
  name,
}) => {
  const [currencies, setCurrency] = useState<CurrenciesDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const init = async () => {
    const db = await getDatabase();

    const currencies = await db.currencies.find().exec();

    setCurrency(toJSON(currencies));
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
  ) => {
    const hasAbbreviation = !!option.Abbreviation;
    return (
      <li {...props} key={props.id}>
        {option.Abbreviation}
        {' '}
        {`${hasAbbreviation ? '(' : ''} ${option.Curr} ${
          hasAbbreviation ? ')' : ''
        }`}
      </li>
    );
  };

  return (
    <Autocomplete
      renderOption={renderOption}
      freeSolo
      onChange={onChange}
      loading={loading}
      label={label}
      placeholder={placeholder}
      options={currencies}
      control={control}
      name={name || 'Currency'}
      keyExpr=""
      displayExpr="Curr"
    />
  );
};

export default CurrenciesDropdown;
