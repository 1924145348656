// Styles
import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';

// React components
import { FC, useEffect, useRef, useState } from 'react';
import { isNil, size, last, omit, isEmpty, isArray } from 'lodash';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

// MUI components
import { Undo, DeleteTwoTone, VisibilityOff, Close } from '@mui/icons-material';
import { Alert, Backdrop, Box, IconButton, Snackbar, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

// Data
import { getDatabase } from '../../../rxdb';
import { Subscription } from 'rxjs';
import { TblDocumentationDocument } from 'src/pages/LibrariesPage/rxdb';
import { TblDocumentation } from '../../../generated/graphql';

// TDI components
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import StickyAppBar from '../../UI/StickyAppBar';
import WarningDialog from 'src/components/UI/WarningDialog';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import DocSummaryForm from './component/DocSummaryForm';
import Comments from '../../../modules/Comments';
import RelatedTab from './component/RelatedTab';
import { InjectedDrawerProps } from 'src/components/PageDrawer';

// Utils
import { CHAR_LIMIT, DOCUMENT_LIBRARY_TYPES, FileExtensionType } from '../../../consts';
import { handleCharLimitWarning } from 'src/utils';
import { validateForm } from './utils';

const useStyles = makeStyles((theme) => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
    maxWidth: 900,
  },
}));

interface Props extends Partial<InjectedDrawerProps> {
  initialValue: TblDocumentationDocument;
  onCancel: () => void;
  onSave: (item: TblDocumentation, isCreated: boolean) => void;
  onDelete: (item: TblDocumentationDocument) => void;
  onShowInCentral: (item: TblDocumentationDocument) => void;
  onUndo?: () => void;
  libraryType: any;
  isCreated?: boolean;
  editFlag?: boolean;
}

const DocDetailForm: FC<Props> = ({
  initialValue,
  onCancel,
  onSave,
  onDelete,
  onShowInCentral,
  onUndo,
  libraryType,
  isCreated=false,
  editFlag = false,
  setFormIsDirty,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      DocTitle: initialValue.DocTitle || '',
      fldDescription: initialValue.fldDescription || '',
      fldDocPage: initialValue.fldDocPage || null,
      fldDocFigure: initialValue.fldDocFigure || null,
      fldAddedBy: initialValue.fldAddedBy || null,
      fldTOC: initialValue.fldTOC || null,
      fldNumber: initialValue.fldNumber || null,
      fldSpecRef: initialValue.fldSpecRef || null,
      fldPassword: initialValue.fldPassword || null,
      fldLibtype: initialValue.fldLibtype || null,
      fldFileType: initialValue.fldFileType || null,
      fldStatus: initialValue.fldStatus || null,
      fldStatusInternal: initialValue.fldStatusInternal || null,
      fldSRHKey: initialValue.fldSRHKey,
      fldSMSReport: initialValue.fldSMSReport || false,
      Received: initialValue.Received || false,
      fldShowInCentral: initialValue.fldShowInCentral || true,
      fldCompleteReqd: initialValue.fldCompleteReqd || false,
      fldVerifiedReq: initialValue.fldVerifiedReq || false,
    },
  });
  const classes = useStyles();
  // const oldDocState = useRef<TblDocumentation>(initialValue.toJSON());
  const [oldDocUndo, setOldDocUndo] = useState<TblDocumentation[]>([]);
  const [item, setItem] = useState<TblDocumentation>(initialValue.toJSON());
  const [commentsCount, setCommentsCount] = useState<number>(0);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const formInitialValues = useRef<any>({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [showInCentral, setShowInCentral] = useState(false);
  const [open, setOpen] = useState(false);
  const { TBLDOCUMENTATION } = CHAR_LIMIT;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const getDocumentCount = async () => {
    const db = await getDatabase();
    // Find and count comments
    activeSubscriptions.current = [
      db.comments
        .find({
          selector: {
            $and: [
              {referenceIssueId: isNil(initialValue.PKey) ? '' : initialValue.PKey},
              {deletedAt: {$eq: null}},
            ],
          },
        })
        .$.subscribe((c) => {
          setCommentsCount(size(c));
        }),
    ];
  };

  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  useEffect(() => {
    getDocumentCount();
    setInitialValues();

    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    setValue(name, value, { shouldDirty: true });
  };

  const handleCancel = () => {
    setOldDocUndo([]);
    onCancel();
  };

  const handleSave = async (data: any) => {
    if (!validateForm(data, setSnackbar)) return;
    const db = await getDatabase();

    // Create items before creating Item.
    const getOrCreate = async (value: any, keyExpr: string) => {
      if (isNil(value)) return null;

      if (value.isCreate) {
        // Create item first and then proceed
        const collection = (db as any)[value.collection];

        // TODO: Hook up tblDefaults
        const result = await collection?.upsert(
          omit(value, ['inputValue', 'isCreate', 'collection']),
        );

        return result[keyExpr];
        // create value collection
      }

      return value[keyExpr];
    };

    const {
      DocTitle,
      fldDescription,
      fldDocPage,
      fldDocFigure,
      fldAddedBy,
      fldTOC,
      fldNumber,
      fldSpecRef,
      fldPassword,
      fldFileType,
      fldStatus,
      fldStatusInternal,
      fldSRHKey,
      fldSMSReport,
      Received,
      fldShowInCentral,
      fldCompleteReqd,
      fldVerifiedReq,
    } = data;

    const document = {
      ...item,
      DocTitle,
      fldDescription,
      fldDocPage,
      fldDocFigure,
      fldAddedBy,
      fldTOC,
      fldNumber,
      fldSpecRef,
      fldPassword,
      fldLibtype: libraryType, // pass library type
      fldFileType,
      fldStatus,
      fldStatusInternal,
      fldSRHKey: (isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey) || null,
      fldSMSReport,
      Received,
      fldShowInCentral,
      fldCompleteReqd,
      fldVerifiedReq,
      // Since we are passing empty object from parent we should distinguish create/update actions.
      // In case of Update we have to pass primary key (EqKey)
      PKey: initialValue.primary || uuid(), // Set primary key, so we will be able to upsert.
      updatedAt: new Date().toISOString(),
    } as any;

    try {
      setOldDocUndo([]);

      const res = await db.collections.tbldocumentation.upsert(document);

      onSave(res, isCreated);

      reset(getValues());
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };

  const handleDelete = () => {
    setIsDeleting(true)
  };

  const handleShowInCentral = async () => {
    setShowInCentral(true);
  };

  const handleDeleteOk = () =>{
    onDelete(initialValue);
    setIsDeleting(false);
  };

  const handleShowInCentralOk = async () => {
    onShowInCentral(initialValue);
    setShowInCentral(false);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
  }

  const handleShowInCentralCancel = async () => {
    setShowInCentral(false);
  };

  const handleUndo = () => {
    const item = last(oldDocUndo);
    setItem(item as TblDocumentation);
    // Remove last step from our store
    setOldDocUndo(oldDocUndo.splice(-1));

    onUndo && onUndo();
  };

  const handleCancelUndo = () => {
    if (isCreated) {
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar)) return;
    if (isEditing) return; // We will send submit action that will be handled in HandleSave.

    handleCancel();
  };

  if (isNil(item)) return null;

  const hasValuesBeenChanged = formState.isDirty
    && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreated;

  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);

  const actions = [
    {
      icon: (
        < VisibilityOff style={{ color: '#002041' }} />
      ),
      name: 'Hide from Doc Libraries',
      onclick: handleShowInCentral,
    },
  ];

  return (
    <form
      className="relative bg-white pt-14 md:pt-19 flex-grow"
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          <div className="mb-6">
            {(editFlag) && (
              <RecordEditWarningCard />
            )}
            <div className="mui-textfield-header mb-2">
              <Input
                inputProps={{
                  size: 'medium',
                  label: 'Document Title',
                  variant: 'standard',
                }}
                rules={{ required: true, maxLength: TBLDOCUMENTATION.DocTitle }}
                warning={(value) => handleCharLimitWarning(value, TBLDOCUMENTATION.DocTitle)}
                control={control}
                name="DocTitle"
              />
            </div>

            <div className="mt-3">
              <Input
                inputProps={{
                  size: 'small',
                  label: 'Number',
                  variant: 'standard',
                }}
                rules={{ maxLength: TBLDOCUMENTATION.fldNumber }}
                warning={(value) => handleCharLimitWarning(value, TBLDOCUMENTATION.fldNumber)}
                control={control}
                name="fldNumber"
              />
            </div>
          </div>

          <div className="mt-3 mb-20">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <DocSummaryForm
                      watch={watch}
                      control={control}
                      initialValue={initialValue}
                      form={item}
                      onChange={onChange}
                      getValues={getValues}
                      libraryType={libraryType}
                      disabled={editFlag || isCreated} // hide ADD button based on isCreation, for creating a revision against newly created entry, force the user to first create the entry.
                    />
                  ),
                },
                // Force the user to first create the record and after additional data against the record.
                ...(isCreated
                  ? []
                  : [
                      {
                        label: 'Related Items',
                        component: (
                          <RelatedTab
                            keyValue={initialValue.PKey}
                            keyName={initialValue.DocTitle}
                          />
                        ),
                      },
                      {
                        label: `Comments (${commentsCount})`,
                        component: (
                          <Comments
                            selectorType="tblDocumentation"
                            selectorKeyValue={initialValue.PKey}
                            disableEdit={editFlag}
                          />
                        ),
                      },
                    ]),
              ]}
            />
          </div>
        </div>
      </div>

      <StickyAppBar
        cancelText="Cancel"
        okType={isEditing ? 'submit' : 'button'}
        okText={isEditing ? 'Save' : 'Close'}
        onOk={() => handleOk(isEditing)}
        onCancel={isEditing ? () => handleCancelUndo() : undefined}
        disabled={editFlag && isEditing}
      >
        {!isCreated && !editFlag && !isNil(item.PKey) && libraryType !== DOCUMENT_LIBRARY_TYPES.SMSDOCS.TYPE && libraryType !== DOCUMENT_LIBRARY_TYPES.IPSDOCS.TYPE && (
          <Box sx={{ position: 'relative', height: 70 }}>
            <Backdrop open={open} />
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              sx={{ position: 'absolute', bottom: 12, right: 12 }}
              FabProps={{ size: 'small' }}
              icon={(
                <SpeedDialIcon
                  sx={{ fontSize: 'small' }}
                  icon={<SpeedDialIcon />}
                  openIcon={<Close />}
                />
              )}
              onClose={handleClose}
              onOpen={handleOpen}
              open={open}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen
                  classes={classes}
                  onClick={action.onclick}
                />
              ))}
            </SpeedDial>
          </Box>
        )}

        {!isEmpty(oldDocUndo) && (
          <IconButton
            onClick={handleUndo}
            color="inherit"
            aria-label="Undo last step"
          >
            <Undo />
          </IconButton>
        )}
        
        {!editFlag && !isNil(item.PKey) && (
          <IconButton
            onClick={handleDelete}
            color="error"
            aria-label="Delete item"
          >
            <DeleteTwoTone />
          </IconButton>
        )}
      </StickyAppBar>

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <WarningDialog
        visible={showInCentral}
        title="Hide from Doc Libraries Warning"
        content="Are you sure you wish to hide record from Doc Libraries?"
        okText='Yes'
        color='info'
        onOk={handleShowInCentralOk}
        onCancel={handleShowInCentralCancel}
      />

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default DocDetailForm;
