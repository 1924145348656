import React, {
  useState,
  FC,
  useRef,
  useCallback,
  MutableRefObject,
} from 'react';
import { isEmpty, pick, size } from 'lodash';
import { getDatabase, TDIDb } from 'src/rxdb';
import {
  TypeComputedProps,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';

import LicensedReactDataGrid from 'src/components/UI/LicensedReactDataGrid';
import './style.css';

interface Props {
  keyValue?: string | null;
  onSelect: (item: any) => void;
}

const filter = [
  {
    name: 'ProductName',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'Manufacturer',
    operator: 'contains',
    type: 'string',
    value: '',
  },
  {
    name: 'fldPartNumber',
    operator: 'contains',
    type: 'select',
    value: '',
  },
];

const transformData = async (item: any) => ({
  ...pick(item, ['EqKey', 'ProductID']),
  ProductName: (await item.populate('ProductID'))?.ProductName,
  Manufacturer: (await item.populate('ProductID'))?.Manufacturer,
  fldPartNumber: (await item.populate('ProductID'))?.fldPartNumber,
  original: item,
});

const getRegexByOperator = (operator: string, value: string) => {
  switch (operator) {
    case 'contains':
      return new RegExp(`.*${value}`, 'i');
    case 'startsWith':
      return new RegExp(`^${value}`, 'i');
    case 'endsWith':
      return new RegExp(`${value}$`, 'i');
    case 'eq':
      return new RegExp(`${value}`, 'i');
    default:
  }
};

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb,
) => {
  switch (name) {
    case 'ProductName':
      const productname = await db.inventory
        .find({
          selector: {
            ProductName: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        ProductID: { $in: productname.map((e) => e.primary) },
      };

    case 'Manufacturer':
      const manufacturers = await db.inventory
        .find({
          selector: {
            Manufacturer: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        ProductID: { $in: manufacturers.map((e) => e.primary) },
      };

    case 'fldPartNumber':
      const fldPartNumbers = await db.inventory
        .find({
          selector: {
            fldPartNumber: { $regex: getRegexByOperator(operator, value) },
          },
        })
        .exec();
      return {
        ProductID: { $in: fldPartNumbers.map((e) => e.primary) },
      };

    default:
      return {};
  }
};

const FilterSpareGrid: FC<Props> = ({ keyValue, onSelect }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>('-1');
  const [gridRef, setGridRef] = useState<any>(null);

  const loadData = async ({
    skip,
    limit,
    filterValue,
  }: {
    sortInfo?: TypeSortInfo;
    skip?: number;
    limit?: number;
    filterValue?: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();

    let selector = {
      EqKey: keyValue,
    };

    await Promise.all(
      filterValue.map(async (v: any) => {
        if (isEmpty(v.value)) return v;

        const s = await getSelectorByFilterName(
          v.name,
          v.value,
          v.operator,
          db
        );

        selector = {
          ...selector,
          ...s,
        };
        return v;
      })
    );

    const items = await db.inveq
      .find({
        selector,
        skip,
        limit,
      })
      .exec();

    const length = size(items);

    const data = await Promise.all(items.map(transformData));

    return { data, count: length };
  };

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();

    db.inveq.$.subscribe(async (ev) => {
      if (ev.operation === 'INSERT' || ev.operation === 'UPDATE') {
        highlightedRowId.current = ev.documentId;
        ref.current?.reload();
      }
      if (ev.operation==='DELETE') {
        highlightedRowId.current = '-1';
        ref.current?.reload();
      }
    });
  };

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
    init(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== '-1') {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = '-1';
    }
    setLoading(status);
  };

  const onRowClick = useCallback(async ({ data }) => {
    const db = await getDatabase();
    const item = await db.inventory
      .findOne({
        selector: {
          ProductID: data.ProductID,
        },
      })
      .exec();
    onSelect(item);
  }, []);

  const dataSource = useCallback(loadData, []);

  const columns = [
    {
      name: 'ProductName',
      header: 'Spares',
      flex: 1,
    },
    {
      name: 'Manufacturer',
      header: 'Manufacturer',
      flex: 1,
    },
    {
      name: 'fldPartNumber',
      header: 'Part Number',
      flex: 1,
    },
  ];

  return (
    <>
      <div data-testid="data-grid" className="flex flex-col flex-grow spare-inventory-grid">
        <LicensedReactDataGrid
          onRowClick={onRowClick}
          onLoadingChange={onLoadingChange}
          defaultLimit={100}
          livePagination
          onReady={onReady}
          rowHeight={40}
          loading={loading}
          defaultFilterValue={filter}
          idProperty="EqKey"
          // theme="default-dark"
          columns={columns}
          dataSource={dataSource}
        />
      </div>
    </>
  );
};

export default FilterSpareGrid;
