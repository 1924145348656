// This group of imports should be in every data detail component
import "../../../App.css";
import "../../../theme/styles.css";
import "../styles.css";
import { v4 as uuid } from "uuid";
import React, { FC, useEffect, useRef, useState } from "react";
import { isNil, size, last, omit, isString, isArray, isEmpty } from "lodash";
// Third party components go here
// check here for @mui/icon-materials values https://mui.com/components/material-icons/
import { DeleteTwoTone } from "@mui/icons-material";
import { Icon, Portal, Alert, IconButton, Snackbar } from "@mui/material";

import { useForm } from "react-hook-form";
import CompaniesDropdown from "src/components/Dropdowns/CompaniesDropdown";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import CloseIcon from "@mui/icons-material/Close";
import { Subscription } from "rxjs";
import { InventoryDocument } from "src/pages/InventoryPage/rxdb";
import { RxDocument } from "rxdb";
import {
  EquipmentDocMethods,
  EquipmentDocType,
} from "src/pages/EquipmentPage/rxdb";
import { InvBarCodesDocument } from "src/rxdb/collections/InvBarCodes/schema";
import Tabs from "../../UI/Tabs";
import Input from "../../UI/Forms/Input";
import StickyAppBar from "../../UI/StickyAppBar";
import WarningDialog from 'src/components/UI/WarningDialog';
import { filterNonNullStrings, removeNonAlphanumericChars } from 'src/utils';

// images
import IconBarCode from "../../../assets/icon-bc-primary.svg";
import IconAddBarCode from "../../../assets/icon-bc-add.svg";
import IconRemoveBarCode from "../../../assets/icon-bc-remove.svg";
import IconPrintBarCode from "../../../assets/icon-bc-print.svg";

// Reusable module components go here
import { getDatabase } from "../../../rxdb";
import Comments from "../../../modules/Comments";
import AttachmentTab from "../../../modules/Attachments";
import { FileExtensionType } from "../../../consts";

// All module specific components go here
import { Inventory } from "../../../generated/graphql";
import BeveragesSummaryForm from "./component/BeveragesSummaryForm";
import { validateForm } from "./utils";
import BeveragesOrderHistory from "./component/BeveragesOrderHistory";
import BarcodePopup from 'src/components/UI/PDF/reports/BarcodePrintButton/BarcodePopup';
import { InjectedDrawerProps } from 'src/components/PageDrawer';
import BarCode, { BarcodeActionType } from "../inventoryDE/component/BarCodes/BarCode";

interface Props extends Partial<InjectedDrawerProps> {
  initialValue: InventoryDocument;
  onCancel: () => void;
  onSave: (invItem: Inventory, isCreated: boolean) => void;
  onDelete: (invitem: InventoryDocument) => void;
  onUndo?: () => void;
  editFlag: boolean;
  type?: string;
  isCreated: boolean;
  storageUpdate: () => void;
  selectedItemOrderID?: string;
}

const BeveragesDetailForm: FC<Props> = ({
  initialValue,
  onCancel,
  onSave,
  onDelete,
  onUndo,
  editFlag = false,
  isCreated,
  type,
  storageUpdate,
  selectedItemOrderID = "",
  setFormIsDirty
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      fldSRHKey: initialValue.fldSRHKey,
      ProductName: initialValue.ProductName || "",
      Manufacturer: initialValue.Manufacturer,
      ModelNumber: initialValue.ModelNumber,
      fldPartNumber: initialValue.fldPartNumber,
      Department: initialValue?.Department || 'Interior',
      fldSMS: initialValue.fldSMS || false,
      fldConsumable: initialValue.fldConsumable || false,
      Bonded: initialValue.Bonded || false,
      ProductDescription: initialValue.ProductDescription || "",
      ReOrderLevel: initialValue.ReOrderLevel,
      Qty: initialValue.Qty || null,
      fldOrderQty: initialValue.fldOrderQty || null,
      fldFactor: initialValue.fldFactor || null,
      ReOrderAmt: initialValue.ReOrderAmt,
      fldDataType: initialValue.fldDataType,
      ProductID: initialValue.ProductID,
    },
  });

  // const oldInvState = useRef<Inventory>(initialValue.toJSON());

  const [snackBar, setSnackbar] = useState({
    open: false,
    type: "success",
    message: "",
  });

  const [oldInvUndo, setoldInvUndo] = useState<Inventory[]>([]);
  const [invItem, setInvItem] = useState<Inventory>(initialValue.toJSON ? initialValue.toJSON() : initialValue);
  const [attachmentCount, setAttachmentCount] = useState<number>(0);
  const [photoCount, setPhotoCount] = useState<number>(0);
  const [spareCount, setSpareCount] = useState<number>(0);
  const [commentsCount, setCommentsCount] = useState<number>(0);
  const [orderHistoryCount, setOrderHistoryCount] = useState<number>(0);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const formInitialValues = useRef<any>({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [addPopupVisible, setAddPopupVisible] = useState<boolean>(false);
  const [removePopupVisible, setRemovePopupVisible] = useState<boolean>(false);
  const [printPopupVisible, setPrintPopupVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: "",
      type: "success",
    });
  };

  const getDocumentCount = async () => {
    // Count Attachments / Photos / Tasks / Schedules / Spares
    const db = await getDatabase();

    // Find and count attachments
    activeSubscriptions.current = [
      db.tbldocumentcrossreference
        .find({
          selector: {
            fldRecordKey: isNil(initialValue.ProductID)
              ? ""
              : initialValue.ProductID,
          },
        })
        .$.subscribe(async (attachments: any) => {
          const results = await Promise.all<string | undefined>(
            attachments.map(async (attachment: any) => {
              const revision = await db.documentrevision
                .findOne({
                  selector: {
                    fldFKey: attachment.fldFKey,
                  },
                })
                .exec();

              if (revision) {
                return revision.fldFileName
                  ? last(revision.fldFileName.split("."))
                  : undefined;
              }

              return undefined;
            })
          );

          setPhotoCount(
            size(
              results
                .filter((e) => e)
                .filter((extension) =>
                  [
                    FileExtensionType.JPEG,
                    FileExtensionType.JPG,
                    FileExtensionType.PNG,
                    FileExtensionType.TIF,
                    FileExtensionType.BMP,
                  ].includes(extension!.toUpperCase() as any)
                )
            )
          );

          setAttachmentCount(
            size(
              results
                .filter((e) => e)
                .filter((extension) =>
                  [
                    FileExtensionType.PDF,
                    FileExtensionType.XLS,
                    FileExtensionType.XLSX,
                    FileExtensionType.DOC,
                    FileExtensionType.DOCX,
                    FileExtensionType.RTF,
                  ].includes(extension!.toUpperCase() as any)
                )
            )
          );
        }),

      db.comments
        .find({
          selector: {
            $and: [
              {referenceIssueId: isNil(initialValue.ProductID) ? "" : initialValue.ProductID},
              {deletedAt: {$eq: null}},
            ],
          },
        })
        .$.subscribe((c: any) => {
          setCommentsCount(size(c));
        }),

      db.inveq
        .find({
          selector: {
            ProductID: initialValue.ProductID,
          },
        })
        .$.subscribe(async (equipmentItems: any) => {
          const results = await Promise.all<RxDocument<
            EquipmentDocType,
            EquipmentDocMethods
          > | null>(
            equipmentItems.map(async (eqItem: any) => {
              const revision = await db.equipment
                .findOne({
                  selector: {
                    EqKey: eqItem.EqKey,
                  },
                })
                .exec();
              return revision;
            })
          );
          setSpareCount(size(results));
        }),

      db.orderitems
        .find({
          selector: {
            ProductID: initialValue.ProductID,
          },
        })
        .$.subscribe((c: any) => {
          setOrderHistoryCount(size(c));
        }),
    ];
  };
  const setInitialValues = async () => {
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  useEffect(() => {
    getDocumentCount();
    setInitialValues();

    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;

    if (name === 'fldSRHKey') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue.fldSRHKey === updatedValue) {
        shouldDirty = false;
      }
    }

    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const handleCancel = () => {
    setoldInvUndo([]);
    onCancel();
  };

  const isCreation = isCreated;

  const handleSave = async (data: any) => {
    if (!validateForm(data, setSnackbar)) return;
    const db = await getDatabase();

    // Create items before creating Item.
    const getOrCreate = async (value: any, keyExpr: string) => {
      if (isNil(value)) return null;

      if (value.isCreate) {
        // Create item first and then proceed
        const collection = (db as any)[value.collection];

        // TODO: Hook up tblDefaults
        const result = await collection?.upsert(
          omit(value, ["inputValue", "isCreate", "collection"])
        );

        return result[keyExpr];
        // create value collection
      }

      return value[keyExpr];
    };

    const {
      ProductName,
      fldSRHKey,
      Manufacturer,
      ModelNumber,
      fldPartNumber,
      Department,
      fldSMS,
      fldConsumable,
      Bonded,
      ProductDescription,
      ReOrderLevel,
      Qty,
      fldOrderQty,
      fldFactor,
      ReOrderAmt,
    } = data;

    const QtyValue = isString(Qty)
      ? Qty
      : Qty?.fldMember || null;

    const fldOrderQtyValue = isString(fldOrderQty)
      ? fldOrderQty
      : fldOrderQty?.fldMember || null

    const document = {
      ...invItem,
      ProductName,
      fldSRHKey: (isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey) || null,
      Manufacturer:
        typeof Manufacturer === 'object' ? Manufacturer?.DisplayMember : Manufacturer || null,
      ModelNumber,
      fldPartNumber: isEmpty(fldPartNumber)
        ? null
        : fldPartNumber.toUpperCase(),
      Department: isString(Department)
        ? Department
        : Department?.fldMember || null,
      ProductDescription,
      fldSMS,
      fldConsumable,
      Bonded,
      ReOrderLevel: parseInt(ReOrderLevel) || null,
      Qty: QtyValue,
      fldOrderQty: fldOrderQtyValue,
      fldFactor:
        (QtyValue === fldOrderQtyValue
          ? 1.0
          : parseFloat(fldFactor)) || 1.0,
      ReOrderAmt: parseInt(ReOrderAmt) || null,
      SearchMaker:isNil(Manufacturer) ? null: typeof Manufacturer === 'object' ? removeNonAlphanumericChars(Manufacturer?.DisplayMember) : removeNonAlphanumericChars(Manufacturer) || null,
      SearchMod: isNil(ModelNumber)? null: removeNonAlphanumericChars(ModelNumber),
      SearchPart: isNil(fldPartNumber) ? null: removeNonAlphanumericChars(fldPartNumber) || null,
      // Since we are passing empty object from parent we should distinguish create/update actions.
      // In case of Update we have to pass primary key (ProductID)
      ProductID: initialValue.primary || uuid(), // Set primary key, so we will be able to upsert.
      updatedAt: new Date().toISOString(),
    } as any;

    try {
      setoldInvUndo([]);
      const res = await db.collections.inventory.upsert(document);
      onSave(res, isCreated);
      reset(getValues());
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: "error",
        message: e.message,
      });
    }
  };
  const handleDelete = () => {
    // onDelete(initialValue);
    setIsDeleting(true)
  };

  const handleDeleteOk = () => {
    onDelete(initialValue);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  };

  // Is this the correct way to handle calling modals from the form?
  const handleAddTask = () => [];

  const handleAddLog = () => [];

  const handleUndo = () => {
    const item = last(oldInvUndo);
    setInvItem(item as Inventory);
    // Remove last step from our store
    setoldInvUndo(oldInvUndo.splice(-1));

    onUndo && onUndo();
  };

  const handleStorageUpdate = (isUpdated: boolean) => {
    if (isUpdated) {
      storageUpdate();
    }
  }

  const handleCancelUndo = () => {
    if (isCreation) {
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar)) return;
    if (isEditing) return; // We will send submit action that will be handled in HandleSave.

    handleCancel();
  };

  const handleAddBarCodePress = () => {
    setAddPopupVisible(true);
  };

  const handleDeleteBarCodePress = () => {
    setRemovePopupVisible(true);
  };

  const handlePrintPress = () => {
    setPrintPopupVisible(true);
  };

  const handlePopupCancel = () => {
    setAddPopupVisible(false);
    setRemovePopupVisible(false);
    setPrintPopupVisible(false);
    handleClose();
  };

  const handleBarCodeSave = async (data: any) => {
    const db = await getDatabase();

    const { fldTritonBC, fldDefault } = data;

    const document = {
      fldTritonBC: fldTritonBC.toUpperCase(),
      fldDefault: false,
      ProductID: initialValue.ProductID,
      id: `${initialValue.ProductID}--${fldTritonBC}`,
    } as any;

    try {
      await db.invbarcodes.insert(document);
      setSnackbar({
        open: true,
        type: "success",
        message: "Bar Code added success!",
      });

      setAddPopupVisible(false);
      reset();
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: "error",
        message: "Invalid bar code!",
      });
    }
  };

  const handleBarCodeDelete = async (item: InvBarCodesDocument) => {
    try {
      await item.remove();
      setSnackbar({
        open: true,
        type: "success",
        message: "Bar Code removed success!",
      });
      setRemovePopupVisible(false);
      reset();
    } catch (e: any) {
      console.log(e.message);
      setSnackbar({
        open: true,
        type: "error",
        message: e.message,
      });
    }
  };

  const handleBarCodeRemove = async (data: any) => {
    const db = await getDatabase();
    const { fldTritonBC } = data;

    const item = await db.invbarcodes
      .findOne({
        selector: {
          ProductID: initialValue.ProductID,
          fldTritonBC: fldTritonBC.toUpperCase(),
        },
      })
      .exec();

    if (item) {
      handleBarCodeDelete(item);
    } else {
      console.log('Error: Barcode not found!')
    }
  };

  const actions = [
    {
      icon: (
        <Icon>
          <img src={IconPrintBarCode} alt="" />
        </Icon>
      ),
      name: "Print",
      onclick: handlePrintPress,
    },
    {
      icon: (
        <Icon>
          <img src={IconAddBarCode} alt="" />
        </Icon>
      ),
      name: "Add",
      onclick: handleAddBarCodePress,
    },
    {
      icon: (
        <Icon>
          <img src={IconRemoveBarCode} alt="" />
        </Icon>
      ),
      name: "Remove",
      onclick: handleDeleteBarCodePress,
    },
  ];
  // Save

  // Delete - should just place the primarykey in tblRecycle then row should
  // be remove from our list

  if (isNil(invItem)) return null;

  const hasValuesBeenChanged =
    formState.isDirty &&
    (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreation;
  
  useEffect(() => {
    setFormIsDirty && setFormIsDirty(hasValuesBeenChanged);
  }, [hasValuesBeenChanged]);

  const relatedKeys = filterNonNullStrings([initialValue.ProductID]);

  const formClass =
    type === "Dialog"
      ? "relative bg-white flex-grow"
      : "relative bg-white pt-14: md:pt-19 flex-grow";

  return (
    <form
      id="Inventory-Edit-form"
      className={`${formClass}`}
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          <div className="mb-6">
            <div className="mui-textfield-header mb-2">
              <Input
                inputProps={{
                  size: "medium",
                  label: "Product Name",
                  variant: "standard",
                }}
                rules={{ required: true }}
                control={control}
                name="ProductName"
              />
            </div>

            <div className="mt-3">
              <CompaniesDropdown
                control={control}
                label="Maker/Bottler"
                name="Manufacturer"
                onChange={onChange}
                variant="standard"
                size="small"
              />
            </div>

            <div className="mt-3">
              <Input
                inputProps={{
                  size: "small",
                  label: "Varietal",
                  variant: "standard",
                  autoComplete: 'off',
                }}
                defaultValue={initialValue.fldPartNumber}
                control={control}
                name="fldPartNumber"
              />
            </div>
          </div>

          <div className="mt-3 mb-20">
            <Tabs
              tabs={[
                {
                  label: "Summary",
                  component: (
                    <BeveragesSummaryForm
                      watch={watch}
                      control={control}
                      initialValue={initialValue}
                      form={invItem}
                      onChange={onChange}
                      getValues={getValues}
                      onStorageUpdate={handleStorageUpdate}
                      isCreated = {isCreated}
                      selectedItemOrderID={selectedItemOrderID}
                      onSave={onSave}
                    />
                  ),
                },
                {
                  label: `Attachments (${attachmentCount})`,
                  component: (
                    <AttachmentTab
                      type="attachments"
                      primaryKey={initialValue.ProductID}
                      relatedKeys={relatedKeys}
                      SRHKey={initialValue.fldSRHKey}
                      allowedFileExtensions={[".pdf"]}
                      tableName="Inventory"
                    />
                  ),
                },

                {
                  label: `Photos (${photoCount})`,
                  component: (
                    <AttachmentTab
                      type="photo"
                      primaryKey={initialValue.ProductID}
                      relatedKeys={relatedKeys}
                      SRHKey={initialValue.fldSRHKey}
                      allowedFileExtensions={[".jpg", ".jpeg", ".gif", ".png"]}
                      tableName="Inventory"
                    />
                  ),
                },
                {
                  label: `Order History (${orderHistoryCount})`,
                  component: (
                    <BeveragesOrderHistory keyValue={initialValue.ProductID} />
                  ),
                },
                {
                  label: `Comments (${commentsCount})`,
                  component: (
                    <Comments
                      selectorType="Inventory"
                      selectorKeyValue={initialValue.ProductID}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
      {type !== "Dialog" && (
        <StickyAppBar
          cancelText="Cancel"
          okType={isEditing ? "submit" : "button"}
          okText={isEditing ? "Save" : "Close"}
          onOk={() => handleOk(isEditing)}
          onCancel={isEditing ? () => handleCancelUndo() : undefined}
          disabled={editFlag && isEditing}
          sx={{	
            position: "sticky",	
            bottom: 0,	
          }}
        >
          {!editFlag && !isNil(invItem.ProductID) && (
            <Box sx={{ position: "relative", height: 70 }}>
              <Backdrop open={open} />
              <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: "absolute", bottom: 12, right: 12 }}
                FabProps={{ size: "small" }}
                icon={
                  <SpeedDialIcon
                    sx={{ fontSize: "small" }}
                    icon={
                      <Icon>
                        <img src={IconBarCode} alt="" />
                      </Icon>
                    }
                    openIcon={<CloseIcon />}
                  />
                }
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={action.onclick}
                  />
                ))}
              </SpeedDial>
            </Box>
          )}

          {!editFlag && !isNil(invItem.ProductID) && (
            <IconButton
              onClick={handleDelete}
              color="error"
              aria-label="Delete item"
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </StickyAppBar>
      )}

      <BarcodePopup
        initialValue={initialValue}
        onCancel={handlePopupCancel}
        visible={printPopupVisible}
        saveLoading={false}
        componentName='Beverages'
      />

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />

      <BarCode
        visible={addPopupVisible}
        onCancel={handlePopupCancel}
        onSave={handleBarCodeSave}
        inventory={initialValue}
        type={BarcodeActionType.Add}
      />

      <BarCode
        visible={removePopupVisible}
        onCancel={handlePopupCancel}
        onSave={handleBarCodeRemove}
        inventory={initialValue}
        type={BarcodeActionType.Remove}
      />

      <Portal>
        <Snackbar
          open={snackBar.open}
          autoHideDuration={2000}
          onClose={onSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={snackBar.type as any} sx={{ width: "100%" }}>
            {snackBar.message}
          </Alert>
        </Snackbar>
      </Portal>
    </form>
  );
};

export default BeveragesDetailForm;
