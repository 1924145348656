import React, { FC, useState, useEffect } from 'react';
import {
  isNil, isArray, get, last, isString,
} from 'lodash';
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  IconButton,
} from '@mui/material';
import { CertificateDocument } from 'src/rxdb/collections/Certificates/rxdb';
import { DeleteTwoTone } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { getDatabase } from '../../rxdb';
import CertificateDetailsForm from '../dataentry/certificatesDE/CertificateDetailsForm';
import WarningDialog from 'src/components/UI/WarningDialog';
import useSnackbar from "../../hooks/useSnackbar";
import { useAppState } from 'src/contexts/app-state';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { normalizeDateTime } from 'src/helpers';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { useAuth } from 'src/contexts/auth';
import { getPlainTextFromHtml } from 'src/utils';

interface InjectedProps {
  visible: boolean;
  onSave?: (certificate: CertificateDocument, isCreate: boolean) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  certificate?: CertificateDocument;
  isPassport?: boolean;
  certificateCreate?: boolean;
  issue?: WorkIssuesDocument;
  certificateType?: string;
}

const CertificateFormDialog: FC<InjectedProps> = ({
  certificate,
  visible,
  onCancel,
  onSave,
  onDelete,
  isPassport = false,
  certificateCreate = false,
  issue,
  certificateType,
}) => {
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [isDeleting, setIsDeleting] = useState(false);
  const isCreation = isNil(certificate?.fldSRHKey);
  const { settingsPersonal } = useAppState();
  const [disableDisplayCerts, setDisableDisplayCerts] = useState(false);
  const [disableEditCerts, setDisableEditCerts] = useState(false);
  let certificateTypeInfo = certificate?.fldCrewID ? 'CREWPROFILE': 'LIBRARY'
  const setPermission = async ()=>{
    if(settingsPersonal.fldCerts === 1){
      setDisableEditCerts(true)
    }
    if(settingsPersonal.fldCerts === 0 || isNil(settingsPersonal.fldCerts)){
      setDisableDisplayCerts(true)
    }
  }

  useEffect(()=>{
    if(certificate){
      certificateTypeInfo = certificate?.fldCrewID ? 'CREWPROFILE': 'LIBRARY'
    }
  },[certificate])

  useEffect(()=>{
    if(settingsPersonal){
      setPermission()
    }
  },[settingsPersonal])

  const onSubmit = async (data: any) => {
    const db = await getDatabase();

    const { DateExpire, DateIssue } = data;

    if (isNil(certificate)) {
      throw new Error('Certificate missing');
    }

    const fldSRHKey = get(data, 'fldSRHKey', null);

    try {
      const document = {
        ...certificate?.toJSON(),
        DateExpire: normalizeDateTime(DateExpire),
        DateIssue: normalizeDateTime(DateIssue),
        fldCertNum: get(data, 'fldCertNum', null),
        fldCrewID: isString(data.fldCrewID)
        ? data.fldCrewID
        : data.fldCrewID?.fldCrewID || null,
        fldIssue: get(data, 'fldIssue', ''),
        fldNationality: isString(data.fldNationality)
        ? data.fldNationality
        : data.fldNationality?.fldMember || null,
        fldNotes: get(data, 'fldNotes', ''),
        fldCertType: get(data, 'fldPassport', 0) ? 1 : 0, // TODO: Missing in certificates schema
        fldResponsible: get(data, ['fldResponsible', 'fldMember'], null),
        fldSMS: get(data, 'fldSMS', false),
        fldType: get(data, 'fldType', ''),
        fldTimeWarn: parseInt(get(data, 'fldTimeWarn', null), 10) || null,
        fldTimeWarnMeasure: get(data, 'fldTimeWarnMeasure.measureValue', null),
        fldSRHKey: (isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey) || null,
        // fldArchive: isNil(certificate.PKey), // Found in old code following `type === new` So seems like mark archive if new certificate
        PKey: certificate?.PKey || uuid(),
        updatedAt: new Date().toISOString(),
      } as any;
     
      if(issue){
        const logentry = {
          EqKey: issue?.EqKey|| null,
          MaintKey: issue?.fldSchedMaintKey|| null,
          DayLogType: 2,
          Curr: issue?.fldActualCurrency|| null,
          fldCrewID: user?.fldCrewID || null,
          fldNoView: false,
          fldLocHierarchy: issue?.fldLocHierarchy || null,
          fldSRHKey: issue?.fldSRHKey || null,
          fldCompany: issue?.Company || null,
          fldWorkList: issue?.fldWorkList || null,
          Department: user?.Department || null,
          fldTimeQty: null,
          LogDate: normalizeDateTime (new Date()),
          fldCost: issue?.fldActualCost || 0 ,
          fldEnteredBy: user?.fldCrewID || null,
          fldHTML: `${issue?.Subject}${
            issue?.Notes ? `<br/><br/>${issue?.Notes}` : ''
          }`,
          LogEntry: getPlainTextFromHtml(`${issue?.Subject}${
            issue?.Notes ? `<br/><br/>${issue?.Notes}` : ''
          }`),
          fldHours: 0,
          fldIsWarranty:  issue?.fldIsWarranty || null,
          fldPerformedBy: `${user?.fldFirst} ${user?.fldLast}` || null,
          fldRestricted: false,
          fldSMS: issue.fldSMS || false,
          DateEntered: normalizeDateTime (new Date()),
          JobNumber: issue?.JobNumber || null,
          fldTime:  issue?.fldActualWork? parseInt(issue.fldActualWork):0 || 0,
          PKey: uuid(),
        }
        await db.logentry.upsert(logentry)
       
      }
      const created = await db.certificates.upsert(document);

      onSave && onSave(created, isNil(certificate?.PKey));

      showSnackbar({
        type: 'success',
        message: 'Success!',
      });
    } catch (e: any) {
      showSnackbar({
        type: 'error',
        message: e.message,
      });
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
  };

  const handleDeleteOk = async () => {
    try {
      await certificate?.remove();
      onCancel && onCancel();
      onDelete && onDelete();

      showSnackbar({
        type: 'success',
        message: 'Success!',
      });
    } catch (e: any) {
      showSnackbar({
        type: 'error',
        message: 'An error has occurred!',
      });
    }
    setIsDeleting(false);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
  };

  if(disableDisplayCerts) {
    return (
      <Dialog scroll="paper" fullWidth maxWidth="md" open={visible}>
        <DialogTitle>
          <span className="font-bold text-2xl">{isPassport? " Passport Details" : " Certificate Details"}</span>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          {disableDisplayCerts && (
            <div className='mb-4'>
              <RecordEditWarningCard message='You are not authorized to view this record'/>
            </div>
          )}
          </DialogContent>
      </Dialog>
    )
  }
  return (
    <>
      <Dialog scroll="paper" fullWidth maxWidth="md" open={visible}>
        <DialogTitle>
          <span className="font-bold text-2xl">{isPassport? "Add passport" : (isCreation ? "Issue new certificate": "Certificate Details")}</span>
        </DialogTitle>
        <DialogContent sx={{ p: 0, height: '1000px' }}>
          {disableEditCerts && (
            <div className='mb-4'>
              <RecordEditWarningCard message='You are not authorized to change this record'/>
            </div>
          )}
          {!!certificate && (
            <CertificateDetailsForm
              onSubmit={onSubmit}
              certificate={certificate}
              isPassport={isPassport}
              certificateType= {certificateTypeInfo}
              issue = {issue}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          {!isCreation && (
            <IconButton
              onClick={handleDelete}
              color="error"
              aria-label="Delete task"
              disabled={disableEditCerts}
            >
              <DeleteTwoTone />
            </IconButton>
          )}
          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              type="submit"
              form="certificate-details-form"
              variant="contained"
              disabled={disableEditCerts}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};

export default CertificateFormDialog;
