import { Button } from "@material-ui/core";
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@mui/material";
import {
  CellReferenceMode,
  Workbook,
  WorkbookLoadOptions,
  WorkbookSaveOptions,
  WorksheetImage,
} from "igniteui-react-excel";
import { IgrSpreadsheet, SpreadsheetCell } from "igniteui-react-spreadsheet";
import { first, isNil, last, split } from "lodash";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { UPLOAD_URL } from "src/consts";
import { useAuth } from "src/contexts/auth";
import { AnyReportDocument } from "src/rxdb/collections/AnyReports/schema";
import { getJWT } from "src/storage";
import { formatDateISOshort } from "src/utils/format-dates";
import { v4 as uuid } from "uuid";
import { getDatabase } from "../../rxdb";
import SignatureCanvas from "react-signature-canvas";
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import ZoomOut from '@mui/icons-material/ZoomOut';
import ZoomIn from '@mui/icons-material/ZoomIn';

interface FileViewerRef {
  save: () => void;
}

interface FileViewerExcelProps {
  url: string;
  report: AnyReportDocument;
}

const FileViewerExcel = forwardRef<FileViewerRef, FileViewerExcelProps>(
  ({ url, report }: FileViewerExcelProps, ref) => {
    const spreadsheet = useRef<IgrSpreadsheet | null>(null);
    const signature = useRef<SignatureCanvas>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadError, setLoadError] = useState("");
    const [isNewSelection, setIsNewSelection] = useState(false);
    const [zoomValue, setZoomValue] = useState<any>(100);
    const { user } = useAuth();

    console.log('excel url:', url)
    console.log('excel report:', report)
    
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleConfirm = () => {
      setOpen(false);
      insertSignature();
    };
    const handleClose = () => {
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({
      save: async () => {
        const s = spreadsheet.current?.activeWorksheet;
        if (s) {
          const date = s?.getCell("REPORTDATE").getText() || null;
          const isoDate = isNil(date) ? null: formatDateISOshort(date);
          const { filename } = await handleSave();

          if (!filename) return;

          report.atomicPatch({
            fldReportFile: filename,
            fldDateCreated: isoDate,
          });

          const db = await getDatabase();

          await db.tblanyreportshistory.upsert({
            PKey: uuid(),
            FKey: report.PKey,
            fldReportFile: filename,
            fldLastAccessedBy: user!.fldCrewID, // Accessed by user that did this update.
            fldLastAccessedByName: `${user!.fldFirst} ${user!.fldLast}`,
            fldDateRevision: new Date().toISOString(),
          });
        }
      },
    }));

    useEffect(() => {
      const load = async () => {
        const file = await fetch(url);
        const buffer = await file.arrayBuffer();
        Workbook.load(
          new Uint8Array(buffer),
          new WorkbookLoadOptions(),
          (w) => {
            setIsLoaded(true);
            if (!spreadsheet?.current) return;
            // TODO: This is a hack to get the spreadsheet to hide items - doesn't work as component props.
            // https://codesandbox.io/s/github/IgniteUI/igniteui-react-examples/tree/master/samples/excel/spreadsheet/overview?fontsize=14&hidenavigation=1&theme=dark&view=preview&file=/src/index.tsx
            setTimeout(() => {
              if(spreadsheet.current) {
                spreadsheet.current.areHeadersVisible = false;
                spreadsheet.current.isFormulaBarVisible= false;
                spreadsheet.current.areGridlinesVisible= false;
              }
            });
            spreadsheet.current.workbook = w;

            // business requirements dictate that we need to add some special interactivity
            // to particular ranges in the eng day log worksheet. This is using active cell changed, which
            // is the closest analog to an 'onclick' I could find in the docs
            // so this interaction isn't quite right, but should suffice until a better method is identified
            // we have added a global onclick event, and we track selected state to prevent duplicate toggles from occuring
            spreadsheet.current.activeCellChanged = (s, e) => {
              // this is a new selection, since the active changed. This will cause the onclick eventto be skipped
              // we have to do it this way, since we do not have a browser event object to stop propagation
              setIsNewSelection(true);

              toggleCheckmarks(s, e.oldValue, e.newValue);
            };
          },
          (e) => {
            setIsLoaded(true);
            setLoadError(e.message);
          }
        );
      };
      load();
    }, [url, spreadsheet]);

    // this will handle the window onclick event
    const handleClick = useCallback(() => {
      const s = spreadsheet?.current;
      if (!s) return;
      const selectedCell = s.activeCell;
      if (!selectedCell) return;

      // if it is new, we can skip, since the activecellchanged event will handle this case
      if (isNewSelection) {
        setIsNewSelection(false); // the selection is no longer new after it has fired once
        return;
      }
      // subsequent onclick that make it here should be repeat clicks of the current selection
      // which is the functionality we need to toggle the current selection as intended
      // this is all fallout from not having a generic onclick/onpress event to hook into from the spreadsheet library
      toggleCheckmarks(s, null, selectedCell);
    }, [spreadsheet, isNewSelection]);

    // this listener is a workaround for the spreadsheet library not exposing an onclick handler
    useEffect(() => {
      window.addEventListener("click", handleClick);
      return () => {
        window.removeEventListener("click", handleClick);
      };
    }, [handleClick]);

    const handleSave = useCallback(async () => {
      if (!spreadsheet?.current) {
        return { filename: "" };
      }
      const s = spreadsheet.current?.activeWorksheet;

      // value returns an excel datevalue number for date, and I don't see a conversion utility anywhere
      // rather than use some hocus pocus from stackoverflow, lets just use the text string that is displayed
      const date = s?.getCell("REPORTDATE").getText() || null;
      const isoDate = isNil(date) ? formatDateISOshort(new Date().toISOString()): formatDateISOshort(date);

      try {
        const res = await saveToUrl(spreadsheet.current.workbook, {
          url,
          date: isoDate,
        });
        return res;
      } catch (e: any) {
        setLoadError(e);
        return { filename: "" };
      }
    }, [history, spreadsheet]);

    const insertSignature = () => {
      if (!spreadsheet?.current) {
        return;
      }

      const d = signature.current?.toDataURL();
      // const d = 'data:image/gif;base64,R0lGODlhEAAQAMQAAORHHOVSKudfOulrSOp3WOyDZu6QdvCchPGolfO0o/XBs/fNwfjZ0frl3/zy7////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAkAABAALAAAAAAQABAAAAVVICSOZGlCQAosJ6mu7fiyZeKqNKToQGDsM8hBADgUXoGAiqhSvp5QAnQKGIgUhwFUYLCVDFCrKUE1lBavAViFIDlTImbKC5Gm2hB0SlBCBMQiB0UjIQA7';

      const wi = new WorksheetImage(d);
      const s = spreadsheet.current;
      const c1 = s.activeWorksheet.getCell(
        `R${s.activeCell.row + 1}C${s.activeCell.column + 1}`,
        CellReferenceMode.R1C1
      );
      const c2 = s.activeWorksheet.getCell(
        `R${s.activeCell.row + 2}C${s.activeCell.column + 2}`,
        CellReferenceMode.R1C1
      );
      wi.topLeftCornerCell = c1;
      wi.bottomRightCornerCell = c2;

      // need to find the shape at the current location to clear it out
      const shapes = s.activeWorksheet.shapes();
      for (let i = 0; i < shapes.count; i++) {
        const shape = shapes.item(i);
        console.log(
          "checking: ",
          shape.bottomRightCornerCell.columnIndex,
          shape.topLeftCornerCell.rowIndex,
          wi.bottomRightCornerCell.columnIndex,
          wi.topLeftCornerCell.rowIndex
        );
        if (
          shape.topLeftCornerCell.equals(c1) &&
          shape.bottomRightCornerCell.equals(c2)
        ) {
          console.log(
            "removed: ",
            shape.bottomRightCornerCell.columnIndex,
            shape.topLeftCornerCell.rowIndex
          );
          shapes.remove(shape);
          // break;
        }
      }

      shapes.add(wi);
      signature.current?.clear();
    };

    const handleClearSignature = () => {
      if (!spreadsheet?.current) {
        return;
      }

      const s = spreadsheet.current;
      const c1 = s.activeWorksheet.getCell(
        `R${s.activeCell.row + 1}C${s.activeCell.column + 1}`,
        CellReferenceMode.R1C1
      );
      const c2 = s.activeWorksheet.getCell(
        `R${s.activeCell.row + 2}C${s.activeCell.column + 2}`,
        CellReferenceMode.R1C1
      );

      // need to find the shape at the current location to clear it out
      const shapes = s.activeWorksheet.shapes();
      for (let i = 0; i < shapes.count; i++) {
        const shape = shapes.item(i);
        if (
          shape.topLeftCornerCell.equals(c1) &&
          shape.bottomRightCornerCell.equals(c2)
        ) {
          shapes.removeAt(i);
          break;
        }
      }
    };

    return (
      <div className="w-full h-full" data-testid="file-viewer-excel">
        {!isLoaded ? (
          <CircularProgress />
        ) : loadError ? (
          <Alert severity="error">{loadError}</Alert>
        ) : (
          <>
            {/* <Button onClick={handleClickOpen}>Add Signature</Button> */}
            {/* <Button onClick={handleClearSignature}>Clear Signature</Button> */}
            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1 }}
              alignItems="center"
              className="w-full items-center justify-center"
            >
              <IconButton
                onClick={() => {
                  if (zoomValue >= 55) setZoomValue(zoomValue - 5);
                }}
                aria-label="Zoom out"
                disabled={!isLoaded}
              >
                <ZoomOut />
              </IconButton>
              <Slider
                aria-label="Volume"
                min={50}
                max={200}
                step={5}
                value={zoomValue}
                className="w-32"
                disabled={!isLoaded}
                onChange={(
                  event: Event,
                  value: number | number[],
                  activeThumb: number
                ) => {
                  console.log('zoom', value);
                  setZoomValue(value);
                }}
              />
              <IconButton
                onClick={() => {
                  if (zoomValue <= 195) setZoomValue(zoomValue + 5);
                }}
                aria-label="Zoom in"
                disabled={!isLoaded}
              >
                <ZoomIn />
              </IconButton>              
            </Stack>
            <div
              className="items-center justify-center w-full flex"
              style={{
                height: 'calc(100% - 5rem)',
              }}
            >
              <IgrSpreadsheet ref={spreadsheet} height="100%" width="100%" zoomLevel={zoomValue}/>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="signature-dialog-title"
              aria-describedby="signature-dialog-description"
            >
              <DialogTitle id="signature-dialog-title">
                Add Signature
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="signature-dialog-description">
                  Sign Here
                </DialogContentText>
                <SignatureCanvas
                  penColor="green"
                  canvasProps={{
                    width: 500,
                    height: 200,
                    style: { border: "1px dotted grey" },
                    className: "sigCanvas",
                  }}
                  ref={signature}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm} autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    );
  }
);

export interface SaveParams {
  url: string;
  date: string;
}

const saveToUrl = (workbook: Workbook, params: SaveParams) => {
  return new Promise<{ filename: string }>((resolve, reject) => {
    const opt = new WorkbookSaveOptions();
    opt.type = "blob";
    const { url, date } = params;

    workbook.save(
      opt,
      async (d) => {
        const fileName = first(split(last(split(url, "/")), "-"));
        const ext = last(split(url, "."));
        const myHeaders = new Headers();
        const token = getJWT();
        myHeaders.append("authorization", `Bearer ${token}`);

        const formdata = new FormData();
        formdata.append("Content-Type", "multipart/form-data");
        if (fileName && ext) formdata.append("attachment", d, fileName + ext);

        // mapped from named fields in spreadsheet
        if (date) formdata.append("date", date);

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
        };
        const response = await fetch(UPLOAD_URL, requestOptions);

        if (!response.ok) {
          const res = await response.json();
          if (!res?.errors) return reject("Unknown response error");
          const errors = res.errors
            .map((e: { msg: string; param: string }) => `${e.msg}: ${e.param}`)
            .join(", ");
          return reject(errors);
        }
        const { key } = await response.json();
        return resolve({ filename: key });
      },
      (e) => {
        return reject(e);
      }
    );
  });
};

const toggleCheckmarks = (s: IgrSpreadsheet, oldSelectedCell: SpreadsheetCell | null, selectedCell: SpreadsheetCell) => {
  // there are two ranges to check, it is recommended to check dlornrrange first for unknown reasons
  const dlnr = s.activeWorksheet.getRegion("DLORNRRANGE");
  const oknr = s.activeWorksheet.getRegion("OKNR");
  const checkrange = dlnr || oknr;
  if (checkrange) {
    // check that the newValue cell intersects our range
    // there's there doesn't appear to be an intersection method available
    // in the infragistics library for regions, only ranges from what I've found
    if (
      checkrange.firstColumn <= selectedCell.column &&
      checkrange.firstRow <= selectedCell.row &&
      checkrange.lastColumn >= selectedCell.column &&
      checkrange.lastRow >= selectedCell.row
    ) {
      // e.newValue returns a SpreadSheet Cell, which doesn't have any capability to get or set values
      // which would be ideal. What we need is a WorkSheet Cell, but unfortunately there doesn't
      // appear to be a direct way to convert between the two types
      // So here we use row and column indexes, convert them to 1 based indexes (excel's format),
      // and then explicitly use the R1C1 reference mode
      const cell = s.activeWorksheet.getCell(
        `R${selectedCell.row + 1}C${selectedCell.column + 1}`,
        CellReferenceMode.R1C1
      );

      // there are two columns of values, left is checkmarks, and right is x's
      // this is a checklist, and we are making it so that you only need to
      // click on the cell to set or remove these marks

      // note that we always clear the 'other' cell, as it does not make sense to have both values set
      // also, wingding font is used, which is why we are setting variations of u
      // ü is a checkmark
      // û is a x mark

      // this is the left side, checkmark side
      if (checkrange.firstColumn === selectedCell.column) {
        if (cell.value === "ü") {
          cell.value = "";
        } else {
          cell.value = "ü";
        }

        const other = s.activeWorksheet.getCell(
          `R${selectedCell.row + 1}C${selectedCell.column + 2}`,
          CellReferenceMode.R1C1
        );
        other.value = "";
      }

      // this is the right side, x side
      if (checkrange.lastColumn === selectedCell.column) {
        if (cell.value === "û") {
          cell.value = "";
        } else {
          cell.value = "û";
        }

        const other = s.activeWorksheet.getCell(
          `R${selectedCell.row + 1}C${selectedCell.column}`,
          CellReferenceMode.R1C1
        );
        other.value = "";
      }
    }
  }
  // we have an additional range to check, but it is a single column with only checkmarks
  const simpleChks = s.activeWorksheet.getRegion("DLSIMPLECHKS");
  if (simpleChks) {
    if (
      simpleChks.firstColumn <= selectedCell.column &&
      simpleChks.firstRow <= selectedCell.row &&
      simpleChks.lastColumn >= selectedCell.column &&
      simpleChks.lastRow >= selectedCell.row
    ) {
      const cell = s.activeWorksheet.getCell(
        `R${selectedCell.row + 1}C${selectedCell.column + 1}`,
        CellReferenceMode.R1C1
      );

      if (cell.value === "ü") {
        cell.value = "";
      } else {
        cell.value = "ü";
      }
    }
  }
  // we have an additional range to check, but it is a single column with only checkmarks called SIMPLECHECK
  const simpleChk = s.activeWorksheet.getRegion("SIMPLECHECK");
  if (simpleChk) {
    const getIntersectingCells = s.workbook.namedReferences(6).referencedRegions.filter(function(simpleChks) {
      //console.log(simpleChks.firstRow, simpleChks.firstColumn, simpleChks.lastRow, simpleChks.lastColumn);
      if (
        simpleChks.firstColumn <= selectedCell.column &&
        simpleChks.firstRow <= selectedCell.row &&
        simpleChks.lastColumn >= selectedCell.column &&
        simpleChks.lastRow >= selectedCell.row
      ) {
          //console.error('In range', simpleChks, selectedCell)
          return simpleChks
      }
  })
    if (getIntersectingCells.length > 0) {
      const cell = s.activeWorksheet.getCell(
        `R${selectedCell.row + 1}C${selectedCell.column + 1}`,
        CellReferenceMode.R1C1
      );
      console.log(oldSelectedCell, selectedCell, 'current cell: ' , cell, cell.value)
      if (cell.value === "ü") {
        console.log('Setting new value to "x"')
        cell.value = "x";        
      } else if (cell.value === "x") {
        cell.value = "ü";
        console.log('Setting new value to "ü"')
      } else {
        console.log('Setting new value to "x"')
        cell.value = "x";
      }
    }
  }
};

export default FileViewerExcel;
