import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { v4 } from 'uuid';
import './styles.scss';
import { isNil, head } from 'lodash';
import { Subscription } from 'rxjs';
import {
  Alert, MenuItem, Select, Snackbar,
} from '@mui/material';
import CommentInput from './components/CommentInput';
import Comment from './components/Comment';
import { CommentDocument } from '../../rxdb/collections/Comments/schema';
import { getDatabase } from '../../rxdb';
import { useAuth } from '../../contexts/auth';

interface InjectedProps {
  selectorKeyValue: string;
  selectorType: string;
  disableEdit?: boolean;
  addCommentOnly?: boolean;
}

enum OrderEnum {
  OLDEST_FIRST,
  OLDEST_LAST,
}

const orderOptions = [
  { key: OrderEnum.OLDEST_LAST, label: 'Newest first' },
  { key: OrderEnum.OLDEST_FIRST, label: 'Oldest first' },
];

const Comments: FC<InjectedProps> = ({
  selectorKeyValue,
  selectorType,
  disableEdit = false,
  addCommentOnly = false,
}) => {
  const { user } = useAuth();
  const [snackbar, setSnackbar] = useState<{ message: string; severity: any }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [comments, setComments] = useState<CommentDocument[]>([]);
  const [orderBy, setOrderBy] = useState(head(orderOptions)?.key);
  const activeSubscriptions = useRef<Subscription[]>([]);

  const getComments = async () => {
    const db = await getDatabase();

    if (isNil(selectorType)) return;

    activeSubscriptions.current = [
      db.comments
        .find({
          selector: {
            referenceIssueId: selectorKeyValue,
            referenceIssueType: selectorType,
            deletedAt: {$eq: null}, // TD-1688 hide deleted comments
          },
          sort: [
            { createdAt: orderBy === OrderEnum.OLDEST_LAST ? 'desc' : 'asc' },
          ],
        })
        .$.subscribe((comments) => {
          const updateComments = comments.filter(item => !item.replyId);
          setComments(updateComments);
          setLoading(false);
        }),
    ];
  };

  useEffect(() => {
    getComments();
    return () => {
      activeSubscriptions.current?.map((s) => s.unsubscribe());
    };
  }, [orderBy]);

  const onSubmit = async (content: string, id?: string) => {
    if (isNil(user)) return;

    const db = await getDatabase();

    try {
      await db.comments.upsert({
        referenceIssueId: selectorKeyValue,
        referenceIssueType: selectorType,
        id: id || v4(),
        content,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        authorId: user.fldCrewID,
      });
      setSnackbar({
        message: 'Comment successfully has been created',
        severity: 'success',
      });
    } catch (e: any) {
      console.error(e);
      setSnackbar({
        message: e.message,
        severity: 'error',
      });
    }
  };
  const renderCommentList = () =>
  // TODO: Think on how should it work. Replace me with react-window once decided.
  // TODO: Big issue with input + height inside drawer.

    comments.map((comment) => (
      <Comment key={comment.id} comment={comment} disableEdit={disableEdit || addCommentOnly} />
    ));
  return (
    <div className="pt-5 comments h-full pb-19">
      <div className="flex flex-col items-start">
        <label htmlFor="orderBy" className="font-bold mb-3 block">
          Order By
        </label>
        <Select
          size="small"
          id="orderBy"
          value={orderBy}
          onChange={(e: any) => setOrderBy(e?.target?.value)}
        >
          {orderOptions.map((o) => (
            <MenuItem key={o.key} value={o.key}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className="mb-3">
        {(
          <CommentInput className="pt-9 pb-4" onSubmit={onSubmit} readOnly={disableEdit} />
        )}
      </div>

      {renderCommentList()}

      <Snackbar
        open={!!snackbar}
        autoHideDuration={2000}
        onClose={() => setSnackbar(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar?.severity}>{snackbar?.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default Comments;
