// Styles
import '../../../../App.css';
import '../../../../theme/styles.css';
import '../../styles.css';

// React Components
import { FC, useEffect, useRef, useState } from 'react';
import { isNil, get, size, last, omit, isEmpty, isArray, isString } from 'lodash';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

// TDI components
import TaskSummaryForm from '../../taskDE/component/TaskSummaryForm';
import SparesTab from '../../../SparesTab';
import AttachmentTab from '../../../../modules/Attachments';
import Comments from '../../../../modules/Comments';
import Tabs from '../../../UI/Tabs';
import Input from '../../../UI/Forms/Input';
import AttachmentTabButton from "../../../../modules/Attachments/component/AttachmentTabButton";
import useSnackbar from "../../../../hooks/useSnackbar";

// Data
import { getDatabase } from '../../../../rxdb';
import { Subscription } from 'rxjs';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import { WorkIssues } from '../../../../generated/graphql';
import { TblSparesUsedDocument } from '../../../SparesTab/rxdb';

//Utils
import { validateForm } from '../../taskDE/utils';
import { normalizeDateTime } from 'src/helpers';
import { filterNonNullStrings, handleCharLimitWarning } from 'src/utils';
import { CHAR_LIMIT } from 'src/consts';



interface Props {
  initialValue: WorkIssuesDocument;
  onSave: () => void;
  disableEdit?: boolean;
}

const EqNewTaskCreateForm: FC<Props> = ({ initialValue, onSave, disableEdit }) => {
  const {
    control, setValue, handleSubmit, getValues, reset, watch,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      Subject: initialValue.Subject || '',
      fldCustJob: initialValue.fldCustJob || '',
      Notes: initialValue.Notes || '',
      fldHTML: initialValue.fldHTML || '',
      fldIsWarranty: initialValue.fldIsWarranty || false,
      fldSMS: initialValue.fldSMS || false,
      DateStart: normalizeDateTime(initialValue.DateStart),
      DateDue: normalizeDateTime(initialValue.DateDue),
      PercentComplete: initialValue.PercentComplete || null,
      AssignedTo: isEmpty(initialValue.AssignedTo)
        ? []
        : initialValue.AssignedTo?.split(', '),
      fldStatus: initialValue?.fldStatus || null,
      fldSRHKey: initialValue.fldSRHKey,
      fldLocHierarchy: initialValue.fldLocHierarchy,
      fldWorkList: initialValue?.fldWorkList || null,
      fldPriority: initialValue?.fldPriority || null,
      Department: initialValue?.Department || null,
      Company: initialValue.Company,
      Equipment: initialValue?.Equipment || null,
    },
  });
  const { showSnackbar } = useSnackbar();
  const [issue] = useState<WorkIssues>(initialValue.toJSON());
  const [spareCount, setSpareCount] = useState<number>(0);
  const [spares, setSpares] = useState<TblSparesUsedDocument[]>([]);
  const [isSparesLoading, setSparesLoading] = useState<boolean>(false);
  const [commentsCount, setCommentsCount] = useState<number>(0);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const formInitialValues = useRef<any>({});
  const { WORKISSUES } = CHAR_LIMIT;

  const getDocumentCount = async () => {
    // Count Attachments / Photos / Spares
    if (isNil(initialValue.JobNumber)) {
      return;
    }
    const db = await getDatabase();

    setSparesLoading(true);

    // Find and count attachments
    activeSubscriptions.current = [
      db.tblsparesused
        .find({
          selector: {
            WorkKey: get(initialValue, 'JobNumber', ''),
          },
        })
        .$.subscribe((spares: TblSparesUsedDocument[] | null) => {
          setSpareCount(size(spares));
          setSpares(spares || []);
          setSparesLoading(false);
        }),

      db.comments
        .find({
          selector: {
            $and: [
              {referenceIssueId: isNil(initialValue.JobNumber) ? '' : initialValue.JobNumber},
              {deletedAt: {$eq: null}},
            ],
          },
        })
        .$.subscribe((c: any) => {
          setCommentsCount(size(c));
        }),
    ];
  };
  const setInitialValues = async () => {
    // const department = await initialValue.populate('Department');
    // const worklist = await initialValue.populate('fldWorkList');
    const status = await initialValue.populate('fldStatus');
    const priority = await initialValue.populate('fldPriority');
    const equipment = await initialValue.populate('EqKey');

    const category = await initialValue.populate('fldSRHKey');

    const defaultValues = {
      ...getValues(),
      // fldSRHKey: category?.toJSON() || null, // avoid infinite loop.
      // fldWorkList: worklist?.toJSON() || null,
      fldStatus: status?.toJSON() || null,
      fldPriority: priority?.toJSON() || null,
      // Department: department?.toJSON() || null,
      Equipment: equipment?.toJSON() || null,
      fldLocHierarchy: initialValue.fldLocHierarchy || null,
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
  };

  useEffect(() => {
    getDocumentCount();
    setInitialValues();

    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      formInitialValues.current = {};
    };
  }, []);

  const onChange = async (name: string, value: any) => {
    // If it's equipment change -> populate location based on it.
    if (name === 'Equipment') {
      const equipment = value as EquipmentDocument;
      setValue('fldLocHierarchy', equipment?.fldLocHierarchy);
    }
    setValue(name, value, { shouldDirty: true });
  };

  const isCreation = isNil(initialValue.JobNumber);

  const extractContent = (s: string) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const handleSave = async (data: any) => {
    if (!validateForm(data, showSnackbar)) return;

    const db = await getDatabase();

    // Create items before creating Issue.
    const getOrCreate = async (value: any, keyExpr: string) => {
      if (isNil(value)) return null;

      if (value.isCreate) {
        // Create item first and then proceed
        const collection = (db as any)[value.collection];

        // TODO: Hook up tblDefaults
        const result = await collection?.upsert(
          omit(value, ['inputValue', 'isCreate', 'collection']),
        );

        return result[keyExpr];
        // create value collection
      }

      return value[keyExpr];
    };

    const {
      fldStatus,
      fldPriority,
      fldWorkList,
      fldSRHKey,
      DateCompleted,
      Notes,
      AssignedTo,
      fldIsWarranty,
      fldSMS,
      PercentComplete,
      Subject,
      fldCustJob,
      Company,
      DateStart,
      DateDue,
      Department,
      Equipment,
      fldLocHierarchy,
      fldHTML
    } = data;

    const completeProgress = PercentComplete
      ? parseInt(PercentComplete, 10)
      : null;

    const document = {
      ...issue,
      AssignedTo: AssignedTo?.join(', ') || null,
      DateCompleted: normalizeDateTime(DateCompleted),
      DateDue: normalizeDateTime(DateDue),
      DateStart: normalizeDateTime(DateStart),
      Notes: extractContent(fldHTML),
      fldHTML,
      PercentComplete: completeProgress,
      Subject,
      fldCustJob,
      fldIsWarranty,
      fldSMS,
      Company: Company?.DisplayMember || Company || null,
      EqKey: Equipment?.EqKey || null,
      fldLocHierarchy:
        (isArray(fldLocHierarchy) ? last(fldLocHierarchy) : fldLocHierarchy)
        || null,
      fldSRHKey: (isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey) || null,
      fldStatus: await getOrCreate(fldStatus, 'PKey'),
      // fldWorkList: await getOrCreate(fldWorkList, 'PKey'),
      fldWorkList: isString(fldWorkList)
      ? fldWorkList
      : fldWorkList?.fldMember || null ,
      fldPriority: fldPriority?.PKey || null,
      // Department: Department?.PKey || null,
      Department: isString(Department)
      ? Department
      : Department?.fldMember || null,
      Completed: false,
      // Since we are passing empty object from parent we should distinguish create/update actions.
      // In case of Update we have to pass primary key (JobNumber)
      JobNumber: initialValue.primary || uuid(), // Set primary key, so we will be able to upsert.
      updatedAt: new Date().toISOString(),
    } as any;

    try {
      const res = await db.collections.workissues.upsert(document);

      onSave();
    } catch (e: any) {
      showSnackbar({
        type: 'error',
        message: e.message,
      });
    }
  };

  const relatedKeys = filterNonNullStrings([initialValue.JobNumber]);

  return (
    <form
      id="Equipment-create-new-task-form"
      className="relative bg-white flex-grow"
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          <div className="mb-6">
            <div className="mui-textfield-header mb-2">
              <Input
                inputProps={{
                  size: 'medium',
                  label: 'Task Subject',
                  variant: 'standard',
                }}
                rules={{ required: true, maxLength:  WORKISSUES.Subject}}
                warning={(value) => handleCharLimitWarning(value, WORKISSUES.Subject)}
                control={control}
                name="Subject"
              />
            </div>
            <Input
              inputProps={{
                size: 'small',
                label: 'Job number',
                variant: 'standard',
              }}
              defaultValue={initialValue.fldCustJob}
              rules={{ maxLength: WORKISSUES.fldCustJob }}
              warning={(value) => handleCharLimitWarning(value, WORKISSUES.fldCustJob)}
              control={control}
              name="fldCustJob"
            />
          </div>

          <div className="mt-3 mb-20">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <TaskSummaryForm
                      watch={watch}
                      control={control}
                      initialValue={initialValue}
                      form={issue}
                      onChange={onChange}
                      getValues={getValues}
                      disableEdit={disableEdit}
                    />
                  ),
                },
                {
                  label: `Spares (${spareCount})`,
                  component: (
                    <SparesTab
                      loading={isSparesLoading}
                      issue={initialValue}
                      spares={spares}
                    />
                  ),
                },
                {

                  label: <AttachmentTabButton type="attachments" selector={{
                    fldRecordKey: isNil(initialValue.JobNumber)
                      ? ''
                      : initialValue.JobNumber,
                  }} />,
                  component: (
                    <AttachmentTab
                      type="attachments"
                      primaryKey={initialValue.JobNumber}
                      relatedKeys={relatedKeys}
                      allowedFileExtensions={['.pdf']}
                      SRHKey={initialValue.fldSRHKey}
                      tableName="Equipment"
                    />
                  ),
                },

                {
                  label: <AttachmentTabButton label="Photos" type="photos" selector={{
                    fldRecordKey: isNil(initialValue.JobNumber)
                      ? ''
                      : initialValue.JobNumber,
                  }} />,
                  component: (
                    <AttachmentTab
                      type="photo"
                      primaryKey={initialValue.JobNumber}
                      relatedKeys={relatedKeys}
                      SRHKey={initialValue.fldSRHKey}
                      allowedFileExtensions={['.jpg', '.jpeg', '.gif', '.png']}
                      tableName="Equipment"
                    />
                  ),
                },
                {
                  label: `Comments (${commentsCount})`,
                  component: (
                    <Comments
                      selectorType="Equipment"
                      selectorKeyValue={initialValue.JobNumber}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default EqNewTaskCreateForm;
