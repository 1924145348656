import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { v4 as uuid } from 'uuid';
import {
  FC, useEffect, useRef, useState,
} from 'react';
import {
  isNil, size, last, omit, isArray, isString,
} from 'lodash';
import { useAppState } from 'src/contexts/app-state';
import { DeleteTwoTone } from '@mui/icons-material';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { Subscription } from 'rxjs';
import { useForm } from 'react-hook-form';
import { CertificateDocument } from 'src/rxdb/collections/Certificates/rxdb';
import Tabs from '../../UI/Tabs';
import Input from '../../UI/Forms/Input';
import { Certificate } from '../../../generated/graphql';
import CertificateSummaryForm from './component/CertificateSummaryForm';
import AttachmentTab from '../../../modules/Attachments';
import Comments from '../../../modules/Comments';
import { getDatabase } from '../../../rxdb';
import { CHAR_LIMIT, FileExtensionType } from '../../../consts';
import StickyAppBar from '../../UI/StickyAppBar';
import { validateForm } from './utils';
import WarningDialog from 'src/components/UI/WarningDialog';
import RecordEditWarningCard from 'src/components/UI/RecordEditWarningCard';
import { normalizeDateTime, normalizeDateFormValue } from 'src/helpers';
import { filterNonNullStrings, handleCharLimitWarning } from 'src/utils';

interface Props {
  initialValue: CertificateDocument;
  onCancel: () => void;
  onSave: (issue: Certificate, isCreated: boolean) => void;
  onDelete: (issue: CertificateDocument) => void;
  onUndo?: () => void;
  onCertificateCreate?: () => void;
  isCreated?: boolean;
  certificateType?: any;
  type?: string;
  isPassport?: boolean;
}

const CertificatesDetail: FC<Props> = ({
  initialValue,
  onCancel,
  onSave,
  onDelete,
  onUndo,
  onCertificateCreate,
  isCreated = false,
  certificateType,
  isPassport = false,
  type,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState,
  } = useForm<any>({
    // For uncontrolled components keep empty string or undefined. Null wouldn't work.
    defaultValues: {
      PKey: initialValue.PKey,
      fldType: initialValue.fldType || '',
      fldCertNum: initialValue.fldCertNum || '',
      fldNotes: initialValue.fldNotes || '',
      fldSMS: initialValue.fldSMS || false,
      DateIssue: normalizeDateFormValue(initialValue.DateIssue),
      DateExpire: normalizeDateFormValue(initialValue.DateExpire),
      fldNationality: initialValue.fldNationality || '',
      fldIssue: initialValue.fldIssue || '',
      fldCertType: initialValue.fldCertType || 0,
      fldArchive: initialValue.fldArchive || false,
      fldTimeWarn: initialValue.fldTimeWarn || null,
      fldTimeWarnMeasure: initialValue.fldTimeWarnMeasure || null,
      fldResponsible: initialValue.fldResponsible || null,
      // fldStatus: null,
      fldCrewID: initialValue.fldCrewID || null,
      fldSRHKey: initialValue.fldSRHKey,
    },
  });

  const oldItemState = useRef<Certificate>(initialValue.toJSON());
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const [oldItemUndo, setOldItemUndo] = useState<Certificate[]>([]);
  const [currentTab, setTab] = useState(0);
  const [item, setItem] = useState<Certificate>(initialValue.toJSON());
  const [isEdited, setEdited] = useState<boolean>(false);
  const [attachmentCount, setAttachmentCount] = useState<number>(0);
  const [photoCount, setPhotoCount] = useState<number>(0);
  const [commentsCount, setCommentsCount] = useState<number>(0);
  const activeSubscriptions = useRef<Subscription[]>([]);
  const [initialDefaultVlaue, setInitialDefaultValue] = useState<any>([]);
  const formInitialValues = useRef<any>({});
  const [isDeleting, setIsDeleting] = useState(false);

  const { settingsPersonal } = useAppState();
  const { CERTIFICATES } = CHAR_LIMIT;
  const [editFlag, setEditFlag] = useState(false);
  const [cannotViewFlag, setCannotViewFlag] = useState(false);
  const setPermission = async ()=>{
    if(settingsPersonal.fldCerts === 1){
      setEditFlag(true)
    }
    if(settingsPersonal.fldCerts === 0 || isNil(settingsPersonal.fldCerts)){
      setCannotViewFlag(true)
    }
  }

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const getDocumentCount = async () => {
    // Count Attachments / Photos / Comments
    const db = await getDatabase();

    // Find and count attachments
    activeSubscriptions.current = [
      db.tbldocumentcrossreference
        .find({
          selector: {
            fldRecordKey: initialValue.PKey,
          },
        })
        .$.subscribe(async (attachments: any) => {
          const results = await Promise.all<string | undefined>(
            attachments.map(async (attachment: any) => {
              const revision = await db.documentrevision
                .findOne({
                  selector: {
                    fldFKey: attachment.fldFKey,
                  },
                })
                .exec();

              if (revision) {
                return revision.fldFileName
                  ? last(revision.fldFileName.split('.'))
                  : undefined;
              }

              return undefined;
            }),
          );

          setPhotoCount(
            size(
              results
                .filter((e) => e)
                .filter((extension) => [
                  FileExtensionType.JPEG,
                  FileExtensionType.JPG,
                  FileExtensionType.PNG,
                  FileExtensionType.TIF,
                  FileExtensionType.BMP,
                ].includes(extension!.toUpperCase() as any)),
            ),
          );

          setAttachmentCount(
            size(
              results
                .filter((e) => e)
                .filter((extension) => [
                  FileExtensionType.PDF,
                  FileExtensionType.XLS,
                  FileExtensionType.XLSX,
                  FileExtensionType.DOC,
                  FileExtensionType.DOCX,
                  FileExtensionType.RTF,
                ].includes(extension!.toUpperCase() as any)),
            ),
          );
        }),

      db.comments
        .find({
          selector: {
            $and: [
              {referenceIssueId: isNil(initialValue.PKey) ? '' : initialValue.PKey},
              {deletedAt: {$eq: null}},
            ]
          },
        })
        .$.subscribe((c: any) => setCommentsCount(size(c))),
    ];
  };
  const setInitialValues = async () => {
    // const category = await initialValue.populate('fldSRHKey');
    const defaultValues = {
      ...getValues(),
    };

    formInitialValues.current = defaultValues;
    reset(defaultValues);
    // reset({
    //   ...getValues(),
    //   // fldSRHKey: category?.toJSON() || null, // avoid infinite loop.
    // });
    // setInitialDefaultValue({
    //   ...getValues(),
    // });
  };

  useEffect(() => {
    getDocumentCount();
    setInitialValues();

    return () => {
      activeSubscriptions.current?.map((sub) => sub.unsubscribe());
      activeSubscriptions.current = [];
      formInitialValues.current = {};
    };
  }, []);

  useEffect(()=>{
    if(settingsPersonal){
      setPermission()
    }
  },[settingsPersonal])

  const onChange = (name: string, value: any) => {
    let shouldDirty = true;

    if (name === 'fldSRHKey') {
      const updatedValue = (isArray(value) ? last(value) : value) || null;
      if (initialValue.fldSRHKey === updatedValue) {
        shouldDirty = false;
      }
    }

    setValue(name, value, { shouldDirty: shouldDirty });
    setItem(getValues())
  };

  const handleCancel = () => {
    setOldItemUndo([]);
    onCancel();
  };

  const handleSave = async (data: any) => {
    if (!validateForm(data, setSnackbar, certificateType)) return;
    const db = await getDatabase();

    // Create items before creating Issue.
    const getOrCreate = async (value: any, keyExpr: string) => {
      if (isNil(value)) return null;

      if (value.isCreate) {
        // Create item first and then proceed
        const collection = (db as any)[value.collection];

        // TODO: Hook up tblDefaults
        const result = await collection?.upsert(
          omit(value, ['inputValue', 'isCreate', 'collection']),
        );

        return result[keyExpr];
        // create value collection
      }

      return value[keyExpr];
    };

    const {
      fldType,
      fldCertNum,
      fldNotes,
      fldSMS,
      DateIssue,
      DateExpire,
      fldNationality,
      fldIssue,
      fldCertType,
      fldArchive,
      fldTimeWarn,
      fldTimeWarnMeasure,
      fldResponsible,
      // fldStatus,
      fldCrewID,
      fldSRHKey,
    } = data;

    const document = {
      ...item,
      fldType,
      fldCertNum,
      fldNotes,
      fldSMS,
      DateIssue: normalizeDateTime(DateIssue),
      DateExpire: normalizeDateTime(DateExpire),
      fldNationality: isString(fldNationality)
      ? fldNationality
      : fldNationality?.fldMember || null,
      fldIssue,
      fldCertType,
      fldArchive,
      fldResponsible: isString(fldResponsible)
        ? fldResponsible
        : fldResponsible?.fldMember || null,
      fldTimeWarn: parseInt(fldTimeWarn, 10) || null,
      fldTimeWarnMeasure: fldTimeWarnMeasure?.measureValue || null,
      // fldStatus,
      fldCrewID : isString(fldCrewID)
      ? fldCrewID
      : fldCrewID?.fldCrewID || null,
      fldSRHKey: (isArray(fldSRHKey) ? last(fldSRHKey) : fldSRHKey) || null,
      updatedAt: new Date().toISOString(),

      // Since we are passing empty object from parent we should distinguish create/update actions.
      // In case of Update we have to pass primary key (JobNumber)
      PKey: initialValue.primary || uuid(), // Set primary key, so we will be able to upsert.
    } as any;

    try {
      setOldItemUndo([]);
      const doc = omit(document,['fldPassport'])
      const res = await db.collections.certificates.upsert(doc);

      onSave(res, isCreated);

      // setSnackbar({
      //   open: true,
      //   type: 'success',
      //   message: 'Success!',
      // });

      if (isCreated) {
        onCertificateCreate && onCertificateCreate();
      }
      await setInitialDefaultValue(getValues());
      reset(getValues());
    } catch (e: any) {
      setSnackbar({
        open: true,
        type: 'error',
        message: e.message,
      });
    }
  };
  const handleDelete = () => {
    setIsDeleting(true)
  };

  const handleDeleteOk = () => {
    onDelete(initialValue);
    setIsDeleting(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  }
  const handleUndo = () => {
    const item = last(oldItemUndo);
    setItem(item as Certificate);

    // Remove last step from our store
    setOldItemUndo(oldItemUndo.splice(-1));

    onUndo && onUndo();
  };

  const handleCancelUndo = () => {

    if (isCreated) {
      return onCancel();
    }
    reset(formInitialValues.current);
  };

  const handleOk = (isEditing: boolean) => {
    if (isEditing && !validateForm(getValues(), setSnackbar, certificateType)) return;
    if (isEditing) return; // We will send submit action that will be handled in HandleSave.

    handleCancel();
  };

  // Save

  // Delete - should just place the jobnumber in tblRecycle then row should
  // be removed from our list

  if (isNil(item)) return null;

  const hasValuesBeenChanged = formState.isDirty
    && (size(formState.dirtyFields) > 0 || size(formState.touchedFields) > 0);

  const isEditing = hasValuesBeenChanged || isCreated;

  const relatedKeys = filterNonNullStrings([initialValue.PKey]);

  const formClass = type === 'Dialog'
    ? 'relative bg-white flex-grow'
    : 'relative bg-white pt-14 md:pt-19 flex-grow';
    

  if(cannotViewFlag) {
    return (
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 pt-16 h-full">
          <div className="mb-6">         
            <RecordEditWarningCard message='You do not have permission to view any data for this record'/>             
          </div>
        </div>
      </div>  
    )
  }

  return (
    <form
      id="Certificates-Edit-form"
      className={`${formClass}`}
      onSubmit={handleSubmit(handleSave)}
    >
      <div className="bg-white h-full flex-grow pt-6">
        <div className="px-6 h-full">
          <div className="mb-6">
          {(editFlag) && (
                <RecordEditWarningCard />
              )}
            <div className="mui-textfield-header mb-2">
              <Input
                inputProps={{
                  size: 'medium',
                  label: 'Type/Title',
                  variant: 'standard',
                }}
                rules={{ required: true, maxLength: CERTIFICATES.fldType }}
                warning={(value) => handleCharLimitWarning(value, CERTIFICATES.fldType)}
                control={control}
                name="fldType"
              />
            </div>
            {/* <Input
              inputProps={{
                size: 'small',
                label: 'Document Number',
                variant: 'standard',
              }}
              defaultValue={initialValue.fldCertNum}
              control={control}
              name="fldCertNum"
            /> */}
          </div>

          <div className="mt-3 mb-20">
            <Tabs
              tabs={[
                {
                  label: 'Summary',
                  component: (
                    <CertificateSummaryForm
                      control={control}
                      initialValue={initialValue}
                      form={item}
                      onChange={onChange}
                      passport={isPassport}
                      certificateType={certificateType}
                      isCreated={isCreated}
                    />
                  ),
                },
                {
                  label: `Attachments (${attachmentCount})`,
                  component: (
                    <AttachmentTab
                      type="attachments"
                      primaryKey={initialValue.PKey}
                      relatedKeys={relatedKeys}
                      allowedFileExtensions={['.pdf']}
                      disabled={editFlag}
                      SRHKey={initialValue.fldSRHKey}
                      tableName="Certificates"
                      // disableCheckbox={certificateType === 'CREWPROFILE'? true: false}
                      disableCheckbox={true} // TD-1202 Remove Ability to add Vessel Certificates to the Central Document Library
                    />
                  ),
                },
                {
                  label: `Photos (${photoCount})`,
                  component: (
                    <AttachmentTab
                      type="photo"
                      disabled={editFlag}
                      primaryKey={initialValue.PKey}
                      relatedKeys={relatedKeys}
                      SRHKey={initialValue.fldSRHKey}
                      allowedFileExtensions={['.jpg', '.jpeg', '.gif', '.png']}
                      tableName="Certificates"
                      disableCheckbox={certificateType === 'CREWPROFILE'? true: false}
                    />
                  ),
                },
                {
                  label: `Comments (${commentsCount})`,
                  component: (
                    <Comments
                      selectorType="Certificates"
                      selectorKeyValue={initialValue.PKey}
                      disableEdit={editFlag}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>

      {type !== 'Dialog' && (
        <StickyAppBar
          cancelText="Cancel"
          okType={isEditing ? 'submit' : 'button'}
          okText={isEditing ? 'Save' : 'Close'}
          onOk={() => handleOk(isEditing)}
          disabled={editFlag && isEditing}
          onCancel={isEditing ? () => handleCancelUndo() : undefined}
        >
          {/* {!isEmpty(oldItemUndo) && (
          <IconButton
            onClick={handleUndo}
            color="inherit"
            aria-label="Undo last step"
          >
            <Undo />
          </IconButton>
        )} */}
          {!editFlag && !isNil(item.PKey) && (
            <IconButton
              onClick={handleDelete}
              hidden={!!item.PKey}
              color="error"
              aria-label="Delete task"
            >
              <DeleteTwoTone />
            </IconButton>
          )}
        </StickyAppBar>
      )}
      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      {/* <AppBar position="fixed" color="inherit" sx={{ left: 'auto', right: 0, width: '60%', top: 'auto', bottom: 0 }}>
        <Toolbar>
          {isCreation ? (
            <Button type="submit" className="mr-3 w-32" variant="contained">Create</Button>
          ) : (
            <Button type="submit" className="mr-3 w-32" variant="contained">Save</Button>
          )}
          <Button onClick={handleCancel} className="w-32">Cancel</Button>
          <Box className="flex-grow" />
          {!isEmpty(oldItemUndo) && (
            <IconButton onClick={handleUndo} color="inherit" aria-label="Undo last step">
              <Undo />
            </IconButton>
          )}
          <IconButton onClick={handleDelete} hidden={!!item.PKey} color="error" aria-label="Delete task">
           <DeleteTwoTone />
          </IconButton>
        </Toolbar>
      </AppBar> */}

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
          {snackBar.message}
        </Alert>
      </Snackbar>

      {/* <CompleteCertificateTaskModal */}
      {/*  visible={false} */}
      {/*  issue={initialValue} */}
      {/*  onClose={handleModalClose} */}
      {/* /> */}

      {/* <CompleteExpireEquipmentTaskModal */}
      {/*  form={form} */}
      {/*  issue={initialValue} */}
      {/*  visible={expireEquipmentPopupVisible} */}
      {/*  handleChange={handleModalChange} */}
      {/*  handleCloseChange={handleCloseChange} */}
      {/* /> */}

      {/* <RecurringTaskModal */}
      {/*  form={form} */}
      {/*  workissue={workissue} */}
      {/*  visible={recurringTaskPopupVisible} */}
      {/*  handleModalChange={handleRecurringTaskModalChange} */}
      {/* /> */}

      {/* <AdHocTaskModal */}
      {/*  form={form} */}
      {/*  workissue={workissue} */}
      {/*  visible={adhocTaskPopupVisible} */}
      {/*  handleModalChange={handleAdHocTaskModalChange} */}
      {/* /> */}
    </form>
  );
};

export default CertificatesDetail;
