// Style
import "./style.css";

// React Components
import { useState, FC, useRef, useCallback, useEffect, MutableRefObject } from "react";
import { isEmpty, pick, size, isNil } from "lodash";
import moment from "moment";

// MUI components
import { Alert, Snackbar, IconButton, Button, DialogTitle, Dialog, DialogActions, DialogContent, Box, useMediaQuery } from "@mui/material";
import { DeleteTwoTone } from "@mui/icons-material";
import { LoadingButton } from '@mui/lab';

// Data
import { Subscription } from "rxjs";
import { getDatabase, TDIDb } from "src/rxdb";
import { MangoQuerySortDirection } from "rxdb";
import { LogEntry } from "src/generated/graphql";
import { LogEntryDocument } from "src/pages/LogEntryPage/rxdb";
import { TypeComputedProps, TypeSortInfo } from "@inovua/reactdatagrid-enterprise/types";

// TDI components
import LicensedReactDataGrid from "src/components/UI/LicensedReactDataGrid";
import LogEntryDetailForm from "../../logentryDE/LogEntryDetailForm";
import WarningDialog from 'src/components/UI/WarningDialog';
import { CustomDialog } from "src/helpers/modals";

// Utils
import { validateForm } from 'src/components/dataentry/logentryDE/utils';
import {getRegexByOperator} from "../../../../utils";
import { AccessType } from "src/consts";

interface Props {
  keyName?: string;
  keyValue: string | null;
  disableEdit?: boolean;
}

const filter = [
  {
    name: "LogEntry",
    operator: "contains",
    type: "string",
    value: "",
  },
];

const transformData = async (item: any) => ({
  ...pick(item, [
    "PKey",
    "LogEntry",
    "LogDate",
    "fldHours",
  ]),
  original: item,
});

const getSelectorByFilterName = async (
  name: string,
  value: string,
  operator: string,
  db: TDIDb
) => {
  switch (name) {
    case "LogEntry":
      return {
        LogEntry: { $regex: getRegexByOperator(operator, value) },
      };

    default:
      return {};
  }
};

const EqHistory: FC<Props> = ({ keyName = 'EqKey', keyValue, disableEdit=false }) => {
  const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1200px)');
  const isMobile = useMediaQuery('(max-width: 420px)');
  const [loading, setLoading] = useState<boolean>(false);
  const highlightedRowId = useRef<string>("-1");
  const [gridRef, setGridRef] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<LogEntryDocument>();
  const [settingsPersonal, setSettingsPersonal] = useState<any>(null);
  const [editPopupVisible, setEditPopupVisible] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: "success",
    message: "",
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: "",
      type: "success",
    });
  };

  const loadData = async ({
    skip,
    limit,
    filterValue,
    sortInfo,
  }: {
    sortInfo?: TypeSortInfo;
    skip?: number;
    limit?: number;
    filterValue?: any;
  }): Promise<{ data: any[]; count: number }> => {
    const db = await getDatabase();

    let preSort: { [key: string]: MangoQuerySortDirection } = {
      LogDate: 'desc',
    };

    if (!isNil(sortInfo)) {
      if (Array.isArray(sortInfo)) {
        // Handle case when sortInfo is an array
        sortInfo.forEach(info => {
          preSort[info.name] = info.dir === 1 ? 'asc' : 'desc';
        });
      } else {
        // Handle case when sortInfo is a single object
        preSort[sortInfo.name] = sortInfo.dir === 1 ? 'asc' : 'desc';
      }
    }

    const sort = [
      {
        ...preSort,
      },
    ];

    let selector: any = {
      deletedAt: {
        $eq: null,
      }
    };
    selector[keyName] = keyValue;

    await Promise.all(
      filterValue.map(async (v: any) => {
        if (isEmpty(v.value)) return v;

        const s = await getSelectorByFilterName(
          v.name,
          v.value,
          v.operator,
          db
        );

        selector = {
          ...selector,
          ...s,
        };
        return v;
      })
    );

    const length = size(await db.logentry.find({ selector }).exec());

    const items = await db.logentry
      .find({
        selector,
        skip,
        limit,
        sort,
      })
      .exec();

    const data = await Promise.all(items.map(transformData));

    return { data, count: length };
  };

  const init = async (ref: MutableRefObject<TypeComputedProps | null>) => {
    const db = await getDatabase();

    db.logentry.$.subscribe(async (ev: any) => {
      if (ev.operation === "INSERT" || ev.operation === "UPDATE") {
        highlightedRowId.current = ev.documentId;
        ref?.current?.reload();
      }
      if (ev.operation === "DELETE") {
        highlightedRowId.current = "-1";
        ref?.current?.reload();
      }
    });
  };

  useEffect(() => {
    init(gridRef);
  }, []);

  const onReady = (ref: MutableRefObject<TypeComputedProps | null>) => {
    setGridRef(ref);
    init(ref);
  };

  const onLoadingChange = (status: boolean) => {
    // If loading completed - check if there any items that needs to be highlighted.
    if (!status && highlightedRowId.current !== "-1") {
      gridRef?.current?.scrollToId(highlightedRowId.current);
      gridRef?.current?.setSelectedById(highlightedRowId.current, true);
      highlightedRowId.current = "-1";
    }
    setLoading(status);
  };
  const handleDelete = async () => {
    setIsDeleting(true);
  };

  const handleDeleteOk = async () =>{
    try {
      await selectedItem?.remove();
      setSelectedItem(undefined);
      setEditPopupVisible(false);
      setSnackbar({
        open: true,
        message: 'Log entry successfully removed',
        type: 'success',
      });
    } catch (e: any) {
      setSnackbar({
        open: true,
        message: e.message,
        type: 'error',
      });
    }
    setIsDeleting(false);
  };

  const handleDeleteCancel = () =>{
    setIsDeleting(false);
  }
  const getAccessType = () => {
    if (settingsPersonal) {
      if (settingsPersonal.fldInv === 1) {
        return AccessType.READ_ONLY;
      }
      if (settingsPersonal.fldInv === 0) {
        return AccessType.NO_ACCESS;
      }
      if (settingsPersonal.fldInv === 2) {
        return AccessType.FULL_ACCESS;
      }
    } else {
      return AccessType.FULL_ACCESS;
    }
  };

  const handleCancel = () => {
    setSelectedItem(undefined);
    setEditPopupVisible(false);
  };

  const handleSave = async (document: LogEntry, isCreated: boolean) => {
    const editedIssue: any = document;
    if (!validateForm(editedIssue, setSnackbar)) return;
    setSelectedItem(undefined);
    setEditPopupVisible(false);

    setSnackbar({
      open: true,
      message: 'This log entry has been updated!',
      type: 'success',
    });
  };

  const handleEditCancel = () => {
    setSelectedItem(undefined);
    setEditPopupVisible(false);
    setProcessing(false);
  };

  const dataSource = useCallback(loadData, []);

  const onRowClick = disableEdit ? undefined : useCallback(({ data }) => {
    setSelectedItem(data.original);
    setEditPopupVisible(true);
  }, []);

  const columns = [
    {
      name: 'LogDate',
      header: 'Date',
      defaultFlex: 0.5,
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      dateFormat: 'DD-MMM-YYYY',
      render: ({ value, cellProps: { dateFormat } }:any) => {
        if (!isNil(value)) {
          return moment(value).format(dateFormat);
        }
      },
    },
    {
      name: "fldHours",
      header: "Hours",
      headerAlign: 'start' as any,
      textAlign: 'end' as any,
      defaultFlex: 0.5,
      visible: !(isTablet || isMobile)
    },
    {
      name: "LogEntry",
      header: "Log Entry",
      defaultFlex: 2,
    },
  ];

  return (
    <>
      <div data-testid="data-grid" className="flex flex-col flex-grow eq-history">
        <LicensedReactDataGrid
          onRowClick={onRowClick}
          onLoadingChange={onLoadingChange}
          defaultLimit={15}
          livePagination
          onReady={onReady}
          rowHeight={40}
          loading={loading}
          defaultFilterValue={filter}
          idProperty="PKey"
          columns={columns}
          dataSource={dataSource}
        />
      </div>
      <CustomDialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        open={editPopupVisible}
      >
        <DialogTitle>
          <span className="font-bold text-2xl">Edit LogEntry</span>
        </DialogTitle>

        <DialogContent dividers sx={{ p: 0 }}>
          {!!selectedItem && (
            <LogEntryDetailForm
            initialValue={selectedItem}
            onCancel={handleCancel}
            onSave={handleSave}
            onDelete={handleDelete}
            editFlag={getAccessType() !== AccessType.FULL_ACCESS}
            isCreated={false}
            type='Dialog'
          />
          )}
        </DialogContent>

        <DialogActions sx={{ px: 4, pb: 4, justifyContent: 'space-between' }}>
          <Box
            sx={{ justifyContent: 'flex-start', flexGrow: 1, display: 'flex' }}
          >
            <IconButton
              onClick={handleDelete}
              color="error"
              aria-label="Delete item"
            >
              <DeleteTwoTone />
            </IconButton>
          </Box>

          <Box
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: 'flex' }}
          >
            <Button className="mr-2" onClick={handleEditCancel}>
              Cancel
            </Button>
            <LoadingButton
              loading={processing}
              type="submit"
              form="logentry-details-form"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </CustomDialog>

      <WarningDialog
        visible={isDeleting}
        title="Delete Warning"
        content="Are you sure you wish to delete record?"
        okText='Yes'
        color='error'
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        onClose={onSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={snackBar.type as any} sx={{ width: "100%" }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EqHistory;
