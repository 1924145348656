import { isArray, get, isEmpty, isNil } from 'lodash';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { UPLOAD_URL } from 'src/consts';
import { TDIDb, getDatabase } from 'src/rxdb';
import { getJWT } from 'src/storage';
import { TypeFilterValue, TypeSingleFilterValue, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import { NODE_URL } from 'src/consts';
import { SchedMaintEQDocument } from 'src/rxdb/collections/SchedMaintEQ/schema';
import { LogEntryDocument } from 'src/pages/LogEntryPage/rxdb';
import { MangoQuerySortDirection } from "rxdb/dist/types/types";
import { uuid } from "uuidv4";
import { IAttachmentState } from "src/modules/Attachments";
import { CalculateNewRecurrenceTriggers, RecurrenceRequest, RecurrenceResponse } from "src/utils/Recurrence";
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { normalizeDateTime } from 'src/helpers';
import { SchedMaintDocument } from 'src/rxdb/collections/SchedMaint/schema';
import { EquipmentDocument } from 'src/pages/EquipmentPage/rxdb';
import { logEntryGetAllError } from 'src/pages/LogEntryPage/store';

export const toJSON = (documents: any) => {
  if (isArray(documents)) return documents.map((d) => d.toJSON());

  return documents?.toJSON();
};

export const isNotNil = (value: any)  => {
  return value !== null && value !== undefined
}

export const handleCharLimitWarning = (value: string, maxLength: number) => {
  if (!maxLength) return;
  if (value.length > maxLength) {
    return `Exceeded ${maxLength}-character limit.`;
  }
  return undefined;
};

export const uploadFile = async (
  buffer: ArrayBuffer | null,
  fileName: string,
  type: string,
  path = '',
  url?: string
): Promise<{ fileName: string; isCreated: boolean }> => {
  if (isNil(buffer)) {
    return {
      fileName,
      isCreated: false,
    };
  }

  const data = new FormData();
  data.append('Content-Type', 'multipart/form-data');
  if (path) data.append('x-upload-path', path);
  data.append('attachment', new Blob([buffer], { type }), fileName);
  const myHeaders = new Headers();
  const token = getJWT();
  if (token) myHeaders.append('authorization', `Bearer ${token}`);
  const response = await fetch(url ? url : UPLOAD_URL, {
    method: 'POST',
    body: data,
    headers: myHeaders,
  });

  const result: any = await response.json();

  return { fileName: result.key, isCreated: true };
};

const downloadBlob = (blob: any, fileName = 'DataGrid') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName}.csv`);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const exportCSV = async (
  columns: { name: string; id: any }[],
  rows: string[]
) => {
  const separator = ',';
  const header = columns.map((c) => c.name).join(separator);
  const items = rows.map((data) =>
    columns.map((c) => data[c.id]).join(separator)
  );

  const contents = [header].concat(items).join('\n');
  const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

  downloadBlob(blob);
};
export const exportExcel = async (
  columns: Array<Partial<any>>,
  rows: any[],
  fileName = 'DataGrid'
) => {
  const workbook = new (await import('exceljs')).Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  worksheet.columns = columns;
  worksheet.addRows(rows);

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buffer], { type: 'application/octet-stream' }),
    `${fileName}.xlsx`
  );
};

export const getPlainTextFromHtml = (html: string) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const buildTreeView = (
  id: string | null = null,
  primaryKey: string,
  parentKey: string,
  data: any[]
): any =>
  data
    .filter((item: any) => item[parentKey] === id)
    .map((item) => ({
      ...item,
      children: buildTreeView(item[primaryKey], primaryKey, parentKey, data),
    }));

export const getEquipmentDocoumentData = async (item: any, newHours: any) => {
  const document = {
    UniqueName: item?.UniqueName || null,
    Manufacturer: item?.Manufacturer || null,
    ModelNumber: item?.ModelNumber || null,
    fldCountHours: item?.fldCountHours || null,
    DateEntered: item?.DateEntered || null,
    fldSRHKey: item?.fldSRHKey || null,
    fldLocHierarchy: item?.fldLocHierarchy || null,
    Department: item?.Department || null,
    fldReportingType: item?.fldReportingType || null,
    fldRestricted: item?.fldRestricted || null,
    fldSMS: item?.fldSMS || null,
    Notes: item?.Notes || null,
    Supplier: item?.Supplier || null,
    fldInService: item?.fldInService || null,
    fldExpireDate: item?.fldExpireDate || null,
    SerialNum: item?.SerialNum || null,
    ArrangementNum: item?.ArrangementNum || null,
    Rating: item?.Rating || null,
    ArtNumber: item?.ArtNumber || null,
    EqKey: item.EqKey,
    Hours: newHours,
    updatedAt: new Date().toISOString(),
  } as any;
  return document;
};

export const equipmentHoursUpdate = async (EqKeyValue: any, newHours: any) => {
  const db = await getDatabase();
  const equipmentMatched = await db.equipment
    .find({
      selector: {
        EqKey: {
          $eq: EqKeyValue,
        },
      },
    })
    .exec();

  const newEqItem = equipmentMatched[0];
  const oldHours = newEqItem?.Hours || 0;
  const parentIdPresent = equipmentMatched[0].fldParent;
  if (oldHours < newHours) {
    const document = await getEquipmentDocoumentData(newEqItem, newHours);
    try{
      await db.collections.equipment.upsert(document);
    }catch(e:any){
      console.log('error', e.message)
    }
  }
  if (!isNil(parentIdPresent)) {
    await equipmentHoursUpdate(parentIdPresent, newHours);
  }
};

export const updateNextHourDueForNotLockHours = async (schedMaint: SchedMaintDocument|null, 
  maintEq: SchedMaintEQDocument, loggedHrs?: number) =>{
  const db = await getDatabase();
  const equipmentMatched = await db.equipment
    .findOne({
      selector: {
        EqKey: {
          $eq: maintEq?.EqKey,
        },
      },
    })
    .exec();

    // To advance the hours trigger the Hours from logEntry should be added to schedMaint.fldHourInterval
    // Otherwise add the current equipment hours, if that fails
    loggedHrs = Math.max(loggedHrs || equipmentMatched?.Hours || maintEq.fldHoursTrigger || 0);

  if (!isNil(equipmentMatched)){
    if (!isNil(schedMaint?.fldHourInterval)  && !isNil(loggedHrs)){
      const nextDueHrs =  (schedMaint?.fldHourInterval ??0) + (loggedHrs) ;
      await maintEq?.atomicPatch({
        fldHoursTrigger: nextDueHrs,
        fldDateTrigger:null,
      });
    }
  }
}

export const saveViewMode = (mode: string) => {
  const page = window.location.pathname;
  const viewModeData = localStorage.getItem('viewMode');
  let viewModeList = [];
  if (viewModeData) {
    viewModeList = JSON.parse(viewModeData);
    const record = viewModeList.find((view: any) => view.key === page);
    if (record) {
      const idx = viewModeList.findIndex((view: any) => view.key === page);
      viewModeList[idx] = {
        ...record,
        mode,
      };
    } else {
      viewModeList = [
        ...viewModeList,
        {
          key: page,
          mode,
        },
      ];
    }
  } else {
    viewModeList = [
      {
        key: page,
        mode,
      },
    ];
  }
  localStorage.setItem('viewMode', JSON.stringify(viewModeList));
};

export const getViewMode = () => {
  const page = window.location.pathname;
  const viewModeData = localStorage.getItem('viewMode');
  if (viewModeData) {
    const viewModeList = JSON.parse(viewModeData);
    const record = viewModeList.find((view: any) => view.key === page);
    if (record) {
      return record.mode;
    }
  }
  return null;
}

export const removeNonAlphanumericChars = ( input: string) =>{
  return input.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
}

export const getRegexByOperator = (operator: string, value: string) => {
  switch (operator) {
    case 'contains':
      return new RegExp(`.*${value}`, 'i');
    case 'startsWith':
      return new RegExp(`^${value}`, 'i');
    case 'endsWith':
      return new RegExp(`${value}$`, 'i');
    case 'eq':
      return new RegExp(`${value}`, 'i');
    default:
      return null;
  }
};
const getRxDbOperator = (operator: string): string => {
  switch (operator) {
    case 'gte': return '$gte';
    case 'lte': return '$lte';
    case 'lt': return '$lt';
    case 'gt': return '$gt';
    case 'eq': return '$eq';
    case 'neq': return '$neq';
    default: return '$eq';
  }
};
export const getRxDbDateOperator = (operator: string, columnName: string, value?: string) => {
  const start = get(value, 'start', value);
  const end = get(value, 'end', value);

  if (isNil(start) || isEmpty(start)) return ({ });

  const date = moment.utc(start).toISOString();

  switch(operator) {
    case 'after': return ({ [columnName]: { $gt: date } });
    case 'afterOrOn': return ({ [columnName]: { $gte: date } });
    case 'before': return ({ [columnName]: { $lt: date } });
    case 'beforeOrOn': return ({ [columnName]: { $lte: date } });
    case 'eq': return ({ [columnName]: date });
    case 'neq': return ({ [columnName]: { $ne: date } });
    case 'inrange': {
      const startDate = moment.utc(start).toISOString();
      const endDate = moment.utc(end).toISOString();
      return ({ $and: [{ [columnName]: { $gte: startDate } }, { [columnName]: { $lte: endDate } }] });
    }
    case 'notinrange': {
      const startDate = moment.utc(start).toISOString();
      const endDate = moment.utc(end).toISOString();
      return ({ $and: [{ [columnName]: { $lt: startDate } }, { [columnName]: { $gt: endDate } }] });
    }
  }
}

export const getSortParams = (sort: TypeSortInfo) => {
  const directionToQuery = (dir: number): MangoQuerySortDirection => dir === -1 ? 'desc' : 'asc';

  if (isNil(sort)) return [];

  if (isArray(sort)) return sort.map(s => ({ [s.name]: directionToQuery(s.dir) }));

  return ([{ [sort.name]: directionToQuery(sort.dir) }]);
};

export const getOperator = (filter: TypeSingleFilterValue) => {
  if (isNil(filter)) return {};

  const { operator, value, type } = filter;

  if (type === 'number') {
    if (isNil(value)) return ({ });

    return ({
      [getRxDbOperator(operator)]: value
    });
  }

  if (isEmpty(value)) return {};

  return ({ $regex: getRegexByOperator(operator, value) });
};

export const buildSelectorWithSelectOperator = (filter: TypeSingleFilterValue, eqSelectorBuilder: (filter: TypeFilterValue) => any, neqSelectorBuilder: (filter: TypeFilterValue) => any) => {
  if (isNil(filter)) return {};

  const { operator, value } = filter;

  if (['eq', 'inlist'].includes(operator)) return eqSelectorBuilder(value);

  return neqSelectorBuilder(value);
}

export const getRecurrencePerSchedMaint = async (
  maintEq: SchedMaintEQDocument,
  logEntry?: LogEntryDocument,
) => {
  const db = await getDatabase();

  const maint = await db.tblschedmaint
      .findOne({
          selector: {
              PKey: maintEq.SchedKey,
          },
      })
      .exec();

  if (isNil(maint)) return {};
  // Use new util method instead of .NET API
  const recurBody: RecurrenceRequest= {
    RecurType: maint.RecurType,
    Iterations: maintEq.fldIterations,
    RecurPattern: maint.RecurPattern || null,
    DateTrigger: maintEq.fldDateTrigger,
    DateCompleted: logEntry ? logEntry.LogDate : new Date(),
    HourInterval: maint.fldHourInterval,
    HoursTrigger: maintEq.fldHoursTrigger,
    HoursCompleted: logEntry && logEntry.fldHours || 0, // Use only logEntry.fldHours and not maintEq.fldHoursCompleted
    LockHours: maint.fldHourLock || false,
  };
  return CalculateNewRecurrenceTriggers(recurBody)
  // const reccurenceResponse = await fetch(
  //     `${NODE_URL}/recurrence`,
  //     {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: recurBody,
  //     },
  // );

  // if (!reccurenceResponse.ok)
  //       throw new Error("Error in recurrrence service:" 
  //       + JSON.stringify(await reccurenceResponse.json())
  //       + ", Request body " + JSON.stringify(recurBody));  

  // return reccurenceResponse.json();
};

export const sortDatesAscending = (d1: string | null, d2: string | null) => {
  const dateA = d1 ? +new Date(d1) : -1;
  const dateB = d2 ? +new Date(d2) : -1;

  return dateA - dateB;
};

export const sortNumbersAscending = (n1: number | null, n2: number | null) => {
  if (n1 === null) {
    return 1;
  }

  if (n2 === null) {
    return -1;
  }

  if (n1 === n2) {
    return 0;
  }

  return n1 < n2 ? -1 : 1;
};

export const handleAttachmentUpsert = async (fldSRHKey: any, SchedKey: any, fldTableName: any, uploadedFile: any, data?: IAttachmentState) => {
  const db = await getDatabase();
  try {
      // upsert into tbldocumentation 
      // use the fldFKey if present from attachment data - null/empty means a new attachment 
      // is being added so generate a new uuid. otherwise just use fldFKey so the documentation
      // record is upserted/updated
      const upsertDoc = await db.tbldocumentation.upsert({
          PKey: data?.fldFKey || uuid(),
          DocTitle: data?.DocTitle,
          fldDescription: data?.fldDescription,
          fldShowInCentral: data?.fldShowInCentral,
          fldSRHKey: fldSRHKey || null,
          fldLibtype: data?.fldLibtype || null,
      });
      // upsert into tbldocumentcrossreference - once only
      // if the fldFKey is present, that means this record has already been associated with documentation,
      // nothing needs to be done in that case
      let fldFKey = data?.fldFKey || undefined;
      if(!fldFKey) {
          console.log('tbldocumentation upsert:', upsertDoc)
          fldFKey  = upsertDoc.PKey;
          await db.tbldocumentcrossreference.upsert({
              PKey: uuid(),
              fldFKey: data?.fldFKey || upsertDoc.PKey,
              fldRecordKey: data?.fldRecordKey || SchedKey,
              fldTableName,
          });
      }
      
      // Find if there's a rev matching this fldFKey - what happens when there are multiple? Scott?       
      const revision: any = await db.documentrevision.findOne({selector: {fldFKey, fldRevision: data?.fldRevision}}).exec();
      // If a file is uploaded, generate a new uuid 
      // otherwise if the findQuery finds a matching rev, use that otherwise generate a new one
      const documentRevisionPKey = (uploadedFile && uploadedFile.key) ? uuid() : (revision ? revision.PKey: uuid());
      // Upsert the record so that new fields are updated from the form data
      await db.documentrevision.upsert({
          PKey: documentRevisionPKey,
          fldFKey, // Create association with Documentation above.
          fldAltPath: data?.fldAltPath,
          fldFileName: data?.fldFileName || uploadedFile.key,
          fldNotes: data?.fldNotes,
          fldPage: data?.fldPage,
          fldRevision: data?.fldRevision,
          fldRevisionDate: normalizeDateTime(data?.fldRevisionDate || new Date()),
      });
  } catch (e: any) {
      console.error(e);
      throw e
  }  
}

export const handleDeleteDocument = async (fldFKey: any, ) => {
  const db = await getDatabase();
  try {
    const revisions: any = await db.documentrevision.find({selector: {fldFKey}}).exec();
    console.log('delete revisions', revisions.map((it:any) => it.PKey));                    
    revisions.map((it:any) => it.remove());
    const documentCrossRefs: any = await db.tbldocumentcrossreference.find({selector: { fldFKey }}).exec();
    console.log('delete documentCrossRefs', documentCrossRefs.map((it:any) => it.PKey));
    documentCrossRefs.map((it:any) => it.remove());
    const tbldocumentation: any = await db.tbldocumentation.findOne({selector: { PKey: fldFKey }}).exec();
    console.log('delete tbldocumentation', tbldocumentation.PKey);
    await tbldocumentation.remove();      
  }
  catch (e: any) {
    throw e
  }
}

export const copySpareUsedFromWorkissue = async (SchedKey: any, document: WorkIssuesDocument) => {
  const db = await getDatabase();
  const schedMaintParts = await db.tblschedmaintparts.find({
      selector: {
        fldSchedMaintKey: SchedKey,
      },
    }).exec();
  if(schedMaintParts && schedMaintParts.length > 0) {
    for (let i in schedMaintParts) {
      const schedMaintPart = JSON.parse(JSON.stringify(schedMaintParts[i]));
      const newSpareUsed: any = {
        PKey: uuid(),
        ProductID: schedMaintPart.ProductID,
        Amount: schedMaintPart.Amount,
        WorkKey: document.JobNumber,
      };
      await db.tblsparesused.upsert(newSpareUsed);
    }
  }
}

export const copySpareUsedFromLogEntry = async (SchedKey: any, logEntry: LogEntryDocument) => {
  const db = await getDatabase();
  const schedMaintParts = await db.tblschedmaintparts.find({
      selector: {
        fldSchedMaintKey: SchedKey,
      },
    }).exec();
  if(schedMaintParts && schedMaintParts.length > 0) {
    for (let i in schedMaintParts) {
      const schedMaintPart = JSON.parse(JSON.stringify(schedMaintParts[i]));
      const newSpareUsed: any = {
        PKey: uuid(),
        ProductID: schedMaintPart.ProductID,
        Amount: schedMaintPart.Amount,
        LogKey: logEntry.PKey,
      };
      await db.tblsparesused.upsert(newSpareUsed);
    }
  }
}

export const preProcessSelectionRecurrence = (item: any) => {
  let scheduleType;
  let simpleCheck = false;
  switch (item.RecurType) {
    case 64: {
      scheduleType = 'Daily';
      break;
    }
    case 62: {
      scheduleType = 'Daily';
      simpleCheck = true;
      break;
    }
    case 63: {
      scheduleType = 'Daily';
      break;
    }
    case 47: {
      scheduleType = 'Weekly';
      simpleCheck = true;
      break;
    }
    case 48: {
      scheduleType = 'Weekly';
      break;
    }
    case 54: {
      scheduleType = 'Monthly';
      simpleCheck = true;
      break;
    }
    case 55: {
      scheduleType = 'Monthly';
      break;
    }
    case 56: {
      scheduleType = 'Monthly';
      break;
    }
    case 49: {
      scheduleType = 'Yearly';
      simpleCheck = true;
      break;
    }
    case 50: {
      scheduleType = 'Yearly';
      break;
    }
    case 51: {
      scheduleType = 'Yearly';
      break;
    }
    case 100: {
      scheduleType = 'Event Based';
      break;
    }
    default: {
      scheduleType = 'Hours Only';
      break;
    }

  }
  const document = {
    ...item,
    scheduleType: scheduleType,
    simpleCheck: simpleCheck,
  };
  return document;
}

export const filterNonNullStrings = (strings: (string | null | undefined)[]): string[] => {
  return strings.filter((str): str is string => str !== null && str !== undefined);
}

export const searchForEquipment = async (equipmentKey: string, db: TDIDb) => {
  const equipment = await db.equipment
    .findOne({ 
      selector: { 
        EqKey: equipmentKey, 
        deletedBy: null 
      },
    })
    .exec();
  if(isNotNil(equipment)) return true;
}

export const searchForSchedMaintEq = async (schedMaintEqKey: string, db: TDIDb) => {
  const schedMaintEq = await db.tblschedmainteq
    .findOne({
        selector: {
            PKey: schedMaintEqKey,
            deletedBy: null,
        },
    })
    .exec();
  return isNotNil(schedMaintEq);
}

export const extractValueBetweenTags = (inputString: string, tagName: string): string | null => {
  const regex = new RegExp(`<${tagName}>(.*?)<\/${tagName}>`);
  const match = inputString.match(regex);
  return match ? match[1] : null;
};

export const stopPropagate = (callback: () => void) => {
  return (e: {preventDefault: () => void, stopPropagation: () => void}) => {
    e.preventDefault();
    callback();
    e.stopPropagation();
  }
}

export const escapeStringRegexp = (string: any) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};